import { axiosUtils } from "../_helpers";
import urlConfig from "url.config";

export const feedbackTemplateService = {
  getAllFeedbackTemplates,
  getFeedbackTemplate,
  createFeedbackTemplate,
  updateFeedbackTemplate,

  removeFeedbackTemplate,
  activateFeedbackTemplate,
};

export const feedbackRequestService = {
  getAllFeedbackRequests,
  getFeedbackRequest,
  createFeedbackRequest,
  updateFeedbackRequest,
  removeFeedbackRequest,
  remindFeedbackRequest,

  updateFeedbackRequestStatus,
  getFeedback,
  updateFeedback,
  removeFeedback,
  activateFeedback,
};

async function getAllFeedbackTemplates(request) {
  let params = request.userTeamId ? `?userTeamId=${request.userTeamId}` : "";
  return await axiosUtils.get(
    urlConfig.FEEDBACK_TEMPLATE + `/list/${request.itemType}` + params
  );
}

async function createFeedbackTemplate(feedbackTemplateRequest) {
  return await axiosUtils.post(
    urlConfig.FEEDBACK_TEMPLATE,
    feedbackTemplateRequest
  );
}

async function updateFeedbackTemplate(feedbackTemplateRequest) {
  return await axiosUtils.put(
    urlConfig.FEEDBACK_TEMPLATE,
    feedbackTemplateRequest
  );
}

async function removeFeedbackTemplate(feedbackTemplateId) {
  return await axiosUtils.remove(
    urlConfig.FEEDBACK_TEMPLATE + `/${feedbackTemplateId}`
  );
}

async function getFeedbackTemplate(feedbackTemplateId) {
  return await axiosUtils.get(
    urlConfig.FEEDBACK_TEMPLATE + `/${feedbackTemplateId}`
  );
}
async function activateFeedbackTemplate(feedbackTemplateId, isActive) {
  return await axiosUtils.put(
    urlConfig.FEEDBACK_TEMPLATE + `/${feedbackTemplateId}/${isActive}`
  );
}

async function getAllFeedbackRequests(request) {
  let params = request.itemId ? `?itemId=${request.itemId}` : "";
  return await axiosUtils.get(
    urlConfig.FEEDBACK + `/list/${request.itemType}` + params
  );
}

async function createFeedbackRequest(feedbackRequestRequest) {
  return await axiosUtils.post(urlConfig.FEEDBACK, feedbackRequestRequest);
}

async function updateFeedbackRequest(feedbackRequestRequest) {
  return await axiosUtils.put(
    urlConfig.FEEDBACK + "/request",
    feedbackRequestRequest
  );
}

async function updateFeedback(feedbackRequest) {
  return await axiosUtils.put(urlConfig.FEEDBACK, feedbackRequest);
}

async function removeFeedbackRequest(feedbackRequestId) {
  return await axiosUtils.remove(
    urlConfig.FEEDBACK + `/request/${feedbackRequestId}`
  );
}

async function getFeedbackRequest(feedbackRequestId) {
  return await axiosUtils.get(
    urlConfig.FEEDBACK + `/request/${feedbackRequestId}`
  );
}
async function getFeedback(feedbackId) {
  return await axiosUtils.get(urlConfig.FEEDBACK + `/${feedbackId}`);
}
async function remindFeedbackRequest(feedbackRequestId) {
  return await axiosUtils.put(
    urlConfig.FEEDBACK + `/request/remind/${feedbackRequestId}`
  );
}

async function updateFeedbackRequestStatus(
  feedbackRequestId,
  status,
  currentUserId,
  expiryDate
) {
  return await axiosUtils.put(
    urlConfig.FEEDBACK +
      `/request/${feedbackRequestId}/${status}?employeeId=${currentUserId}&expiryDate=${
        expiryDate ? expiryDate : ""
      }`
  );
}

async function removeFeedback(feedbackRequestId) {
  return await axiosUtils.remove(urlConfig.FEEDBACK + `/${feedbackRequestId}`);
}

async function activateFeedback(feedbackRequestId, isActive) {
  return await axiosUtils.put(
    urlConfig.FEEDBACK + `/${feedbackRequestId}/${isActive}`
  );
}
