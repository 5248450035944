import React, { Component } from "react";

class MultiStarsSelectInput extends Component {
  onChange = (name, value) => {
    name = name.trim();
    var answer = this.props.questionConfig.answer || null;
    var answerList = answer ? answer.split(",") : [];
    var index = -1;
    var count = -1;
    answerList.forEach((ans) => {
      count++;
      if (ans.includes(name + "::")) {
        index = count;
      }
    });

    if (index > -1) {
      answerList[index] = (name + "::" + value).trim();
    } else {
      answerList.push((name + "::" + value).trim());
    }

    this.props.onChange({
      ...this.props.questionConfig,
      answer: answerList.join(","),
    });
  };

  getAnswerNameValue = (optionNameValue) => {
    var answer = this.props.questionConfig.answer || null;
    var answerList = answer ? answer.split(",") : [];
    answerList.forEach((ans) => {
      if (ans.includes(optionNameValue + "::")) {
        optionNameValue = ans;
      }
    });
    return optionNameValue;
  };

  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6 inputField">
            <div className="rating">
              <label className="labelTxt">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>
              <div className="ratingInner">
                {this.props.questionConfig.questionOptions &&
                  this.props.questionConfig.questionOptions
                    .split(",")
                    .map((namevalue) => (
                      <SingleRating
                        key={namevalue}
                        nameValue={this.getAnswerNameValue(namevalue.trim())}
                        onChange={this.onChange}
                        readonly={this.props.readonly}
                      />
                    ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

class SingleRating extends Component {
  constructor() {
    super();
    this.state = {
      selectableStars: 0,
    };
  }

  getSelectedStars = () => {
    return (
      (this.props.nameValue &&
        this.props.nameValue.split("::").length > 0 &&
        this.props.nameValue.split("::")[1]) ||
      0
    );
  };

  onMouseOver = (stars) => {
    if (this.props.readonly === false) {
      this.setState({
        selectableStars: stars,
      });
    }
  };

  onMouseOut = () => {
    if (this.props.readonly === false) {
      this.setState({
        selectableStars: 0,
      });
    }
  };

  onClick = (selectedStars) => {
    if (this.props.readonly === false) {
      this.props.onChange(this.props.nameValue.split("::")[0], selectedStars);
    }
  };

  render() {
    var n = 5;
    const numbers = Array.from({ length: n }, (_, i) => i + 1);
    return (
      <div className="ratingSingle">
        <h3 className="ratingHeading">{this.props.nameValue.split("::")[0]}</h3>
        <div className="ratingSingle-inner">
          {numbers.map((number) => (
            <i
              key={number}
              className={
                `star-fill fa  fa-solid fa-star ` +
                ((number <= this.state.selectableStars ? " filled" : "") ||
                  (this.state.selectableStars === 0 &&
                  this.getSelectedStars() !== 0 &&
                  number <= this.getSelectedStars()
                    ? " filled"
                    : ""))
              }
              onMouseOver={() => this.onMouseOver(number)}
              onMouseLeave={() => this.onMouseOut()}
              onClick={() => this.onClick(number)}
            ></i>
          ))}
          <span className="total-rating">{this.getSelectedStars()}.0</span>
        </div>
      </div>
    );
  }
}
export default MultiStarsSelectInput;
