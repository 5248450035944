export const feedbackTemplateConstants = {
  SHOW_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW:
    "SHOW_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW",
  CLOSE_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW:
    "CLOSE_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW",

  //feedback templates
  GETALL_FEEDBACK_TEMPLATES_REQUEST: "GETALL_FEEDBACK_TEMPLATES_REQUEST",
  GETALL_FEEDBACK_TEMPLATES_SUCCESS: "GETALL_FEEDBACK_TEMPLATES_SUCCESS",
  GETALL_FEEDBACK_TEMPLATES_FAILURE: "GETALL_FEEDBACK_TEMPLATES_FAILURE",

  GET_FEEDBACK_TEMPLATE_REQUEST: "GET_FEEDBACK_TEMPLATE_REQUEST",
  GET_FEEDBACK_TEMPLATE_SUCCESS: "GET_FEEDBACK_TEMPLATE_SUCCESS",
  GET_FEEDBACK_TEMPLATE_FAILURE: "GET_FEEDBACK_TEMPLATE_FAILURE",

  CREATE_FEEDBACK_TEMPLATE_REQUEST: "CREATE_FEEDBACK_TEMPLATE_REQUEST",
  CREATE_FEEDBACK_TEMPLATE_SUCCESS: "CREATE_FEEDBACK_TEMPLATE_SUCCESS",
  CREATE_FEEDBACK_TEMPLATE_FAILURE: "CREATE_FEEDBACK_TEMPLATE_FAILURE",

  UPDATE_FEEDBACK_TEMPLATE_REQUEST: "UPDATE_FEEDBACK_TEMPLATE_REQUEST",
  UPDATE_FEEDBACK_TEMPLATE_SUCCESS: "UPDATE_FEEDBACK_TEMPLATE_SUCCESS",
  UPDATE_FEEDBACK_TEMPLATE_FAILURE: "UPDATE_FEEDBACK_TEMPLATE_FAILURE",

  REMOVE_FEEDBACK_TEMPLATE_REQUEST: "REMOVE_FEEDBACK_TEMPLATE_REQUEST",
  REMOVE_FEEDBACK_TEMPLATE_SUCCESS: "REMOVE_FEEDBACK_TEMPLATE_SUCCESS",
  REMOVE_FEEDBACK_TEMPLATE_FAILURE: "REMOVE_FEEDBACK_TEMPLATE_FAILURE",

  ACTIVATE_FEEDBACK_TEMPLATE_REQUEST: "ACTIVATE_FEEDBACK_TEMPLATE_REQUEST",
  ACTIVATE_FEEDBACK_TEMPLATE_SUCCESS: "ACTIVATE_FEEDBACK_TEMPLATE_SUCCESS",
  ACTIVATE_FEEDBACK_TEMPLATE_FAILURE: "ACTIVATE_FEEDBACK_TEMPLATE_FAILURE",
};

export const feedbackRequestConstants = {
  CHANGE_FILTER_FEEDBACKS: "CHANGE_FILTER_FEEDBACKS",
  GETALL_FEEDBACK_REQUEST_REQUEST: "GETALL_FEEDBACK_REQUEST_REQUEST",
  GETALL_FEEDBACK_REQUEST_SUCCESS: "GETALL_FEEDBACK_REQUEST_SUCCESS",
  GETALL_FEEDBACK_REQUEST_FAILURE: "GETALL_FEEDBACK_REQUEST_FAILURE",

  GET_FEEDBACK_REQUEST_REQUEST: "GET_FEEDBACK_REQUEST_REQUEST",
  GET_FEEDBACK_REQUEST_SUCCESS: "GET_FEEDBACK_REQUEST_SUCCESS",
  GET_FEEDBACK_REQUEST_FAILURE: "GET_FEEDBACK_REQUEST_FAILURE",

  CREATE_FEEDBACK_REQUEST_REQUEST: "CREATE_FEEDBACK_REQUEST_REQUEST",
  CREATE_FEEDBACK_REQUEST_SUCCESS: "CREATE_FEEDBACK_REQUEST_SUCCESS",
  CREATE_FEEDBACK_REQUEST_FAILURE: "CREATE_FEEDBACK_REQUEST_FAILURE",

  UPDATE_FEEDBACK_REQUEST_REQUEST: "UPDATE_FEEDBACK_REQUEST_REQUEST",
  UPDATE_FEEDBACK_REQUEST_SUCCESS: "UPDATE_FEEDBACK_REQUEST_SUCCESS",
  UPDATE_FEEDBACK_REQUEST_FAILURE: "UPDATE_FEEDBACK_REQUEST_FAILURE",

  UPDATE_FEEDBACK_REQUEST_STATUS_REQUEST:
    "UPDATE_FEEDBACK_REQUEST_STATUS_REQUEST",
  UPDATE_FEEDBACK_REQUEST_STATUS_SUCCESS:
    "UPDATE_FEEDBACK_REQUEST_STATUS_SUCCESS",
  UPDATE_FEEDBACK_REQUEST_STATUS_FAILURE:
    "UPDATE_FEEDBACK_REQUEST_STATUS_FAILURE",

  REMOVE_FEEDBACK_REQUEST_REQUEST: "REMOVE_FEEDBACK_REQUEST_REQUEST",
  REMOVE_FEEDBACK_REQUEST_SUCCESS: "REMOVE_FEEDBACK_REQUEST_SUCCESS",
  REMOVE_FEEDBACK_REQUEST_FAILURE: "REMOVE_FEEDBACK_REQUEST_FAILURE",

  REMIND_FEEDBACK_REQUEST_REQUEST: "REMIND_FEEDBACK_REQUEST_REQUEST",
  REMIND_FEEDBACK_REQUEST_SUCCESS: "REMIND_FEEDBACK_REQUEST_SUCCESS",
  REMIND_FEEDBACK_REQUEST_FAILURE: "REMIND_FEEDBACK_REQUEST_FAILURE",

  ACTIVATE_FEEDBACK_REQUEST_REQUEST: "ACTIVATE_FEEDBACK_REQUEST_REQUEST",
  ACTIVATE_FEEDBACK_REQUEST_SUCCESS: "ACTIVATE_FEEDBACK_REQUEST_SUCCESS",
  ACTIVATE_FEEDBACK_REQUEST_FAILURE: "ACTIVATE_FEEDBACK_REQUEST_FAILURE",

  GET_FEEDBACK_REQUEST: "GET_FEEDBACK_REQUEST",
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
  GET_FEEDBACK_FAILURE: "GET_FEEDBACK_FAILURE",

  REMOVE_FEEDBACK_REQUEST: "REMOVE_FEEDBACK_REQUEST",
  REMOVE_FEEDBACK_SUCCESS: "REMOVE_FEEDBACK_SUCCESS",
  REMOVE_FEEDBACK_FAILURE: "REMOVE_FEEDBACK_FAILURE",

  ACTIVATE_FEEDBACK_REQUEST: "ACTIVATE_FEEDBACK_REQUEST",
  ACTIVATE_FEEDBACK_SUCCESS: "ACTIVATE_FEEDBACK_SUCCESS",
  ACTIVATE_FEEDBACK_FAILURE: "ACTIVATE_FEEDBACK_FAILURE",
};
