import React, { Component } from "react";

class ReactionInput extends Component {
  onChange = (value) => {
    this.props.onChange({ ...this.props.questionConfig, answer: value });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <>
            <div className="reactions">
              <div className="input_field">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </div>
              <div className="reaction_inner">
                <EmojiImage
                  name="Cool"
                  image="cool"
                  onChange={this.onChange}
                  value={this.props.questionConfig.answer || ""}
                  readonly={this.props.readonly}
                />
                <EmojiImage
                  name="Sad"
                  image="sad"
                  onChange={this.onChange}
                  value={this.props.questionConfig.answer || ""}
                  readonly={this.props.readonly}
                />
                <EmojiImage
                  name="Shock"
                  image="shock"
                  onChange={this.onChange}
                  value={this.props.questionConfig.answer || ""}
                  readonly={this.props.readonly}
                />
                <EmojiImage
                  name="Wink"
                  image="wink"
                  onChange={this.onChange}
                  value={this.props.questionConfig.answer || ""}
                  readonly={this.props.readonly}
                />
                <EmojiImage
                  name="Happy"
                  image="happy"
                  onChange={this.onChange}
                  value={this.props.questionConfig.answer || ""}
                  readonly={this.props.readonly}
                />
              </div>
              <div className="info-text">
                <i className="fa fa-solid fa-circle-info"></i>
                <h5>
                  A Mood Scale allows for the analysis and calculation of the
                  project's overall mood.
                </h5>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

class EmojiImage extends Component {
  constructor() {
    super();
    this.state = {
      mouseOver: false,
    };
  }
  onMouseOver = () => {
    if (this.props.readonly === false) {
      this.setState({
        mouseOver: true,
      });
    }
  };
  onMouseOut = () => {
    if (this.props.readonly === false) {
      this.setState({
        mouseOver: false,
      });
    }
  };
  getImageName = () => {
    return this.props.image + (this.state.mouseOver === true ? ".gif" : ".png");
  };
  onChange = () => {
    if (this.props.readonly === false) {
      this.props.onChange(this.props.name);
    }
  };
  render() {
    return (
      <div
        className="emoji"
        data-value="Cool"
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onChange}
      >
        <div
          className={`emoji_img ${
            this.state.mouseOver === true ||
            this.props.value === this.props.name
              ? "colorgreen"
              : ""
          }`}
        >
          <img
            src={`/assets/images/custom/emoji/${this.getImageName()}`}
            alt="emoji"
          />
        </div>
        <span>{this.props.name}</span>
      </div>
    );
  }
}
export default ReactionInput;
