import { feedbackTemplateConstants } from "../_constants";
const feedbackTemplateFieldConfig = [
  { name: "name", mandatory: true, enabled: true },
  { name: "type", mandatory: true, enabled: true },
  { name: "subType", mandatory: true, enabled: true },
  { name: "userTeamId", mandatory: true, enabled: true },
];

const typeOptions = [
  { value: "boolean", label: "Decision", multi: false },
  { value: "string", label: "Text", multi: false },
  { value: "number", label: "Number", multi: false },
  { value: "longstring", label: "Long Text", multi: false },
  { value: "overallstarrating", label: "Over all Star Rating", multi: false },
  { value: "starrating", label: "Star Rating", multi: false },
  {
    value: "multiopitonrating",
    label: "Multi Options Star Rating",
    multi: true,
  },
  { value: "reaction", label: "Reaction", multi: false },
  { value: "dropdown", label: "Drop Down", multi: true },
  { value: "multiselect", label: "Multi Select Options", multi: true },
  { value: "singleselectoption", label: "Single Select Option", multi: false },
  {
    value: "singleselectmultioptions",
    label: "Single Select Multi Options",
    multi: true,
  },
];

const feedbackTypeOptions = [
  { value: "PROJECT", label: "Project" },
  { value: "PURCHASE_ORDER", label: "Purchase Order" },
  { value: "EMPLOYEE", label: "Employee" },
  { value: "BID", label: "Bid" },
];

const feedbackSubTypeOptions = [
  { value: "GENERAL", label: "General" },
  { value: "DELIVERY_TEAM", label: "Delivery Team" },
  { value: "CLIENT", label: "Client" },
  { value: "CLIENT_PARTNER", label: "Client Partner" },
];

export function feedbackTemplate(
  state = {
    feedbackTemplateFieldConfig,
    typeOptions,
    feedbackTypeOptions,
    feedbackSubTypeOptions,
  },
  action
) {
  switch (action.type) {
    case feedbackTemplateConstants.SHOW_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW:
      return {
        ...state,
        isShowConfigureFeedbackTemplateSidebar: true,
      };
    case feedbackTemplateConstants.CLOSE_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW:
      return {
        ...state,
        isShowConfigureFeedbackTemplateSidebar: false,
      };

    case feedbackTemplateConstants.GETALL_FEEDBACK_TEMPLATES_REQUEST:
      return {
        ...state,
        feedbackTemplatesLoading: true,
      };
    case feedbackTemplateConstants.GETALL_FEEDBACK_TEMPLATES_SUCCESS:
      return {
        ...state,
        feedbackTemplatesLoading: false,
        templateItems: action.feedbackTemplates,
      };
    case feedbackTemplateConstants.GETALL_FEEDBACK_TEMPLATES_FAILURE:
      return {
        ...state,
        feedbackTemplatesLoading: false,
        error: action.error,
      };

    case feedbackTemplateConstants.CREATE_FEEDBACK_TEMPLATE_REQUEST:
      return {
        ...state,
        creating: true,
        error: null,
      };
    case feedbackTemplateConstants.CREATE_FEEDBACK_TEMPLATE_SUCCESS:
      state.templateItems
        ? state.templateItems.push(action.feedbackTemplate)
        : (state.templateItems = [action.feedbackTemplate]);
      return {
        ...state,
        creating: false,
      };
    case feedbackTemplateConstants.CREATE_FEEDBACK_TEMPLATE_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error,
      };

    case feedbackTemplateConstants.UPDATE_FEEDBACK_TEMPLATE_REQUEST:
      return {
        ...state,
        updating: true,
        templateItems: state.templateItems.map((feedbackTemplate) =>
          feedbackTemplate.id === action.feedbackTemplateId
            ? { ...feedbackTemplate, updating: true }
            : feedbackTemplate
        ),
        error: null,
      };
    case feedbackTemplateConstants.UPDATE_FEEDBACK_TEMPLATE_SUCCESS:
      let oldItem = state.templateItems.find(
        (feedbackTemplate) => feedbackTemplate.id === action.feedbackTemplateId
      );
      let newItems = state.templateItems.filter(
        (feedbackTemplate) => feedbackTemplate.id !== action.feedbackTemplateId
      );
      let newItem = {
        ...oldItem,
        ...action.feedbackTemplate,
      };
      newItems.push(newItem);
      return {
        ...state,
        updating: false,
        templateItems: newItems,
      };
    case feedbackTemplateConstants.UPDATE_FEEDBACK_TEMPLATE_FAILURE:
      return {
        ...state,
        updating: false,
        templateItems: state.templateItems.map((feedbackTemplate) => {
          if (feedbackTemplate.id === action.feedbackTemplateId) {
            const { updating, ...feedbackTemplateCopy } = feedbackTemplate;
            return { ...feedbackTemplateCopy, deleteError: action.error };
          }
          return feedbackTemplate;
        }),
        error: action.error,
      };

    case feedbackTemplateConstants.REMOVE_FEEDBACK_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateItems: state.templateItems.filter(
          (feedbackTemplate) =>
            feedbackTemplate.id !== action.feedbackTemplateId
        ),
      };

    case feedbackTemplateConstants.ACTIVATE_FEEDBACK_TEMPLATE_REQUEST:
      return {
        ...state,
        templateItems: state.templateItems.map((feedbackTemplate) =>
          feedbackTemplate.id === action.feedbackTemplateId
            ? { ...feedbackTemplate, activating: true }
            : feedbackTemplate
        ),
      };
    case feedbackTemplateConstants.ACTIVATE_FEEDBACK_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateItems: state.templateItems.map((feedbackTemplate) =>
          feedbackTemplate.id === action.feedbackTemplateId
            ? { ...feedbackTemplate, active: action.isActive }
            : feedbackTemplate
        ),
      };
    case feedbackTemplateConstants.ACTIVATE_FEEDBACK_TEMPLATE_FAILURE:
      return {
        ...state,
        templateItems: state.templateItems.map((feedbackTemplate) => {
          if (feedbackTemplate.id === action.feedbackTemplateId) {
            const { activating, ...feedbackTemplateCopy } = feedbackTemplate;
            return { ...feedbackTemplateCopy, deleteError: action.error };
          }
          return feedbackTemplate;
        }),
      };
    default:
      return state;
  }
}
