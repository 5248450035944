import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import ServiceLineEstimate from "views/common/fragments/formfields/ServiceLineEstimate";
import { purchaseOrderActions } from "_actions";
import { projectActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import { validationUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import workItemUtils from "_helpers/workitem.utils";
import ProjectTaskAccessRestrictionControlInput from "../fragments/ProjectTaskAccessRestrictionControlInput";
import SidebarFooter from "./SidebarFooter";

class CreateProjectSidebar extends Component {
  componentDidMount() {}
  render() {
    return (
      <div
        id="createprojectsidebar"
        className={`right_sidebar create_project_right_sidebar  ${
          this.props.show ? "open" : ""
        }`}
      >
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">
              {" "}
              {this.props.editMode
                ? "Update"
                : this.props.duplicateMode
                ? "Duplicate"
                : "Create"}{" "}
              Project
            </h3>
            <div className="card-options">
              <button
                onClick={this.props.closeActionHandler}
                className="link p-2 create-project-close-link float-right"
              >
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          {this.props.show &&
            ((this.props.editMode && this.props.selectedProjectInfo) ||
              (this.props.duplicateMode && this.props.selectedProjectInfo) ||
              (!this.props.editMode && !this.props.duplicateMode)) && (
              <CreateProjectSidebarSection
                handleSubmit={this.props.handleSubmit}
                editMode={this.props.editMode}
                duplicateMode={this.props.duplicateMode}
                selectedProjectInfo={this.props.selectedProjectInfo}
                currentUser={this.props.currentUser}
                fieldConfig={this.props.fieldConfig}
                createConfig={this.props.createConfig}
                allProjectTeamAccounts={this.props.allProjectTeamAccounts}
                allEmployees={this.props.allEmployees}
                employeeAppConfig={this.props.employeeAppConfig}
                allRoles={this.props.allRoles}
                allUserTeams={this.props.allUserTeams}
                isProcessing={this.props.creating || this.props.updating}
                purchaseOrders={this.props.purchaseOrders}
                initializeCreateProject={this.props.initializeCreateProject}
                permission={this.props.permission}
              />
            )}
        </div>
      </div>
    );
  }
}

class CreateProjectSidebarSection extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false,
    };
  }

  componentDidMount() {
    this.initializeRequest();
  }

  onResetFormError = () => {
    this.setState({
      hasFormError: false,
    });
  };
  initializeRequest = () => {
    let userTeamId = commonUtils.getCurrentUserTeamId();
    let projectRequest = this.props.editMode
      ? this.props.selectedProjectInfo
      : this.props.duplicateMode
      ? {
          ...this.props.selectedProjectInfo,
          id: null,
          createdBy: commonUtils.getCurrentUserId(),
          modifiedBy: null,
        }
      : {
          userTeamId: userTeamId,
          automationEnabled: false,
          code: "PRJ" + Date.now(),
        };
    this.setState({
      projectRequest: projectRequest,
      purchaseOrderServiceLines:
        projectRequest.serviceLines && projectRequest.serviceLines.legnth > 0
          ? projectRequest.serviceLines
          : projectRequest.purchaseOrderDetails &&
            projectRequest.purchaseOrderDetails.serviceLines,
      purchaseOrderServiceLineEstimate:
        projectRequest.purchaseOrderDetails &&
        projectRequest.purchaseOrderDetails.serviceLineEstimate,
      purchaseOrderStartTime:
        projectRequest &&
        projectRequest.purchaseOrderDetails &&
        projectRequest.purchaseOrderDetails
          ? projectRequest.purchaseOrderDetails.estStartTime
          : null,
      purchaseOrderCompletionTime:
        projectRequest &&
        projectRequest.purchaseOrderDetails &&
        projectRequest.purchaseOrderDetails
          ? projectRequest.purchaseOrderDetails.estCompletionTime
          : null,
    });
  };

  onFormError = (fieldName, hasError) => {
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError,
      },
    }));
  };
  onPurchaseOrderChange = (fieldName, value) => {
    let newSelectedOptions =
      (this.props.purchaseOrders &&
        this.props.purchaseOrders.find(
          (purchaseOrder) => purchaseOrder.id === value
        )) ||
      {};
    let { id, userTeamId, name, ...newSelectedOptionsCopy } =
      newSelectedOptions;
    this.setState((prevState) => ({
      projectRequest: {
        ...prevState.projectRequest,
        ...newSelectedOptionsCopy,
        [fieldName]: value,
        createdBy: commonUtils.getCurrentUserId(),
        modifiedBy: commonUtils.getCurrentUserId(),
      },
      purchaseOrderServiceLines: newSelectedOptionsCopy.serviceLines,
      purchaseOrderServiceLineEstimate:
        newSelectedOptionsCopy.serviceLineEstimate,
      purchaseOrderStartTime: newSelectedOptionsCopy.estStartTime,
      purchaseOrderCompletionTime: newSelectedOptionsCopy.estCompletionTime,
    }));
  };

  purchaseOrderListCallBack = (purchaseOrders) => {
    let purchaseOrder =
      this.state.projectRequest &&
      purchaseOrders &&
      purchaseOrders.find(
        (purchaseOrder) =>
          purchaseOrder.id === this.state.projectRequest.purchaseOrderId
      );
    if (!purchaseOrder) {
      this.setState((prevState) => ({
        projectRequest: {
          ...prevState.projectRequest,
          purchaseOrderId: null,
          proposalNumber: null,
          purchaseOrderNumber: null,
        },
        purchaseOrderServiceLines: null,
        purchaseOrderServiceLineEstimate: null,
        purchaseOrderStartTime: null,
        purchaseOrderCompletionTime: null,
      }));
    } else {
    }
  };

  onChange = (fieldName, value) => {
    this.onResetFormError();
    if (fieldName === "userTeamId") {
      store.dispatch(this.props.initializeCreateProject(value));
      store.dispatch(
        purchaseOrderActions.getActivePurchaseOrderList(
          { userTeamIds: value },
          this.purchaseOrderListCallBack
        )
      );
    }

    this.setState(
      (prevState) => ({
        projectRequest: {
          ...prevState.projectRequest,
          [fieldName]: value,
        },
      }),
      () => {
        if (fieldName === "estStartTime" || fieldName === "estCompletionTime") {
          if (
            this.state.projectRequest.estStartTime &&
            this.state.projectRequest.estCompletionTime
          ) {
            let startDateMoment = dateUtils.convertDateStringToMoment(
              this.state.projectRequest.estStartTime
            );
            let endDateMoment = dateUtils.convertDateStringToMoment(
              this.state.projectRequest.estCompletionTime
            );
            if (
              dateUtils.compareTwoMoments(
                startDateMoment.startOf("day"),
                endDateMoment.endOf("day")
              ) === -1
            ) {
              this.setState((prevState) => ({
                projectRequest: {
                  ...prevState.projectRequest,
                  [fieldName === "estStartTime"
                    ? "estCompletionTime"
                    : fieldName]: undefined,
                },
              }));
            }
          }
        }

        if (fieldName === "isOrderChanged" && value === false) {
          this.setState((prevState) => ({
            projectRequest: {
              ...prevState.projectRequest,
              revisedAdditionalHrs: 0,
              revisedAdditionalCost: 0,
            },
          }));
        }
      }
    );
  };

  /*onNewItemSelected = (isNewItemSelected) => {
    this.setState(prevState => ({
      isNewItemSelected: isNewItemSelected
    }))
  }


  /*onCreateSelectChange = (fieldName, selectedOption) => {
    let newSelectedOptions = { ...selectedOption };
    delete newSelectedOptions.id;
    this.setState(prevState => ({
      projectRequest: {
        ...prevState.projectRequest,
        ...newSelectedOptions,
        [fieldName]: selectedOption.value
      }
    }))
  }*/

  getFieldConfig = () => {
    let fieldConfig =
      commonUtils.getAppConfigValue(
        this.props.createConfig,
        "isPurchaseOrderMandatory"
      ) === true
        ? this.props.fieldConfig.map((field) => {
            if (field.name === "purchaseOrderId") {
              field.mandatory = true;
            }
            return field;
          })
        : this.props.fieldConfig;
    return this.props.permission.enableProjectPurchaseOrderEditable === true &&
      this.props.editMode === true
      ? fieldConfig.map((field) => {
          if (field.name === "purchaseOrderId") {
            field.editable = true;
          }
          return field;
        })
      : fieldConfig;
  };

  handleSubmit = (isCreateAnotherEnabled) => {
    let isValid = validationUtils.isValidRequest(
      this.getFieldConfig(),
      this.state.projectRequest,
      this.state.formErrors
    );
    //console.log("Validating form  request, isValid - " + isValid);
    //console.log(this.state.projectRequest);
    //console.log(this.state.formErrors)
    if (isValid) {
      let defaultNotificationEmails = commonUtils.getAppConfigValue(
        this.props.createConfig,
        "defaultNotificationEmails"
      );
      const defaultNotificationEmailsForFinanceTeam =
        commonUtils.getAppConfigValue(
          this.props.createConfig,
          "defaultNotificationEmailsForFinanceTeam"
        );
      const defaultPIINotificationEmails = commonUtils.getAppConfigValue(
        this.props.createConfig,
        "defaultPIINotificationEmails"
      );
      if (
        this.state.projectRequest.hasPPI &&
        this.state.projectRequest.hasPPI === true
      ) {
        defaultNotificationEmails = [
          ...(defaultNotificationEmails || []),
          ...(defaultPIINotificationEmails || []),
        ];
      }
      let projectRequestToBeSaved = {
        ...this.state.projectRequest,
        watchers: [
          ...(this.state.projectRequest.watchers || []),
          ...(defaultNotificationEmails ? defaultNotificationEmails : []),
        ],
        financeTeamWatchers: [
          ...(defaultNotificationEmailsForFinanceTeam
            ? defaultNotificationEmailsForFinanceTeam
            : []),
        ],
      };
      this.props.handleSubmit(
        projectRequestToBeSaved,
        this.props.editMode,
        isCreateAnotherEnabled,
        !this.props.editMode ? this.initializeRequest : null
      );
    } else {
      this.setState({
        hasFormError: true,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.projectRequest && (
          <>
            <div className="card-body pl-3 pr-3 overflow-auto">
              <div className="row">
                <div className="card pb-0 mb-0">
                  <div className="card-header pt-0 pb-1 d-none  ">
                    <span className="text-color-gray-aaa">
                      <i className="fa fa-info-circle pr-1"></i>Basic details
                    </span>
                    <div className="card-options">
                      <button
                        className="link card-options-collapse"
                        data-toggle="card-collapse"
                      >
                        <i className="fe fe-chevron-up"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 row">
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-6">
                          {/* <CustomInput
                            inputtype='text'
                            label="PO Number"
                            name="poNumber"
                            editMode={this.props.editMode}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="PO Number..."
                            datatype='alpnumspace' />
                           */}
                          <CustomInput
                            type="select"
                            label="Purchase Order"
                            name="purchaseOrderId"
                            editMode={this.props.editMode}
                            fieldConfig={this.getFieldConfig()}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onPurchaseOrderChange}
                            // onCreateSelectChange={this.onCreateSelectChange}
                            // onNewItemSelected={this.onNewItemSelected}
                            onFormError={this.onFormError}
                            placeholder="Purchase Order Number..."
                            options={
                              this.props.purchaseOrders &&
                              commonUtils.convertPurchaseOrderNameToSelectObject(
                                this.props.purchaseOrders
                              )
                            }
                          />
                        </div>

                        <div className="col-6">
                          <CustomInput
                            inputtype="text"
                            label="Order Book Ref"
                            name="orderBookRef"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Order Book Ref..."
                            datatype="alpnumspace"
                          />
                        </div>
                      </div>
                      <div className="col-12 px-0">
                        <CustomInput
                          inputtype="text"
                          label="Project Name"
                          name="name"
                          autoComplete="on"
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.projectRequest}
                          onChange={this.onChange}
                          onFormError={this.onFormError}
                          placeholder="Project Name..."
                          datatype="alpnumspl"
                        />
                      </div>
                      <div className="col-12 px-0">
                        {commonUtils.getAppConfigValue(
                          this.props.createConfig,
                          "enableCategorySelection"
                        ) &&
                          !(
                            commonUtils.getAppConfigValue(
                              this.props.createConfig,
                              "enableServiceLineSelection"
                            ) ||
                            commonUtils.getAppConfigValue(
                              this.props.createConfig,
                              "enableServiceLineEstimate"
                            )
                          ) && (
                            <CustomInput
                              type="select"
                              createConfig={this.props.createConfig}
                              label="Project Category"
                              name="category"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              placeholder="Category..."
                              isAppConfig={true}
                              onFormError={this.onFormError}
                            />
                          )}
                        {commonUtils.getAppConfigValue(
                          this.props.createConfig,
                          "enableServiceLineSelection"
                        ) &&
                          !commonUtils.getAppConfigValue(
                            this.props.createConfig,
                            "enableServiceLineEstimate"
                          ) && (
                            <CustomInput
                              type="select"
                              label="Service Line"
                              name="category"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              placeholder="Category..."
                              options={
                                this.props.createConfig &&
                                workItemUtils.getServiceLines(
                                  this.props.createConfig
                                )
                              }
                              onFormError={this.onFormError}
                            />
                          )}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type="dateselect"
                            label="Estimated Start Date"
                            name="estStartTime"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            time={false}
                            onFormError={this.onFormError}
                            minDate={
                              this.props.permission
                                .projectDatesRestrictedByPurchaseOrderDates
                                ? this.state.purchaseOrderStartTime
                                : null
                            }
                            maxDate={
                              this.props.permission
                                .projectDatesRestrictedByPurchaseOrderDates
                                ? this.state.purchaseOrderCompletionTime
                                : null
                            }
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="dateselect"
                            label="Estimated Completion Date"
                            name="estCompletionTime"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            time={false}
                            onFormError={this.onFormError}
                            minDate={
                              this.props.permission
                                .projectDatesRestrictedByPurchaseOrderDates
                                ? this.state.purchaseOrderStartTime
                                : null
                            }
                            maxDate={
                              this.props.permission
                                .projectDatesRestrictedByPurchaseOrderDates
                                ? this.state.purchaseOrderCompletionTime
                                : null
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Account"
                            name="accountName"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Account..."
                            options={
                              this.props.allProjectTeamAccounts &&
                              commonUtils.getAccountOptions(
                                this.props.allProjectTeamAccounts
                              )
                            }
                            onFormError={this.onFormError}
                            readOnly={this.state.projectRequest.purchaseOrderId}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Team"
                            name="teamName"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Team..."
                            options={
                              this.props.allProjectTeamAccounts &&
                              this.state.projectRequest &&
                              commonUtils.getTeamOptions(
                                this.props.allProjectTeamAccounts,
                                this.state.projectRequest.accountName
                              )
                            }
                            onFormError={this.onFormError}
                            readOnly={this.state.projectRequest.purchaseOrderId}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Client"
                            name="clientNames"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Client..."
                            options={
                              this.props.allProjectTeamAccounts &&
                              this.state.projectRequest &&
                              commonUtils.getClientsOptions(
                                this.props.allProjectTeamAccounts,
                                this.state.projectRequest.accountName,
                                this.state.projectRequest.teamName
                              )
                            }
                            onFormError={this.onFormError}
                            multi={true}
                            //readOnly={this.state.projectRequest.purchaseOrderId}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Location"
                            name="location"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Location..."
                            options={[
                              { value: "Offshore", label: "Offshore" },
                              { value: "Onshore", label: "Onshore" },
                            ]}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            inputtype="text"
                            label="Client Partner"
                            name="clientPartner"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Client Partner/Sales Person..."
                            datatype="alpnumspace"
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Project Type"
                            name="projectType"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Project Type..."
                            options={[
                              { value: "ADHOC", label: "Adhoc" },
                              { value: "FTEMS", label: "FTE-Managed Services" },
                              { value: "FTESA", label: "FTE-Staff-Aug" },
                              { value: "SERVICES", label: "Services" },
                              { value: "PRODUCT", label: "Product" },
                              { value: "TRACKER", label: "Tracker" },
                              {
                                value: "TMHB",
                                label: "T & M (Hourly Billing)",
                              },
                            ]}
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Service Category"
                            name="serviceCategory"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Service Category..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Survey Type"
                            name="surveyType"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Survey Type..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <CustomInput
                            type="text"
                            label="Phone Number"
                            name="phoneNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Phone Number..."
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-3">
                          <CustomInput
                            type="select"
                            label="Time Zone"
                            name="timezone"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Time Zone..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type="select"
                            label="Currency"
                            name="currency"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Currency..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>
                        <div className="col-3">
                          <CustomInput
                            type="text"
                            label="Loi"
                            name="loi"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Loi..."
                            onFormError={this.onFormError}
                            disabled={false}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-3">
                          <CustomInput
                            type="select"
                            label="Sample"
                            name="sample"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Sample..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>
                        <div className="col-3">
                          <CustomInput
                            type="text"
                            label="Target Audience"
                            name="targetAudience"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Target Audience..."
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type="text"
                            label="Sample Targeting"
                            name="sampleTargeting"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Sample Targeting..."
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type="text"
                            label="Estimated IR"
                            name="estimatedIR"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Estimated IR..."
                            onFormError={this.onFormError}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <CustomInput
                            type="radios"
                            label="Has PII?"
                            name="hasPPI"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="PII..."
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-4">
                          <CustomInput
                            type="radios"
                            label="Automation Enabled?"
                            name="automationEnabled"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Automation Enabled"
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-4">
                          <CustomInput
                            type="radios"
                            label="Revised Est Enabled?"
                            name="revisedEstEnabled"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Revised Est Enabledd"
                            onFormError={this.onFormError}
                          />
                        </div>
                        {/*<div className="col-6">
                          <CustomInput inputtype='number'
                            label="Estimated Duration in Hrs"
                            name="estHours"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="0"
                            datatype='num'
                          />
                         </div>*/}
                      </div>
                    </div>

                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-4">
                          <CustomInput
                            inputtype="text"
                            label="Purchase Order Number"
                            name="purchaseOrderNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Purchase Order Number..."
                            datatype="alpnumspace"
                            readOnly={this.state.projectRequest.purchaseOrderId}
                          />
                        </div>
                        <div className="col-4">
                          <CustomInput
                            inputtype="text"
                            label="Proposal Number"
                            name="proposalNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Proposal Number..."
                            datatype="alpnumspace"
                            readOnly={this.state.projectRequest.purchaseOrderId}
                          />
                        </div>
                        <div className="col-4 ">
                          <CustomInput
                            inputtype="text"
                            label="Project code"
                            name="code"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Project Code..."
                            datatype="alpnumspace"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <CustomInput
                            type="textarea"
                            label="Summary"
                            name="summary"
                            rows={4}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Summary..."
                            onFormError={this.onFormError}
                            datatype="alpnumspl"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            createConfig={this.props.createConfig}
                            label="Product Name"
                            name="productName"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Product Name..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Type"
                            name="type"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Type..."
                            options={[
                              { value: "BILLABLE", label: "Billable" },
                              { value: "BILLABLE_DA", label: "Billable DA" },
                              {
                                value: "NON_BILLABLE_PRODUCTIVE",
                                label: "Non Billable Productive",
                              },
                              { value: "NON_BILLABLE", label: "Non Billable" },
                            ]}
                            onFormError={this.onFormError}
                            readOnly={this.state.projectRequest.purchaseOrderId}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            type="select"
                            label="Project Owner User Team"
                            name="userTeamId"
                            isIdAsSelectValue={false}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Project Owner User Team..."
                            options={
                              commonUtils.getUserTeamsFromUserItem() &&
                              commonUtils.convertObjectToSelectObject(
                                commonUtils.getUserTeamsFromUserItem()
                              )
                            }
                            //options={this.props.allTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allTeams)}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type="select"
                            label="Project Lead"
                            name="owner"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Project Owner..."
                            options={
                              this.props.allEmployees &&
                              commonUtils.convertObjectToSelectObject(
                                this.props.allEmployees
                              )
                            }
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <CustomInput
                            type="select"
                            label="Complexity"
                            name="complexity"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Complexity..."
                            options={[
                              { value: "BASIC", label: "Basic" },
                              { value: "MEDIUM", label: "Medium" },
                              { value: "COMPLEX", label: "Complex" },
                            ]}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-4">
                          <CustomInput
                            type="select"
                            label="Priority"
                            name="priority"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Priority..."
                            options={[
                              { value: "URGENT", label: "Urgent" },
                              { value: "HIGH", label: "High" },
                              { value: "MEDIUM", label: "Medium" },
                              { value: "LOW", label: "Low" },
                            ]}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-4">
                          <CustomInput
                            type="select"
                            label="Capex/Opex"
                            name="capexOpex"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.projectRequest}
                            onChange={this.onChange}
                            placeholder="Capex/Opex..."
                            options={[
                              { value: "Capex", label: "Capex" },
                              { value: "Opex", label: "Opex" },
                            ]}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      {this.props.editMode && (
                        <div className="row">
                          <div className="col-4">
                            <CustomInput
                              type="radios"
                              label="Order Changed?"
                              name="isOrderChanged"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              placeholder="Order Changed?"
                              onFormError={this.onFormError}
                            />
                          </div>
                          <div className="col-4">
                            <CustomInput
                              inputtype="text"
                              label="Revised Additional Hours"
                              name="revisedAdditionalHrs"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Additional Hours..."
                              datatype="num"
                              readOnly={
                                this.state.projectRequest.isOrderChanged ===
                                  undefined ||
                                this.state.projectRequest.isOrderChanged ===
                                  false
                              }
                            />
                          </div>
                          <div className="col-4">
                            <CustomInput
                              inputtype="text"
                              label="Revised Additional Cost ($)"
                              name="revisedAdditionalCost"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Additional Cost..."
                              datatype="num"
                              readOnly={
                                this.state.projectRequest.isOrderChanged ===
                                  undefined ||
                                this.state.projectRequest.isOrderChanged ===
                                  false
                              }
                            />
                          </div>
                        </div>
                      )}
                      {!commonUtils.getAppConfigValue(
                        this.props.createConfig,
                        "enableServiceLineEstimate"
                      ) && (
                        <div className="row">
                          <div className="col-4">
                            <CustomInput
                              inputtype="text"
                              label="Estimated Cost ($)"
                              name="estCost"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Estimated Cost..."
                              datatype="num"
                            />
                          </div>
                          <div className="col-4">
                            <CustomInput
                              inputtype="text"
                              label="Est Hourly Rate ($)"
                              name="estHourlyRate"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Estimated Hourly Rate..."
                              datatype="num"
                            />
                          </div>
                          <div className="col-4 ">
                            <CustomInput
                              inputtype="text"
                              label="Estimated Hours"
                              name="estHours"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.projectRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Estimated Hours..."
                              datatype="num"
                            />
                          </div>
                        </div>
                      )}

                      {commonUtils.getAppConfigValue(
                        this.props.createConfig,
                        "enableServiceLineEstimate"
                      ) && (
                        <ServiceLineEstimate
                          name="serviceLineEstimate"
                          readOnly={this.props.readOnly}
                          config={{
                            estHourEditEnabled:
                              this.props.permission
                                .enableServiceLineEstimateHourEditable,
                            estHourViewEnabled:
                              this.props.permission
                                .enableServiceLineEstimateHourVisible,
                            estHourlyCostEditEnabled:
                              this.props.permission
                                .enableServiceLineEstimateCostEditable,
                            estHourlyCostViewEnabled:
                              this.props.permission
                                .enableServiceLineEstimateCostVisible,
                          }}
                          onChange={this.onChange}
                          serviceLineEstimate={
                            this.state.projectRequest.serviceLineEstimate || {}
                          }
                          parentServiceLineEstimate={
                            this.state.purchaseOrderServiceLineEstimate || {}
                          }
                          selectedServiceLines={
                            this.state.projectRequest.serviceLines
                          }
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.projectRequest}
                          serviceLineOptions={
                            this.state.projectRequest.purchaseOrderId
                              ? commonUtils.convertConfigListToSelectObject(
                                  this.state.purchaseOrderServiceLines
                                )
                              : this.props.createConfig &&
                                workItemUtils.getServiceLines(
                                  this.props.createConfig
                                )
                          }
                          onFormError={this.onFormError}
                        />
                      )}
                      {this.state.projectRequest &&
                        this.state.projectRequest.automationEnabled && (
                          <div className="row">
                            <div className="col-6">
                              <CustomInput
                                type="select"
                                createConfig={this.props.createConfig}
                                label="Tool Used"
                                name="toolUsed"
                                fieldConfig={this.props.fieldConfig}
                                mappingObject={this.state.projectRequest}
                                onChange={this.onChange}
                                isAppConfig={true}
                                configName="toolNames"
                                onFormError={this.onFormError}
                                placeholder="Tool Used..."
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <ProjectTaskAccessRestrictionControlInput
                userTeams={this.props.allUserTeams}
                employeeAppConfig={this.props.employeeAppConfig}
                allEmployees={this.props.allEmployees}
                fieldConfig={this.props.fieldConfig}
                mappingObject={this.state.projectRequest}
                onChange={this.onChange}
                onFormError={this.onFormError}
                permission={this.props.permission}
              />

              {/*<div className="row d-none">
              <ProjectAccessControlInput
                label="Project Collaboration and Estimation"
                name="accessControl"
                fieldConfig={this.props.fieldConfig}
                mappingObject={this.state.projectRequest}
                onChange={this.onChange}
                placeholder="Project Lead..."
                options={this.props.allUserTeams && commonUtils.convertObjectToSelectObject(this.props.allUserTeams)} 
                onFormError={this.onFormError}
                currentTeam='99999'
              />
            </div>

            <div className="row d-none">
              <div className="card pb-0 mb-0">
                <div className="card-header pt-0 pb-1">
                  <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Notifications</span>
                  <div className="card-options">
                    <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                  </div>
                </div>
                <div className="col-12 row">
                  <div className="col-12 col-lg-6">

                  </div>
                </div>
              </div>
            </div>
    */}
            </div>
            <SidebarFooter
              handleSubmit={this.handleSubmit}
              editMode={this.props.editMode}
              duplicateMode={this.props.duplicateMode}
              initializeRequest={this.initializeRequest}
              isProcessing={this.props.isProcessing}
              hasFormError={this.state.hasFormError}
              onResetFormError={this.onResetFormError}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.authentication.user,
  fieldConfig: state.projects.fieldConfig,
  createConfig: state.projects.createConfig,
  allProjectTeamAccounts: state.accounts.allProjectTeamAccounts,
  employeeAppConfig: state.employees.createConfig,
  allUserTeams: state.appconfig.allTeams,
  allEmployees: state.employees.allEmployees,
  creating: state.projects.creating,
  updating: state.projects.updating,
  purchaseOrders: state.purchaseOrders.activeItems,
});

const mapDispatchToProps = (dispatch) => ({
  initializeCreateProject: projectActions.initializeCreateProject,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectSidebar);
