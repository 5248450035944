import React, { Component } from "react";

class BrandNameLogo extends Component {
    render() {
        return (

            <div className="text-center mb-1">
                <div className="header-brand">
                    <img alt="famstack" src="/favicon.ico" style={{ width: 30 + 'px' }}></img>
                    <span className="brand-name pl-2">famstack</span><span className="fsize15 align-top">&#174;</span>
                </div>
            </div>
        );
    }
}

export default BrandNameLogo;
