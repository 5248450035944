import { accessUtils } from "_helpers";
import React, { Component } from "react";
import PageInnerHeader from "views/common/PageInnerHeader";
import ConfigureFeedbackTemplateSidebar from "./modal/ConfigureFeedbackTemplateSidebar";
import { feedbackTemplateConstants } from "_constants/feedback.constants";
import { store } from "_helpers";
import { connect } from "react-redux";
import SidebarFooter from "./modal/SidebarFooter";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { commonUtils } from "_helpers";
import { validationUtils } from "_helpers";
import Skeleton from "react-loading-skeleton";
import dateUtils from "_helpers/date.utils";
import CronJobSelectionMenu from "views/common/fragments/CronJobSelectionMenu";
import classNames from "classnames";
import { feedbackTemplateActions } from "_actions";
import { feedbackRequestActions } from "_actions";
import ItemNotFoundSection from "./ItemsNotFoundSection";
import SelectInput from "views/common/fragments/formfields/SelectInput";
import moment from "moment";
import { feedbackRequestConstants } from "_constants";

class FeedbackPageSection extends Component {
  constructor() {
    super();
    this.state = {
      viewMode: "PAGE",
      filterOptions: {
        SHOW: {
          options: [
            { name: "ALL", label: "All" },
            { name: "ACTIVE", label: "Active" },
            { name: "INACTIVE", label: "In Active" },
          ],
          selected: "ALL",
        },
        SORT: {
          options: [
            { name: "CREATEDDATE", label: "Created Date" },
            { name: "A-Z", label: "A-Z" },
          ],
          selected: "CREATEDDATE",
          directionUp: true,
        },
        filterText: null,
      },
      permission: accessUtils.getFeedbackTemplateAccessControl(),
      permissionFeedbackRequest: accessUtils.getFeedbackRequestAccessControl(),
      editMode: false,
      showCreateWindow: false,
      formErrors: {},
    };
  }
  componentDidMount() {
    this.setState({
      type: this.props.feedbackType,
    });

    if (this.props.feedbackType) {
      this.setState({
        viewMode: this.props.feedbackType,
      });
      store.dispatch(
        this.props.getAllFeedbackTemplates({
          itemType: this.props.feedbackType,
        })
      );
      store.dispatch(
        this.props.getAllFeedbackRequests({
          itemType: this.props.feedbackType,
          itemId: this.props.projectItem.id,
        })
      );
    } else {
      store.dispatch(
        this.props.getAllFeedbackRequests({
          itemType: "PROJECT",
        })
      );
      this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    }
    this.initialize();
  }

  initialize = () => {
    //store.dispatch(employeeActions.getAllEmployees())
    this.setState({
      showCroDropdownMenu: false,
      editMode: false,
      feedbackRequest: {
        userTeamId: commonUtils.getCurrentUserTeamId(),
        type: this.state.type || this.props.feedbackType,
        subType: "GENERAL",
        itemId: this.props.projectItem ? this.props.projectItem.id : null,
        itemName: this.props.projectItem ? this.props.projectItem.name : null,
        recurring: false,
        draft: false,
        active: true,
        feedbackRequestInfos: [],
      },
    });
  };

  onChange = (feedbackRequest) => {
    this.setState({
      feedbackRequest: feedbackRequest,
    });
  };

  onCreateItemClick = () => {
    this.setState({
      showCreateWindow: true,
    });
    store.dispatch(
      this.props.getAllFeedbackTemplates({ itemType: this.state.type })
    );
    this.initialize();
  };

  closeCreateItemWindow = () => {
    this.setState({
      showCreateWindow: false,
      editMode: false,
    });
  };

  editFeedbackRequest = (feedbackRequest) => {
    if (feedbackRequest !== null) {
      this.setState({
        showCreateWindow: true,
        feedbackRequest: feedbackRequest,
        editMode: true,
      });
    } else {
      this.setState({
        feedbackRequest: null,
      });
    }
  };

  createFeedbackRequest = (createAnother) => {
    store.dispatch(
      this.props.createFeedbackRequest(
        this.state.feedbackRequest,
        this.initialize
      )
    );
    if (createAnother === false) {
      this.closeCreateItemWindow();
    }
  };

  updateFeedbackRequest = () => {
    store.dispatch(
      this.props.updateFeedback(this.state.feedbackRequest, this.initialize)
    );
    this.closeCreateItemWindow();
  };

  showFeedbackTemplateConfigureWindow = () => {
    store.dispatch({
      type: feedbackTemplateConstants.SHOW_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW,
    });
  };

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions }, () => {
      store.dispatch({
        type: feedbackRequestConstants.CHANGE_FILTER_FEEDBACKS,
        filterOptions,
      });
    });
  };

  onFilterTextChange = (value) => {
    this.setState(
      (prevState) => ({
        filterOptions: {
          ...prevState.filterOptions,
          filterText: value,
        },
      }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  };

  render() {
    return (
      <div className="col-md-12 col-xs-12 px-0">
        <div className="card p-0 m-0">
          <PageInnerHeader
            userTeams={[]}
            handleUserTeamsSelection={this.handleUserTeamsSelection}
            //showCreateMenu={this.state.permission.createEnabled}
            showCreateMenu={
              this.state.showCreateWindow === false &&
              this.state.viewMode !== "PAGE" &&
              this.state.permissionFeedbackRequest.createEnabled === true
            }
            showGridListMenu={false}
            searchViewEnabled={false}
            handleSearchSubmit={this.handleSearchSubmit}
            showFilter={true}
            showUserTeamMenu={true}
            isLoading={this.props.isLoading}
            filterOptions={
              this.props.filterOptions
                ? this.props.filterOptions
                : this.state.filterOptions
            }
            handleFilterChange={this.handleFilterChange}
            onFilterTextChange={this.onFilterTextChange}
            showCreateMenuText="Request Feedback"
            createItemHandler={this.onCreateItemClick}
            showDateRangeSelector={false}
            handleDateRangeChange={this.handleDateRangeChange}
            permission={this.state.permission}
            additionalButtons={
              this.state.permission.createEnabled === true &&
              this.state.viewMode !== "PAGE" ? (
                <button
                  type="button"
                  className="link create-link pr-2 float-right"
                  title="Configure Template"
                  onClick={() => this.showFeedbackTemplateConfigureWindow()}
                >
                  <i className="fa fa-cogs colorblue pr-1"></i>Configure
                  Template
                </button>
              ) : (
                <></>
              )
            }
          ></PageInnerHeader>
        </div>
        {this.state.showCreateWindow && this.state.feedbackRequest && (
          <div className="card-body bgcolorwhite">
            <CreateFeedbackRequestSection
              initialize={this.initialize}
              editMode={this.state.editMode}
              feedbackRequest={this.state.feedbackRequest}
              fieldConfig={this.props.feedbackFieldConfig}
              allEmployees={this.props.allEmployees}
              feedbackTypeOptions={this.props.feedbackTypeOptions}
              feedbackSubTypeOptions={this.props.feedbackSubTypeOptions}
              userTeamId={this.props.projectItem.userTeamId}
              type={this.state.type || this.props.feedbackType}
              itemId={this.props.projectItem.id}
              itemName={this.props.projectItem.name}
              closeCreateItemWindow={this.closeCreateItemWindow}
              onChange={this.onChange}
              permission={this.state.permissionFeedbackRequest}
              createFeedbackRequest={this.createFeedbackRequest}
              updateFeedbackRequest={this.updateFeedbackRequest}
              emailInfoFieldConfig={this.props.emailInfoFieldConfig}
              feedbackTemplateDataList={this.props.feedbackTemplateDataList}
            />
          </div>
        )}
        {this.state.viewMode !== "PAGE" && (
          <FeedbackRequestsTable
            items={this.props.items}
            editMode={this.state.editMode || this.state.showCreateWindow}
            getAllFeedbackScheulers={this.handleGetAllFeedbackScheulers}
            permission={this.state.permissionFeedbackRequest}
            enableFeedback={this.props.enableFeedback}
            removeFeedback={this.props.removeFeedback}
            removeFeedbackRequest={this.props.removeFeedbackRequest}
            remindFeedbackRequest={this.props.remindFeedbackRequest}
            updateFeedbackRequestStatus={this.props.updateFeedbackRequestStatus}
            editEnabled={true}
            editFeedbackRequest={this.editFeedbackRequest}
            allUserTeams={this.props.allUserTeams}
            allEmployees={this.props.allEmployees}
          />
        )}
        {this.state.viewMode === "PAGE" && this.props.items && (
          <FeedbackRequestsTableByItem
            items={this.props.items}
            editMode={this.state.editMode || this.state.showCreateWindow}
            getAllFeedbackScheulers={this.handleGetAllFeedbackScheulers}
            permission={this.state.permissionFeedbackRequest}
            enableFeedback={this.props.enableFeedback}
            removeFeedback={this.props.removeFeedback}
            removeFeedbackRequest={this.props.removeFeedbackRequest}
            remindFeedbackRequest={this.props.remindFeedbackRequest}
            updateFeedbackRequestStatus={this.props.updateFeedbackRequestStatus}
            allUserTeams={this.props.allUserTeams}
            allEmployees={this.props.allEmployees}
          />
        )}
        <ConfigureFeedbackTemplateSidebar
          type={this.props.feedbackType}
          show={this.props.showFeedbackTemplateConfigureModel}
        />
      </div>
    );
  }
}

class CreateFeedbackRequestSection extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false,
    };
  }

  componentDidMount() {}
  onResetFormError = () => {
    this.setState({
      hasFormError: false,
    });
  };

  getFeedbackTypeOptions = () => {
    if (this.props.type && this.props.feedbackTypeOptions) {
      return this.props.feedbackTypeOptions.filter(
        (type) => type.value === this.props.type
      );
    }
    return this.props.feedbackTypeOptions;
  };

  getFeedbackSubTypeOptions = () => {
    return this.props.feedbackSubTypeOptions;
  };

  getItemOptions = () => {
    if (this.props.itemId) {
      return [{ value: this.props.itemId, label: this.props.itemName }];
    }
    //get all project or po or bid or employees
  };

  getFeedbackTemplateDataList = () => {
    return (
      this.props.feedbackTemplateDataList &&
      this.props.feedbackTemplateDataList.filter(
        (ftdl) => ftdl.subType === this.props.feedbackRequest.subType
      )
    );
  };
  onCreateAnotherEnabled = (value) => {
    this.setState({
      createAnotherEnabled: value,
    });
  };

  onFormError = (fieldName, hasError) => {
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError,
      },
    }));
  };

  onChange = (fieldName, value) => {
    this.onResetFormError();
    var scheduleData = null;
    var templateId =
      fieldName === "templateId"
        ? value
        : this.props.feedbackRequest.templateId;
    if (fieldName === "subType") {
      templateId = null;
    }
    if (fieldName === "recurring") {
      if (value === true) {
        scheduleData = {
          cronValue: "0 0 00 ? * SUN ",
          cronString: "At 12:00 AM, only on Sunday",
        };
      }
    }
    this.props.onChange({
      ...this.props.feedbackRequest,
      [fieldName]: value,
      templateId: templateId,
      scheduleData:
        fieldName === "recurring"
          ? scheduleData
          : this.props.feedbackRequest.scheduleData,
    });
  };

  onCronValueChange = (cronValue, cronString) => {
    this.props.onChange({
      ...this.props.feedbackRequest,
      scheduleData: {
        ...this.props.feedbackRequest.scheduleData,
        cronString: cronString,
        cronValue: cronValue.substr(0, cronValue.length - 1),
      },
    });
    this.toggeleCronMenu(false);
  };

  onChangeScheduleData = (fieldName, value) => {
    this.props.onChange({
      ...this.props.feedbackRequest,
      scheduleData: {
        ...this.props.feedbackRequest.scheduleData,
        [fieldName]: value,
      },
    });
  };
  toggeleCronMenu = (isShow) => {
    this.setState((prevState) => ({
      showCroDropdownMenu: isShow,
    }));
  };

  handleSubmit = (createAnotherEnabled) => {
    let isValid = validationUtils.isValidRequest(
      this.props.fieldConfig,
      this.props.feedbackRequest,
      this.state.formErrors
    );
    if (isValid) {
      if (this.props.editMode === true) {
        this.props.updateFeedbackRequest(false);
        //this.props.toggeleCreateWindow(false);
        //this.props.toggeleEditMote(false)
      } else {
        this.props.createFeedbackRequest(createAnotherEnabled);
      }
    } else {
      this.setState({
        hasFormError: true,
      });
    }
  };

  render() {
    return (
      <>
        {this.props.feedbackRequest && (
          <>
            <div className="card-body pt-0 mt-0 ml-3 mr-3 pl-2">
              <div className="row">
                <div className="card pb-0 mb-0 pl-3">
                  <div className="row p-0 m-0">
                    <div className="col-12 row">
                      <div className="col-7 row">
                        <div className="col-6 row">
                          <div className="col-12">
                            <CustomInput
                              inputtype="text"
                              label="Name"
                              name="name"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Feeback Request Name..."
                              datatype="alpnumspl"
                            />
                          </div>
                          <div className="col-12">
                            <CustomInput
                              label="Description"
                              name="description"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Description..."
                              datatype="alpnumspl"
                            />
                          </div>

                          <div className="col-12">
                            <CustomInput
                              label="Email Subject"
                              name="emailSubject"
                              type="textarea"
                              height={"29px"}
                              rows={1}
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Email Subject..."
                              datatype="alpnumspl"
                            />
                          </div>
                        </div>
                        <div className="col-6 row">
                          <div className="col-6">
                            {this.props.feedbackTypeOptions && (
                              <CustomInput
                                type="select"
                                label="Feedback Type"
                                name="type"
                                fieldConfig={this.props.fieldConfig}
                                mappingObject={this.props.feedbackRequest}
                                onChange={this.onChange}
                                placeholder="Feedback..."
                                options={this.getFeedbackTypeOptions()}
                                onFormError={this.onFormError}
                              />
                            )}
                          </div>
                          <div className="col-6">
                            <CustomInput
                              type="select"
                              label="Item Id"
                              name="itemId"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              placeholder="Item Id..."
                              options={this.getItemOptions()}
                              onFormError={this.onFormError}
                            />
                          </div>
                          <div className="col-6">
                            <CustomInput
                              type="select"
                              label="Sub Type"
                              name="subType"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              placeholder="Feedback..."
                              options={this.getFeedbackSubTypeOptions()}
                              onFormError={this.onFormError}
                            />
                          </div>
                          <div className="col-6">
                            {this.props.feedbackTemplateDataList && (
                              <CustomInput
                                type="select"
                                label="Feedback Template"
                                name="templateId"
                                fieldConfig={this.props.fieldConfig}
                                mappingObject={this.props.feedbackRequest}
                                onChange={this.onChange}
                                placeholder="Feedback..."
                                options={commonUtils.convertObjectToSelectObject(
                                  this.getFeedbackTemplateDataList()
                                )}
                                onFormError={this.onFormError}
                              />
                            )}
                          </div>
                          <div className="col-6">
                            <CustomInput
                              type="dateselect"
                              label="Expiry Date"
                              name="expiryDate"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              time={false}
                              onFormError={this.onFormError}
                              minDate={new Date()}
                              currentDate={new Date()}
                            />
                          </div>
                          <div className="col-6">
                            <CustomInput
                              type="radios"
                              label="Create as Draft?"
                              name="draft"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              placeholder="Is Draft..."
                              onFormError={this.onFormError}
                            />
                          </div>
                        </div>
                        <div className="col-12 row bgcolorgray pt-2">
                          <div className="col-3">
                            <CustomInput
                              readOnly={this.props.readOnly}
                              type="radios"
                              label="Is a Recurring?"
                              name="recurring"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.props.feedbackRequest}
                              onChange={this.onChange}
                              placeholder="Recurring..."
                              onFormError={this.onFormError}
                            />
                          </div>

                          <div className="col-3">
                            <CustomInput
                              readOnly={
                                !(
                                  this.props.feedbackRequest.recurring &&
                                  this.props.feedbackRequest.recurring === true
                                )
                              }
                              type="dateselect"
                              label="End Date"
                              name="endDate"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={
                                this.props.feedbackRequest.scheduleData || {}
                              }
                              onChange={this.onChangeScheduleData}
                              time={false}
                              onFormError={this.onFormError}
                              minDate={new Date()}
                              currentDate={new Date()}
                            />
                          </div>
                          <div className="col-6">
                            {this.props.feedbackRequest.recurring &&
                              this.props.feedbackRequest.recurring === true && (
                                <>
                                  <label className="form-label text-muted pr-3">
                                    Schedule
                                    <span className="form-required"></span>
                                  </label>

                                  <i
                                    className="fa fa-recycle pr-1 colorblue"
                                    onClick={() =>
                                      this.toggeleCronMenu(
                                        !this.state.showCroDropdownMenu
                                      )
                                    }
                                  />
                                  <CronJobSelectionMenu
                                    showDropDown={
                                      this.state.showCroDropdownMenu
                                    }
                                    closeDropDwonMenu={this.toggeleCronMenu}
                                    onChange={this.onCronValueChange}
                                    cronValue={
                                      this.props.feedbackRequest.scheduleData
                                        .cronValue
                                    }
                                  />
                                  <span
                                    className="fsize13 fweight600 menu-text-black mr-5"
                                    onClick={() =>
                                      this.toggeleCronMenu(
                                        !this.state.showCroDropdownMenu
                                      )
                                    }
                                  >
                                    {
                                      this.props.feedbackRequest.scheduleData
                                        .cronString
                                    }
                                  </span>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="col-12">
                          {this.props.feedbackRequest && (
                            <EmailSelectionInput
                              mappingObject={this.props.feedbackRequest}
                              fieldConfig={this.props.emailInfoFieldConfig}
                              onChange={this.onChange}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SidebarFooter
              handleSubmit={this.handleSubmit}
              editMode={this.props.editMode}
              closeButtonLabel={"Cancel"}
              createButtonLabel={"Create Feedback Request"}
              initializeRequest={this.props.closeCreateItemWindow}
              hasFormError={this.state.hasFormError}
              onResetFormError={this.onResetFormError}
            />
          </>
        )}
      </>
    );
  }
}

class EmailSelectionInput extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false,
      feedbackRequestInfos: {},
      showAddEmailMenu: false,
    };
  }

  toggleEmailShowMenu = (isShow) => {
    this.setState({
      showAddEmailMenu: isShow,
    });
  };

  onChange = (name, value) => {
    this.setState({
      feedbackRequestInfos: {
        ...this.state.feedbackRequestInfos,
        [name]: value,
      },
    });
  };
  onAddFeedbackInfo = () => {
    if (
      this.state.feedbackRequestInfos &&
      this.state.feedbackRequestInfos.personEmail
    ) {
      this.props.onChange("feedbackRequestInfos", [
        ...this.props.mappingObject.feedbackRequestInfos,
        this.state.feedbackRequestInfos,
      ]);
    }
    this.setState({ feedbackRequestInfos: null });
  };

  onRemoveFeedbackEmailInfo = (emailId) => {
    this.props.onChange("feedbackRequestInfos", [
      ...this.props.mappingObject.feedbackRequestInfos.filter(
        (value) => value.personEmail != emailId
      ),
    ]);
  };
  onFormError = () => {};

  render() {
    return (
      <>
        <div>
          <label className="form-label text-muted">
            Emails<span className="form-required">*</span>
          </label>
        </div>
        <div className="col-12 row">
          <div className="table-responsive p-2">
            <table
              className={`table table-hover table-vcenter table-striped mb-0 ${
                !this.props.mappingObject ||
                !this.props.mappingObject.feedbackRequestInfos ||
                this.props.mappingObject.feedbackRequestInfos.length === 0
                  ? " borderRed"
                  : ""
              } `}
            >
              <thead>
                <tr>
                  <th>SlNo</th>
                  <th>Person Name</th>
                  <th>Person Email</th>
                  <th></th>
                </tr>
              </thead>
              {this.props.mappingObject &&
                this.props.mappingObject.feedbackRequestInfos &&
                this.props.mappingObject.feedbackRequestInfos.length > 0 && (
                  <tbody>
                    {this.props.mappingObject.feedbackRequestInfos.map(
                      (feedbackInfo, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{feedbackInfo.personName}</td>
                          <td>{feedbackInfo.personEmail}</td>
                          <td>
                            <button
                              onClick={() =>
                                this.onRemoveFeedbackEmailInfo(
                                  feedbackInfo.personEmail
                                )
                              }
                              className="link pl-2"
                              title="Remove"
                            >
                              <i className="dropdown-icon fa fa fa-times colorred"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                )}
            </table>
          </div>
        </div>

        {!this.state.showAddEmailMenu && (
          <button
            className="link colorblue pl-2 fsize10"
            onClick={() => this.toggleEmailShowMenu(true)}
          >
            Add Target Emails
          </button>
        )}

        {this.state.showAddEmailMenu && (
          <div className="col-12 row">
            <div className="col-5">
              <CustomInput
                inputtype="text"
                label="Person Name"
                name="personName"
                fieldConfig={this.props.fieldConfig}
                mappingObject={this.state.feedbackRequestInfos || {}}
                onChange={this.onChange}
                onFormError={this.onFormError}
                placeholder="Person Name..."
                datatype="alpnumspl"
              />
            </div>

            <div className="col-5 ">
              <CustomInput
                inputtype="email"
                label="Email"
                name="personEmail"
                fieldConfig={this.props.fieldConfig}
                mappingObject={this.state.feedbackRequestInfos || {}}
                onChange={this.onChange}
                onFormError={this.onFormError}
                placeholder="Email..."
                datatype="email"
              />
            </div>
            <div className="col-1 ptrem1dot3">
              <span>
                <button
                  onClick={() => this.onAddFeedbackInfo()}
                  className="btn btn-primary btn-sm"
                >
                  Add
                </button>
              </span>
            </div>
            <div className="col-1 ptrem1dot3">
              <span>
                <button
                  onClick={() => this.toggleEmailShowMenu(false)}
                  className="link pl-2 fsize10"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        )}
      </>
    );
  }
}

class FeedbackRequestsTableByItem extends Component {
  constructor() {
    super();
    this.state = {
      expandedIds: [],
      clicked: false,
    };
  }

  toggleExpand = (id) => {
    var expandedIds = this.state.expandedIds;
    if (!this.state.expandedIds.includes(id)) {
      expandedIds.push(id);
    } else {
      expandedIds = expandedIds.filter((item) => item !== id);
    }
    this.setState({ expandedIds, clicked: true });
  };

  render() {
    const listByItems =
      this.props.items &&
      this.props.items.reduce(function (r, a) {
        let itemId = a.itemId;
        r[itemId] = r[itemId] || [];
        r[itemId].push(a);
        return r;
      }, Object.create(null));
    return (
      <>
        {listByItems &&
          Object.keys(listByItems).map((key, index) => (
            <>
              <div className="bgcolorlightgray1 pl-2 mt-1 ml-1 pt-0 border-bottom d-flex justify-content-between align-items-center">
                <span
                  className="mx-1 fweight600 fsize15"
                  onClick={() => this.toggleExpand(key)}
                >
                  {(this.state.expandedIds.includes(key) ||
                    (this.state.expandedIds.length === 0 &&
                      index == 0 &&
                      this.state.clicked === false)) && (
                    <i className="fa fa-lg fa-caret-down mr-2"></i>
                  )}
                  {!(
                    this.state.expandedIds.includes(key) ||
                    (this.state.expandedIds.length === 0 &&
                      index == 0 &&
                      this.state.clicked === false)
                  ) && <i className="fa fa-lg fa-caret-up mr-2"></i>}
                  <span className="colorblue">
                    <i className="fa fa-wpforms mr-1 colorlightgreen"></i>[{key}
                    ]
                  </span>
                  <span className="pl-2">{listByItems[key][0].itemName}</span>
                </span>
                <a
                  className="mx-1 fweight600 fsize15 pr-5"
                  target="_new"
                  href={`/project/details/${key}?view=feedback`}
                >
                  <i className="fa fa-external-link"></i>
                </a>
              </div>
              {(this.state.expandedIds.includes(key) ||
                (this.state.expandedIds.length === 0 &&
                  index == 0 &&
                  this.state.clicked === false)) && (
                <div className="ml-2 border3gray">
                  <FeedbackRequestsTable
                    items={listByItems[key]}
                    editMode={false}
                    getAllFeedbackScheulers={this.handleGetAllFeedbackScheulers}
                    permission={this.props.permission}
                    enableFeedback={this.props.enableFeedback}
                    removeFeedback={this.props.removeFeedback}
                    removeFeedbackRequest={this.props.removeFeedbackRequest}
                    remindFeedbackRequest={this.props.remindFeedbackRequest}
                    updateFeedbackRequestStatus={
                      this.props.updateFeedbackRequestStatus
                    }
                    editEnabled={false}
                    editFeedbackRequest={() => {}}
                    allUserTeams={this.props.allUserTeams}
                    allEmployees={this.props.allEmployees}
                  />
                </div>
              )}
            </>
          ))}
      </>
    );
  }
}

class FeedbackRequestsTable extends Component {
  render() {
    return (
      <>
        <div className="table-responsive pt-1">
          {this.props.isLoading && (
            <table className="table table-hover table-vcenter table-striped">
              <tbody>
                <tr>
                  <td colSpan="12">
                    <Skeleton count={7} height={40} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {!this.props.isLoading && this.props.items && (
            <FeedbackRequestsTableItems
              items={this.props.items.filter((fr) => fr.recurring === false)}
              editMode={this.props.editMode}
              editEnabled={this.props.editEnabled}
              permission={this.props.permission}
              removeFeedbackRequest={this.props.removeFeedbackRequest}
              remindFeedbackRequest={this.props.remindFeedbackRequest}
              editFeedbackRequest={this.props.editFeedbackRequest}
              updateFeedbackRequestStatus={
                this.props.updateFeedbackRequestStatus
              }
              enableFeedback={this.props.enableFeedback}
              removeFeedback={this.props.removeFeedback}
            />
          )}
          <div className="fsize14 pt-2">
            {this.props.items &&
              this.props.items.filter((fr) => fr.recurring === true).length >
                0 && <>Recurring Feedback Request Templates</>}
          </div>
          {!this.props.isLoading && this.props.items && (
            <FeedbackRequestsTableItems
              items={this.props.items.filter((fr) => fr.recurring === true)}
              editMode={this.props.editMode}
              editEnabled={this.props.editEnabled}
              permission={this.props.permission}
              removeFeedbackRequest={this.props.removeFeedbackRequest}
              remindFeedbackRequest={this.props.remindFeedbackRequest}
              editFeedbackRequest={this.props.editFeedbackRequest}
              updateFeedbackRequestStatus={
                this.props.updateFeedbackRequestStatus
              }
              enableFeedback={this.props.enableFeedback}
              removeFeedback={this.props.removeFeedback}
            />
          )}
          <ItemNotFoundSection
            items={this.props.items}
            imgurl="no_feedback_template.png"
            text={`Feedback Requests Not Added ?`}
            description="Try Adding a Feedback Requests..."
          />
        </div>
      </>
    );
  }
}

class FeedbackRequestsTableItems extends Component {
  render() {
    return (
      <>
        {this.props.items.map((item, index) => (
          <FeedbackRequestsTableItem
            key={item.id}
            item={item}
            editMode={this.props.editMode}
            editEnabled={this.props.editEnabled}
            permission={this.props.permission}
            removeFeedbackRequest={this.props.removeFeedbackRequest}
            remindFeedbackRequest={this.props.remindFeedbackRequest}
            editFeedbackRequest={this.props.editFeedbackRequest}
            updateFeedbackRequestStatus={this.props.updateFeedbackRequestStatus}
            enableFeedback={this.props.enableFeedback}
            removeFeedback={this.props.removeFeedback}
          />
        ))}
      </>
    );
  }
}

class FeedbackRequestsTableItem extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false,
      isActive: false,
    };
  }
  toggeleShowDetails = (isActive) => {
    this.setState({
      isActive: isActive,
    });
  };

  handleEnableFeedback = (isEnabled) => {
    store.dispatch(this.props.enableFeedback(this.props.item.id, isEnabled));
  };

  handleEdit = (id, item) => {
    this.props.editFeedbackRequest(item);
  };

  handleRemoveFeedback = (isConfrimDelete) => {
    if (isConfrimDelete) {
      store.dispatch(this.props.removeFeedback(this.props.item.id));
    } else {
      this.setState({
        confrimDelete: true,
      });
    }
  };

  handleRemoveFeedbackRequest = (isConfrimDelete) => {
    if (isConfrimDelete) {
      store.dispatch(this.props.removeFeedbackRequest(this.props.item.id));
    } else {
      this.setState({
        confrimDelete: true,
      });
    }
  };

  getFeedbackRequestCounts = () => {
    var totalFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.length) ||
      0;
    var receivedFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) =>
            fr.status === "RECEIVED" ||
            fr.status === "REVIEWED" ||
            fr.status === "CLOSED"
        ).length) ||
      0;
    return {
      receivedFeedbackCount,
      totalFeedbackCount: totalFeedbackCount - receivedFeedbackCount,
    };
  };

  isEditEnabled = () => {
    var totalFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.length) ||
      0;
    var draftFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) => fr.status === "DRAFT"
        ).length) ||
      0;
    return totalFeedbackCount === draftFeedbackCount;
  };

  getStatusValue = () => {
    if (this.props.item.recurring === true) {
      return -1;
    }

    if (this.props.item.active === false) {
      return 0;
    }

    if (this.isFeedbackExpired()) {
      return 7;
    }

    var draftFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) => fr.status === "DRAFT"
        ).length) ||
      0;
    if (draftFeedbackCount > 0) {
      return 1;
    }

    var requestedFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) => fr.status === "REQUESTED"
        ).length) ||
      0;

    var reviwedFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) => fr.status === "REVIEWED"
        ).length) ||
      0;

    if (requestedFeedbackCount > 0) {
      return 2;
    }
    var totalFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.length) ||
      0;
    var closedFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) => fr.status === "CLOSED"
        ).length) ||
      0;
    if (closedFeedbackCount > 0 && closedFeedbackCount === totalFeedbackCount) {
      return 3;
    }
    var receivedFeedbackCount =
      (this.props.item.feedbackRequestInfos &&
        this.props.item.feedbackRequestInfos.filter(
          (fr) => fr.status === "RECEIVED"
        ).length) ||
      0;
    if (
      receivedFeedbackCount > 0 &&
      receivedFeedbackCount === totalFeedbackCount
    ) {
      return 4;
    }
    if (
      reviwedFeedbackCount > 0 &&
      reviwedFeedbackCount === totalFeedbackCount
    ) {
      return 5;
    }
    return 6;
  };

  isFeedbackExpired = () => {
    return (
      dateUtils.compareTwoMoments(
        moment(this.props.item.expiryDate).endOf("day"),
        moment()
      ) === 1
    );
  };

  render() {
    return (
      <div
        className={`taskActivityContainer ${
          this.state.isActive ? "active" : ""
        }`}
      >
        <div className="d-flex justify-content-between align-items-center mt-0 py-0 mt-1 px-2 shadow-sm bg-white rounded">
          <div onClick={() => this.toggeleShowDetails(!this.state.isActive)}>
            <i
              className={classNames(
                "fa-lg",
                { "fa fa-clock-o": this.getStatusValue() === -1 },
                {
                  colorred:
                    this.getStatusValue() === -1 &&
                    this.props.item.active === false,
                },
                {
                  "colorgreen  ":
                    this.getStatusValue() === -1 &&
                    this.props.item.active === true,
                },
                { "fa fa-minus-circle colorred": this.getStatusValue() === 0 },
                {
                  "fa fa-exclamation-circle colorlightblue":
                    this.getStatusValue() === 1 && !this.isFeedbackExpired(),
                },
                {
                  "fa fa-ban colorred blink_text":
                    this.getStatusValue() === 1 && this.isFeedbackExpired(),
                },
                { "fa fa fa-spinner fa-spin": this.getStatusValue() === 2 },
                {
                  colorgreen:
                    this.getStatusValue() === 2 &&
                    this.getFeedbackRequestCounts().receivedFeedbackCount > 0,
                },
                {
                  colororange:
                    this.getStatusValue() === 2 &&
                    this.getFeedbackRequestCounts().receivedFeedbackCount === 0,
                },
                {
                  "fa fa-check-square-o colorgreen":
                    this.getStatusValue() === 3,
                },
                {
                  "fa fa fa-check-circle colororange":
                    this.getStatusValue() === 4,
                },
                {
                  "fa fa fa-check-circle colorgreen":
                    this.getStatusValue() === 5,
                },
                { "fa fa-bars colorred": this.getStatusValue() === 6 },
                { "fa fa-ban colorred blink_text": this.getStatusValue() === 7 }
              )}
            ></i>
            <span className="mx-1 fweight600 fsize13 colorblue">
              {this.props.item.id}
            </span>
            <span
              title={this.props.item.name}
              className={`fsize14 mx-1 d-inline-block text-truncate mt-0 align-middle w-300`}
            >
              {this.props.item.name}
            </span>
          </div>
          <div>
            <span className="float-right">
              <span className="mt-1 ml-4">
                <span className="mr-3">
                  {this.props.item.recurring === false && (
                    <>
                      <span className="badge badge-info bgcolorlightgreen colorblack">
                        {this.getFeedbackRequestCounts().receivedFeedbackCount}
                      </span>
                      /
                    </>
                  )}
                  <span className="badge badge-warning colorblack">
                    {this.getFeedbackRequestCounts().totalFeedbackCount}
                  </span>
                </span>
                <span className="colorblue px-1 py-0 mb-0 fsize12">
                  {dateUtils.convertDateFormat(this.props.item.createdDate)}
                </span>
                <span className="fsize14 mx-1">
                  {this.props.item.cronString || ""}
                </span>
                {this.props.permission.activateEnabled === true && (
                  <span>
                    <label className=" custom-switch custom-switch-sm m-0">
                      <input
                        type="checkbox"
                        onChange={() => {}}
                        onClick={(e) =>
                          this.handleEnableFeedback(!this.props.item.active)
                        }
                        checked={this.props.item.active ? true : false}
                        className="custom-switch-input"
                      />
                      <span className="custom-switch-indicator" />
                    </label>
                  </span>
                )}
                {this.props.editMode === false &&
                  this.props.editEnabled === true &&
                  this.props.permission.editEnabled === true && (
                    <button
                      type="button"
                      className="link pl-2"
                      disabled={!this.isEditEnabled()}
                      onClick={(e) =>
                        this.handleEdit(this.props.item.id, this.props.item)
                      }
                      title="Edit"
                    >
                      <i className="fa fa-edit" />
                    </button>
                  )}
                {this.props.permission.removeEnabled === true && (
                  <button
                    type="button"
                    onClick={(e) =>
                      this.handleRemoveFeedback(this.state.confrimDelete)
                    }
                    className="link pl-2"
                    title="Remove"
                  >
                    {this.state.confrimDelete === true && (
                      <i className="fe fe-check colorred blink_text"></i>
                    )}
                    {this.state.confrimDelete === false && (
                      <i className="fe fe-x colorred"></i>
                    )}
                  </button>
                )}
              </span>
            </span>
          </div>
        </div>
        {this.state.isActive && (
          <div className="card">
            <div className="card-status card-status-left bg-blue"></div>
            <div className="card-header py-0 pr-2">
              <span className="fsize12">{this.props.item.description}</span>
            </div>
            <div className="card-body py-0 ml-2 pl-3">
              <div className="overlaySection">
                <div className="row pt-1 text-left">
                  <div className="col-4 row">
                    <div className="form-group col-12 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Description
                      </label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item &&
                          this.props.item.requestedEmployeeInfo &&
                          this.props.item.description) ||
                          "NA"}
                      </span>
                    </div>
                    <div className="form-group col-6 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Requested By
                      </label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item &&
                          this.props.item.requestedEmployeeInfo &&
                          this.props.item.requestedEmployeeInfo.name) ||
                          "NA"}
                      </span>
                    </div>
                    <div className="form-group col-6 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Created Date
                      </label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item &&
                          dateUtils.convertDateTimeFormat(
                            this.props.item.createdDate
                          )) ||
                          "NA"}
                      </span>
                    </div>

                    <div className="form-group col-6 mb-1">
                      <label className="form-label mb-0 text-muted">Type</label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item && this.props.item.type) || "NA"}
                      </span>
                    </div>

                    <div className="form-group col-6 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Sub Type
                      </label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item && this.props.item.subType) || "NA"}
                      </span>
                    </div>

                    <div className="form-group col-6 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Expiry Date
                      </label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item &&
                          dateUtils.convertDateTimeFormat(
                            this.props.item.expiryDate
                          )) ||
                          "NA"}
                      </span>
                    </div>
                    <div className="form-group col-6 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Template
                      </label>
                      <a
                        className="fsize12 fweight600 menu-text-black colorblue"
                        target="_new"
                        href={`/document/feedback/${this.props.item.id}`}
                      >
                        {(this.props.item &&
                        this.props.item.feedbackTemplateInfo
                          ? this.props.item.feedbackTemplateInfo.name
                          : this.props.item.templateId) || "NA"}
                      </a>
                    </div>

                    <div className="form-group col-12 mb-1">
                      <label className="form-label mb-0 text-muted">
                        Email Subject
                      </label>
                      <span className="fsize12 fweight600 menu-text-black">
                        {(this.props.item && this.props.item.emailSubject) ||
                          "Default"}
                      </span>
                    </div>

                    {this.props.item && this.props.item.recurring && (
                      <>
                        <div className="form-group col-6 mb-1">
                          <label className="form-label mb-0 text-muted">
                            Create as Draft
                          </label>
                          <span className="fsize12 fweight600 menu-text-black">
                            {(this.props.item &&
                            this.props.item.scheduleData &&
                            this.props.item.scheduleData.draft === false
                              ? "No"
                              : "Yes") || "NA"}
                          </span>
                        </div>
                        <div className="form-group col-6 mb-1">
                          <label className="form-label mb-0 text-muted">
                            Schedule
                          </label>
                          <span className="fsize12 fweight600 menu-text-black">
                            {(this.props.item &&
                              this.props.item.scheduleData &&
                              this.props.item.scheduleData.cronString) ||
                              "NA"}
                          </span>
                        </div>
                        <div className="form-group col-6 mb-1">
                          <label className="form-label mb-0 text-muted">
                            Schedule End Date
                          </label>
                          <span className="fsize12 fweight600 menu-text-black">
                            {(this.props.item &&
                              this.props.item.scheduleData &&
                              this.props.item.scheduleData.endDate &&
                              dateUtils.convertDateTimeFormat(
                                this.props.item.scheduleData.endDate
                              )) ||
                              "NA"}
                          </span>
                        </div>
                        <div className="form-group col-6 mb-1">
                          <label className="form-label mb-0 text-muted">
                            Schedule Next Run
                          </label>
                          <span className="fsize12 fweight600 menu-text-black">
                            NA
                          </span>
                        </div>
                        <div className="form-group col-6 mb-1">
                          <label className="form-label mb-0 text-muted">
                            Schedule Last Run
                          </label>
                          <span className="fsize12 fweight600 menu-text-black">
                            NA
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-8 border bg-light">
                    <div className="fsize14 pt-2 fweight600 border-bottom">
                      Feedback Requests
                    </div>
                    {this.props.item.feedbackRequestInfos &&
                      this.props.item.feedbackRequestInfos.map(
                        (feedbackRequest) => (
                          <FeedbackRequestItem
                            key={feedbackRequest.id}
                            permission={this.props.permission}
                            feedbackId={this.props.item.id}
                            isRecurring={this.props.item.recurring}
                            feedbackItem={this.props.item}
                            isFeedbackExpired={
                              this.isFeedbackExpired() &&
                              this.props.item.recurring === false
                            }
                            item={feedbackRequest}
                            removeFeedbackRequest={
                              this.props.removeFeedbackRequest
                            }
                            remindFeedbackRequest={
                              this.props.remindFeedbackRequest
                            }
                            updateFeedbackRequestStatus={
                              this.props.updateFeedbackRequestStatus
                            }
                          />
                        )
                      )}

                    {(!this.props.item.feedbackRequestInfos ||
                      this.props.item.feedbackRequestInfos.length === 0) && (
                      <div className="w-auto h-auto text-center align-middle pt-3">
                        No Feedback Requests Added.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class FeedbackRequestItem extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false,
      confrimRetrigger: false,
    };
  }

  handleRemoveFeedbackRequest = (isConfrimDelete) => {
    if (isConfrimDelete) {
      store.dispatch(
        this.props.removeFeedbackRequest(
          this.props.feedbackId,
          this.props.item.id
        )
      );
    } else {
      this.setState({
        confrimDelete: true,
      });
    }
  };
  handleRetriggerFeedbackRequest = (confrimRetrigger) => {
    if (confrimRetrigger) {
      store.dispatch(this.props.remindFeedbackRequest(this.props.item.id));
      this.setState({
        confrimRetrigger: false,
      });
    } else {
      this.setState({
        confrimRetrigger: true,
      });
    }
  };

  handleUpdateStatus = (itemId, status) => {
    if (status !== this.props.item.status) {
      store.dispatch(
        this.props.updateFeedbackRequestStatus(
          itemId,
          status,
          commonUtils.getCurrentUserId(),
          this.props.feedbackItem.expiryDate
        )
      );
    }
  };

  getChangebleStatus = (currentStatus) => {
    var changebleStatus = [];
    if (currentStatus === "DRAFT") {
      changebleStatus.push("REQUESTED");
    } else if (currentStatus === "RECEIVED") {
      changebleStatus.push("REVIEWED");
    } else if (currentStatus === "REVIEWED") {
      changebleStatus.push("CLOSED");
    }
    return changebleStatus;
  };
  render() {
    return (
      <div className="d-flex justify-content-between align-items-center mt-0 py-0 mb-1 px-2 shadow-sm bg-white rounded">
        <div>
          <i
            className={classNames(
              {
                "fa fa-ban colorred blink_text":
                  this.props.isFeedbackExpired === true,
              },
              {
                "fa fa-exclamation-circle colorlightblue":
                  this.props.item.status === "DRAFT" &&
                  this.props.isFeedbackExpired === false,
              },
              {
                "fa fa fa-spinner fa-spin ":
                  this.props.item.status === "REQUESTED" &&
                  this.props.isFeedbackExpired === false,
              },
              {
                colorgreen:
                  this.props.item.status === "REQUESTED" &&
                  this.props.isFeedbackExpired === false,
              },
              {
                colorred:
                  this.props.item.status === "REQUESTED" &&
                  this.props.isFeedbackExpired === true,
              },
              {
                "fa fa fa-check-circle colororange":
                  this.props.item.status === "RECEIVED",
              },
              {
                "fa fa-check-square-o colorgreen":
                  this.props.item.status === "CLOSED",
              },
              {
                "fa fa fa-check-circle colorgreen":
                  this.props.item.status === "REVIEWED",
              }
            )}
          ></i>
          <span className="mx-1 fweight600 fsize13 colorblue d-none">
            {this.props.item.id}
          </span>
          <span
            title={this.props.item.personName}
            className={`fsize14 mx-1 text-truncate mt-0 align-middle w-200`}
          >
            {this.props.item.personName}
          </span>
          <span
            title={this.props.item.personEmail}
            className={`fsize14 mx-1 text-truncate mt-0 align-middle w-200`}
          >
            {this.props.item.personEmail}
          </span>
        </div>
        <div>
          <span className="float-right">
            <span className="mt-1 ml-4">
              <span className="colorblue px-1 py-0 mb-0 fsize12">
                {dateUtils.convertDateFormat(this.props.item.lastModifiedDate)}
              </span>
              <span className="fsize14 mx-1">
                <SelectInput
                  options={[
                    { value: "DRAFT", label: "Draft", level: 0 },
                    { value: "REQUESTED", label: "Requested", level: 1 },
                    { value: "RECEIVED", label: "Received", level: 2 },
                    { value: "REVIEWED", label: "Reviewed", level: 3 },
                    { value: "CLOSED", label: "Closed", level: 4 },
                  ]}
                  value={this.props.item.status}
                  allowLevelDown={false}
                  changeableStatus={this.getChangebleStatus(
                    this.props.item.status
                  )}
                  isReadOnly={
                    this.props.isFeedbackExpired === true ||
                    this.props.isRecurring === true ||
                    this.props.permission.updateStatusEnabled === false
                  }
                  itemId={this.props.item.id}
                  onChange={this.handleUpdateStatus}
                  className={classNames(
                    "badge",
                    { "badge-info": this.props.item.status === "DRAFT" },
                    { "badge-primary": this.props.item.status === "REQUESTED" },
                    { "badge-warning": this.props.item.status === "RECEIVED" },
                    { "badge-success": this.props.item.status === "REVIEWED" },
                    { "badge-dark": this.props.item.status === "CLOSED" }
                  )}
                />
              </span>

              {(this.props.item.status === "RECEIVED" ||
                this.props.item.status === "REVIEWED" ||
                this.props.item.status === "CLOSED") && (
                <a
                  className=""
                  target="_new"
                  href={`/document/feedback/request/${this.props.item.id}`}
                >
                  <i className="fa fa-eye pr-2" />
                </a>
              )}

              {this.props.item.status === "REQUESTED" &&
                this.props.isFeedbackExpired === false &&
                this.props.permission.resendEnabled === true && (
                  <button
                    type="button"
                    onClick={(e) =>
                      this.handleRetriggerFeedbackRequest(
                        this.state.confrimRetrigger
                      )
                    }
                    className="link pl-2"
                    title="Send a reminder"
                  >
                    {this.state.confrimRetrigger === true && (
                      <i className="fa fa-send colorred blink_text"></i>
                    )}
                    {this.state.confrimRetrigger === false && (
                      <i className="fa fa-send-o colorgreen"></i>
                    )}
                  </button>
                )}
              {this.props.permission.removeEnabled === true && (
                <button
                  type="button"
                  onClick={(e) =>
                    this.handleRemoveFeedbackRequest(this.state.confrimDelete)
                  }
                  className="link pl-2"
                  title="Remove"
                >
                  {this.state.confrimDelete === true && (
                    <i className="fe fe-check colorred blink_text"></i>
                  )}
                  {this.state.confrimDelete === false && (
                    <i className="fe fe-x colorred"></i>
                  )}
                </button>
              )}
            </span>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showFeedbackTemplateConfigureModel:
    state.feedbackTemplate.isShowConfigureFeedbackTemplateSidebar,
  isLoading: state.feedbackRequest.loading,
  userTeams: commonUtils.getUserTeams(state.feedbackRequest.userTeams),
  allUserTeams: state.appconfig.allTeams,
  items: commonUtils.filterFeedbacks(
    state.feedbackRequest.feedbackItems,
    state.feedbackRequest.filterOptions
  ),
  feedbackTemplateDataList: state.feedbackTemplate.templateItems,
  filterOptions: state.feedbackRequest.filterOptions,
  emailInfoFieldConfig: state.feedbackRequest.emailInfoFieldConfig,
  feedbackFieldConfig: state.feedbackRequest.feedbackFieldConfig,
  feedbackTypeOptions: state.feedbackRequest.feedbackTypeOptions,
  feedbackSubTypeOptions: state.feedbackRequest.feedbackSubTypeOptions,
});
const mapDispatchToProps = (dispatch) => ({
  createFeedbackRequest: feedbackRequestActions.createFeedbackRequest,
  updateFeedback: feedbackRequestActions.updateFeedback,
  updateFeedbackRequestStatus:
    feedbackRequestActions.updateFeedbackRequestStatus,
  updateFeedbackTemplate: feedbackRequestActions.updateFeedbackTemplate,

  getAllFeedbackTemplates: feedbackTemplateActions.getAllFeedbackTemplates,
  getAllFeedbackRequests: feedbackRequestActions.getAllFeedbackRequests,

  removeFeedbackRequest: feedbackRequestActions.removeFeedbackRequest,
  remindFeedbackRequest: feedbackRequestActions.remindFeedbackRequest,

  enableFeedback: feedbackRequestActions.activateFeedback,
  removeFeedback: feedbackRequestActions.removeFeedback,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackPageSection);
