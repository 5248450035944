import LoginPage from "views/user/LoginPage";
import ChangePassword from "views/user/ChangePassword";

import ProjectDashboardPage from "views/project/ProjectDashboardPage";
import ProjectListPage from "views/project/ProjectListPage";
import WorkItemAcitivitiesCalendarPage from "views/project/WorkItemAcitivitiesCalendarPage";
import ReportsPage from "views/project/ReportsPage";

import EmployeeDashboardPage from "views/hrms/EmployeeDashboardPage";
import EmployeesPage from "views/hrms/EmployeesPage";
import TimesheetPage from "views/hrms/TimesheetPage";
import TaskBoardPage from "views/project/TaskBoardPage";
import ProjectDetailsPage from "views/project/ProjectDetailsPage";
import FileManagerPage from "views/project/FileManagerPage";
import ChatPage from "views/project/ChatPage";
import UserCalendarPage from "views/project/UserCalendarPage";
import AttendancePage from "views/hrms/AttendancePage";
import SearchPage from "views/project/SearchPage";
import ProfileSettingsPage from "views/hrms/ProfileSettingsPage";
import ProfilePage from "views/hrms/ProfilePage";
import ApplicationConfigPage from "views/hrms/ApplicationConfigPage";
import ForgotPassword from "views/user/ForgotPassword";
import Error500 from "views/user/Error500";
import ToDoListPage from "views/project/ToDoListPage";
import AccountsListPage from "views/project/AccountsListPage";
import InvoiceDetailsPage from "views/project/InvoiceDetailsPage";
import InvoiceListPage from "views/project/InvoiceListPage";
import PurchaseOrderListPage from "views/project/PurchaseOrderListPage";
import TaskPlannerPage from "views/project/TaskPlannerPage";
import TaskDetailsPage from "views/project/TaskDetailsPage";
import TaskListPage from "views/project/TaskListPage";
import WorkItemsListPage from "views/project/WorkItemsListPage";
import PurchaseOrderDetailsPage from "views/project/PurchaseOrderDetailsPage";
import BidRequestsListPage from "views/sales/BidRequestsListPage";
import BidRequestsDetailsPage from "views/sales/BidRequestsDetailsPage";
import ResourcePlannerPage from "views/project/ResourcePlannerPage";
import FeedbackRequestPage from "views/project/FeedbackRequestPage";
import FeedbackPageSection from "views/project/components/FeedbackPageSection";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: ProjectDashboardPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/bidrequests",
    name: "Bid Requests",
    component: BidRequestsListPage,
    layoutName: "main",
    layout: "/sales",
    showInMenu: true,
  },
  {
    path: "/purchaseorders",
    name: "Purchase Orders",
    component: PurchaseOrderListPage,
    layoutName: "main",
    layout: "/sales",
    showInMenu: true,
  },

  {
    path: "/list",
    name: "Project List",
    component: ProjectListPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/taskplanner",
    name: "Task Planner",
    component: TaskPlannerPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/rplanner",
    name: "Resource Planner",
    component: ResourcePlannerPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: AccountsListPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/todolist",
    name: "To Do List",
    component: ToDoListPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/taskboard",
    name: "Taskboard",
    component: TaskBoardPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/activites",
    name: "Task Activity",
    component: WorkItemAcitivitiesCalendarPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: FeedbackPageSection,
    layoutName: "main",
    layout: "/project",
    showInMenu: true,
  },
  {
    path: "/details/:projectId",
    name: "Project Details",
    component: ProjectDetailsPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
  },
  {
    path: "/bidrequest/:bidRequestId",
    name: "Bid Details",
    component: BidRequestsDetailsPage,
    layoutName: "main",
    layout: "/sales",
    showInMenu: false,
  },
  {
    path: "/deliverables/:projectId",
    name: "Deliverables",
    component: TaskListPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
  },
  {
    path: "/purchaseorder/:purchaseOrderId",
    name: "Purchase Order Details",
    component: PurchaseOrderDetailsPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
  },
  {
    path: "/workitems/:taskId",
    name: "Work Items",
    component: WorkItemsListPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
  },
  {
    path: "/deliverable/:taskId",
    name: "Deliverable Details",
    component: TaskDetailsPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
  },
  {
    path: "/filemanager",
    name: "File Manager",
    component: FileManagerPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
    accessControlled: true,
  },
  {
    path: "/chat",
    name: "Chat",
    component: ChatPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
    accessControlled: true,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: UserCalendarPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
    accessControlled: true,
  },
  {
    path: "/search",
    name: "Search",
    component: SearchPage,
    layoutName: "main",
    layout: "/project",
    showInMenu: false,
    accessControlled: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: EmployeeDashboardPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: true,
  },
  {
    path: "/employees",
    name: "Employees",
    component: EmployeesPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: true,
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: AttendancePage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: true,
  },
  {
    path: "/timesheet",
    name: "Time Sheet",
    component: TimesheetPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: true,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: InvoiceListPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: true,
  },
  {
    path: "/invoice/details/:invoiceId",
    name: "Invoice Details",
    component: InvoiceDetailsPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: false,
  },
  {
    path: "/settings",
    name: "Settings",
    component: ProfileSettingsPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: false,
    accessControlled: true,
  },
  {
    path: "/appconfig",
    name: "Application Config",
    component: ApplicationConfigPage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: false,
    accessControlled: true,
  },
  {
    path: "/profile/:employeeId",
    name: "profile",
    component: ProfilePage,
    layoutName: "main",
    layout: "/hrms",
    showInMenu: false,
  },

  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    layoutName: "user",
    layout: "/user",
  },
  {
    path: "/invoice/details/:invoiceId",
    name: "Invoice Details",
    component: InvoiceDetailsPage,
    layoutName: "docs",
    layout: "/document",
  },
  {
    path: "/feedback/request/:feedbackRequestId",
    name: "Feedback Request",
    component: FeedbackRequestPage,
    layoutName: "docs",
    layout: "/document",
  },
  {
    path: "/feedback/template/:templateId",
    name: "Feedback Template",
    component: FeedbackRequestPage,
    layoutName: "docs",
    layout: "/document",
  },
  {
    path: "/feedback/:feedbackId",
    name: "Feedback",
    component: FeedbackRequestPage,
    layoutName: "docs",
    layout: "/document",
  },
  {
    path: "/activateaccount/:activationKey/:employeeId/:uniqueId",
    name: "Account Activation",
    component: ChangePassword,
    layoutName: "user",
    layout: "/user",
  },
  {
    path: "/changepassword/:activationKey/:employeeId/:uniqueId",
    name: "Change Password",
    component: ChangePassword,
    layoutName: "user",
    layout: "/user",
  },
  {
    path: "/forgotpassword",
    name: "Forgot Password",
    component: ForgotPassword,
    layoutName: "user",
    layout: "/user",
  },
  {
    path: "/error500",
    name: "Error Page",
    component: Error500,
    layoutName: "error",
    layout: "/error",
  },
];

export default dashboardRoutes;
