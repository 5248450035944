import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { accessUtils } from "_helpers";
import 'views/common/plugins/invoice/style.css';
import { store } from "_helpers";
import { invoiceActions } from "_actions/invoice.actions";
import dateUtils from "_helpers/date.utils";
import { commonUtils } from "_helpers";

class InvoiceDetailsPage extends PureComponent {
    constructor() {
        super();
        this.state = {
            showEmployees: false,
            permission: accessUtils.getInvoiceAccessControl(),
        }
    }

    componentDidMount() {
        let invoiceId = this.props.match.params.invoiceId
        this.setState({
            invoiceId: invoiceId
        })
        this.invokeGetInvoiceService(invoiceId);
    }

    toggleShowEmployees = (show) => {
        this.setState({
            showEmployees: show
        })
    }
    invokeGetInvoiceService = (invoiceId) => {
        let hasAccess = this.hasAccess();
        if (this.state.permission.viewEnabled || hasAccess) {
            store.dispatch(this.props.getInvoiceInfo(invoiceId, hasAccess));
        }
    }

    getInvoiceNumber = () => {
        return "C5" + (this.props.item && dateUtils.convertDateStringToMoment(this.props.item.invoiceDateTime).format("YYYYMMDD")) || "123456";
    }

    hasAccess = () => {
        let ac = new URLSearchParams(this.props.location.search).get("ac");
        let urlInvoiceNumber = new URLSearchParams(this.props.location.search).get("uin");
        let invoiceId = this.props.match.params.invoiceId
        return ac && urlInvoiceNumber && (commonUtils.decryptString(ac, urlInvoiceNumber + "_" + invoiceId) === urlInvoiceNumber)
    }

    render() {
        return (
            <div className="wrapper invoicePrint">
                {this.props.item &&
                    <>
                        <div className="">
                            <div className="header row mx-auto bg-white py-3">
                                <div className="col-6">
                                    <div className="d-flex">
                                        <span>
                                            <img className="icon-invoice" src="/assets/images/invoice/infleca.png" />
                                        </span>
                                        <span className="ml-2">
                                            <span className="fsize17 fweight600 d-block"> Infleca Innovation Pvt Ltd</span>
                                            <span className="fsize13 fweight600 d-block"> Perukilakattunirappel, Mamala PO,</span>
                                            <span className="fsize13 fweight600 d-block"> Muriyamangalam, Thiruvankulam,</span>
                                            <span className="fsize13 fweight600 d-block"> Ernakulam, Kerala – 682309</span>
                                        </span>
                                    </div>



                                </div>

                                <div className="col-2">
                                    <div className="header-contact">
                                        <span className="fa fa-address-card-o colorgreen fa-lg" />
                                        <p>info@infleca.com<br />
                                            support@infleca.com<br />
                                            finance@infleca.com</p>
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="header-contact">
                                        <span className="fa fa-phone colorgreen fa-lg" />
                                        <p> +91 9496721720<br />
                                            +1 669-249-5541<br />
                                            Monday to Friday<br />
                                            9am to 5pm lines are open</p>
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="header-contact">
                                        <img className="icon-web" src="/assets/images/invoice/world.png" />
                                        <p>www.infleca.com</p>
                                    </div>
                                </div>

                            </div>


                            <div className="header-bottom row mx-auto">

                                <div className="col-6 header-bottom-left">

                                    <span className="pb-2"><img className="icon-invoice" src="/assets/images/invoice/invoice.png" />INVOICE TO</span>

                                    <span className="ml-2">
                                        <span className="fsize17 fweight600 d-block mt-2"> COURSE5 INTELLIGENCE Pvt Ltd</span>
                                        <span className="fsize13 fweight600 d-block">Registration Number 29AABCC6525A3ZZ</span>
                                        <span className="fsize13 fweight600 d-block">103,C4, Brigade Tech Gardens,</span>
                                        <span className="fsize13 fweight600 d-block">ITPL MAIN ROAD, Kundalhalli village,</span>
                                        <span className="fsize13 fweight600 d-block">Bengaluru 560037 Karnataka</span>
                                    </span>
                                    <p className="mb-1"> <span className="fa fa-envelope colorgreen mr-1" />Sebastian.Fernando@course5i.com</p>
                                    <p> <span className="fa fa-phone colorgreen mr-1" /> +91 8049313800</p>

                                </div>

                                <div className="col-6 invoice-header print-exact">

                                    <span className="fsize50 text-muted">INVOICE</span>

                                    <table className="mt-3">
                                        <thead>
                                            <tr>
                                                <td><div className="circle colorgreen"><span className="fa fa-inr fa-lg pr-1 fsize35 pl-2" /></div></td>
                                                <td><div className="circle colorgreen"> <span className="fa fa-calendar fa-lg pr-1 fsize35" /></div></td>
                                                <td><div className="circle colorgreen" > <span className="fa fa-barcode fa-lg pr-1 fsize35" /></div></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Total Due:<br />
                                                    <span className="fsize20 fweight600"><span className="fa fa-inr" />{(this.props.item && this.props.item.subTotal.toFixed(2)) || 0.00}</span>
                                                </td>
                                                <td>
                                                    Invoice Date:<br />
                                                    <strong>{(this.props.item && dateUtils.convertDateStringToMoment(this.props.item.revisedInvoiceDateTime || this.props.item.invoiceDateTime).format("DD-MMM-YYYY")) || "01 01 0000"}</strong>
                                                </td>
                                                <td>
                                                    Invoice #:<br />
                                                    <strong>{this.props.item.invoiceNumber || this.getInvoiceNumber()}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>


                            <div className="row mx-auto">
                                <div className="col-12">
                                    <table className="products-table print-exact">
                                        <thead>
                                            <tr>
                                                <th>Item Description</th>
                                                <th>Unit Price</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.props.item &&
                                                <tr>
                                                    <td>
                                                        <span className="fsize18 fweight600 d-block">Monthly User fee ({dateUtils.convertDateStringToMoment(this.props.item.invoiceMonthYear).format(dateUtils.DATE_MONTH_FORMAT)})</span>
                                                        <span className="fsize15"> Recurring monthly licence fee for {this.props.item.numberOfUsers} users.</span>
                                                    </td>
                                                    <td>&#x20B9;400</td>
                                                    <td>{(this.props.item.usersMap && Object.keys(this.props.item.usersMap).length) || 0}</td>
                                                    <td>&#x20B9;{this.props.item.totalAmount.toFixed(2)}</td>
                                                </tr>
                                            }
                                            {this.props.item && this.props.item.additionalItems && this.props.item.additionalItems.items && this.props.item.additionalItems.items.map(additionalItem =>
                                                <tr>
                                                    <td>
                                                        <span className="fsize18 fweight600 d-block">{additionalItem.name}</span>
                                                        <span className="fsize15"> {additionalItem.description}</span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>&#x20B9;{additionalItem.totalAmount.toFixed(2)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td colSpan={4}>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>


                            <div className="row mx-auto">
                                <div className="col-5  mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th><strong>Payment Method:</strong> Direct Deposit (NEFT).</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>PAN: AAFC12408E<br />
                                                        GST: - 32AAFCI2408E1Z6<br />
                                                        LUT ARN: - AD320821002704L
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="mt-2">
                                                        <strong>Infleca Innovation Private Limited</strong><br />
                                                        Account Number: 10163471527<br />
                                                        IFSC: IDFB0080672<br />
                                                        SWIFT code: IDFBINBBMUM,<br />
                                                        IDFC FIRST,<br />
                                                        Tripunithura Branch, Ernakulum,<br />
                                                        Kerala 682301,<br />
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-2">
                                </div>
                                <div className="col-5 totals">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>SUB TOTAL:</td>
                                                <td><span className="fa fa-inr" />{(this.props.item && this.props.item.subTotal.toFixed(2)) || 0.00}</td>
                                            </tr>
                                            <tr>
                                                <td>Tax:</td>
                                                <td><span className="fa fa-inr" />00.00</td>
                                            </tr>
                                            <tr className="discount">
                                                <td><span>DISCOUNT :</span></td>
                                                <td><span>-<span className="fa fa-inr" />00.00</span></td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="print-exact">
                                            <tr>
                                                <td>Total Due:</td>
                                                <td><span className="fa fa-inr" />{(this.props.item && this.props.item.subTotal.toFixed(2)) || 0.00}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div className="signature">
                                        <img className="icon-signature" src="/assets/images/invoice/sign.png" />
                                        <p>Neethu Mohanan</p>
                                        <p><strong>Accounts Manager</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mx-auto terms">
                                <div className="col-12">
                                    <p><strong>Terms:</strong>Payment should be made within 30 days by bank transfer. Per user <span className="fa fa-inr" />5, <span className="fa fa-inr" />1 = ₹80</p>
                                </div>
                            </div>

                            <div className="row mx-auto footer-invoice print-exact">
                                <div className="col-6">
                                    <img className="icon-invoice" src="/assets/images/invoice/infleca.png" />
                                </div>
                                <div className="col-2">
                                    <p>info@infleca.com<br />
                                        support@infleca.com<br />
                                        finance@infleca.com</p>
                                </div>

                                <div className="col-2">
                                    <p>+91 9686301304<br />
                                        Monday to Friday
                                        9am to 6pm lines are open</p>
                                </div>

                                <div className="col-2">
                                    <p>www.infleca.com</p>
                                </div>
                            </div>


                        </div>

                        <div className="fsize16  no-print bgcolorgray mb-1 p-3">
                            <span className="mb-1">
                                Employee Details <span className="fsize9" onClick={() => this.toggleShowEmployees(!this.state.showEmployees)}>{this.state.showEmployees ? 'Hide' : 'Show'}</span>
                            </span>
                        </div>

                        {this.props.item && this.props.item.usersMap && this.state.showEmployees &&

                            <div className="pl-3 no-print">
                                <table className="table table-hover table-striped table-vcenter">

                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Employee Id</th>
                                            <th>Email Id</th>

                                        </tr>
                                    </thead>
                                    {Object.keys(this.props.item.usersMap).map((key, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{key}</td>
                                            <td>{this.props.item.usersMap[key]}</td>
                                        </tr>
                                    )}

                                </table>
                            </div>
                        }
                    </>
                }
                {!this.state.permission.viewEnabled && !this.hasAccess() &&
                    <span>NO PERMISSION</span>
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    isLoading: true,
    item: state.invoice.selectedInvoiceInfo
});
const mapDispatchToProps = dispatch => ({
    getInvoiceInfo: invoiceActions.getInvoiceInfo,
    removeInvoice: invoiceActions.removeInvoice,
    updateInvoiceStatus: invoiceActions.updateInvoiceStatus
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailsPage);