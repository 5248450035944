import { feedbackRequestConstants } from "../_constants";

const feedbackTypeOptions = [
  { value: "PROJECT", label: "Project" },
  { value: "PURCHASE_ORDER", label: "Purchase Order" },
  { value: "EMPLOYEE", label: "Employee" },
  { value: "BID", label: "Bid" },
];

const feedbackSubTypeOptions = [
  { value: "GENERAL", label: "General" },
  { value: "DELIVERY_TEAM", label: "Delivery Team" },
  { value: "CLIENT", label: "Client" },
  { value: "CLIENT_PARTNER", label: "Client Partner" },
];

const feedbackFieldConfig = [
  { name: "name", mandatory: true, enabled: true, editable: true },
  { name: "templateId", mandatory: true, enabled: true, editable: true },
  { name: "itemId", mandatory: true, enabled: true, editable: true },
  { name: "type", mandatory: true, enabled: true, editable: true },
  { name: "subType", mandatory: true, enabled: true, editable: true },
  { name: "userTeamId", mandatory: true, enabled: true },
];

const emailInfoFieldConfig = [
  { name: "personEmail", mandatory: true, enabled: true, editable: true },
  { name: "personName", mandatory: true, enabled: true, editable: true },
];
export function feedbackRequest(
  state = {
    feedbackFieldConfig,
    feedbackTypeOptions,
    feedbackSubTypeOptions,
    emailInfoFieldConfig,
  },
  action
) {
  switch (action.type) {
    case feedbackRequestConstants.CHANGE_FILTER_FEEDBACKS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };

    case feedbackRequestConstants.GETALL_FEEDBACK_REQUEST_REQUEST:
      return {
        ...state,
        feedbackTemplatesLoading: true,
      };
    case feedbackRequestConstants.GETALL_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        feedbackTemplatesLoading: false,
        feedbackItems: action.feedbackRequests,
      };
    case feedbackRequestConstants.GETALL_FEEDBACK_REQUEST_FAILURE:
      return {
        ...state,
        feedbackTemplatesLoading: false,
        error: action.error,
      };

    case feedbackRequestConstants.CREATE_FEEDBACK_REQUEST_REQUEST:
      return {
        ...state,
        creating: true,
        error: null,
      };
    case feedbackRequestConstants.CREATE_FEEDBACK_REQUEST_SUCCESS:
      state.feedbackItems
        ? state.feedbackItems.push(action.feedbackRequest)
        : (state.feedbackItems = [action.feedbackRequest]);
      return {
        ...state,
        creating: false,
      };
    case feedbackRequestConstants.CREATE_FEEDBACK_REQUEST_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error,
      };

    case feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_REQUEST:
      return {
        ...state,
        updating: true,
        feedbackItems: state.feedbackItems.map((feedbackRequest) =>
          feedbackRequest.id === action.feedbackId
            ? { ...feedbackRequest, updating: true }
            : feedbackRequest
        ),
        error: null,
      };
    case feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_SUCCESS:
      let oldItem = state.feedbackItems.find(
        (feedbackRequest) => feedbackRequest.id === action.feedbackId
      );
      let newItems = state.feedbackItems.filter(
        (feedbackRequest) => feedbackRequest.id !== action.feedbackId
      );
      let newItem = {
        ...oldItem,
        ...action.feedbackRequest,
      };
      newItems.push(newItem);
      return {
        ...state,
        updating: false,
        feedbackItems: newItems,
      };
    case feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_FAILURE:
      return {
        ...state,
        updating: false,
        feedbackItems: state.feedbackItems.map((feedbackRequest) => {
          if (feedbackRequest.id === action.feedbackId) {
            const { updating, ...feedbackTemplateCopy } = feedbackRequest;
            return { ...feedbackTemplateCopy, deleteError: action.error };
          }
          return feedbackRequest;
        }),
        error: action.error,
      };

    case feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        feedbackItems: state.feedbackItems.map((feedBackItem) => {
          if (feedbackRequest.id === action.feedbackId) {
            const { feedbackRequestInfos, ...feedBackItemCopy } = feedBackItem;
            feedbackRequestInfos.map((feedbackRequestInfo) => {
              if (
                feedbackRequestInfo.id === action.feedbackRequestResponse.id
              ) {
                feedbackRequestInfo.status =
                  action.feedbackRequestResponse.status;
              }
            });
            return { feedbackRequestInfos, ...feedBackItemCopy };
          }
          return feedbackRequest;
        }),
      };

    case feedbackRequestConstants.REMOVE_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        feedbackItems: state.feedbackItems.map((feedbackRequest) => {
          if (feedbackRequest.id === action.feedbackId) {
            const { feedbackRequestInfos, ...feedbackTemplateCopy } =
              feedbackRequest;
            var newFeedbackRequestInfos = feedbackRequestInfos.filter(
              (feedbackRequest) =>
                feedbackRequest.id !== action.feedbackRequestId
            );
            return {
              feedbackRequestInfos: newFeedbackRequestInfos,
              ...feedbackTemplateCopy,
            };
          }
          return feedbackRequest;
        }),
      };

    case feedbackRequestConstants.REMOVE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackItems: state.feedbackItems.filter(
          (feedback) => feedback.id !== action.feedbackId
        ),
      };

    case feedbackRequestConstants.ACTIVATE_FEEDBACK_REQUEST:
      return {
        ...state,
        feedbackItems: state.feedbackItems.map((feedbackRequest) =>
          feedbackRequest.id === action.feedbackId
            ? { ...feedbackRequest, activating: true }
            : feedbackRequest
        ),
      };
    case feedbackRequestConstants.ACTIVATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackItems: state.feedbackItems.map((feedbackRequest) =>
          feedbackRequest.id === action.feedbackId
            ? { ...feedbackRequest, active: action.isActive }
            : feedbackRequest
        ),
      };
    case feedbackRequestConstants.ACTIVATE_FEEDBACK_FAILURE:
      return {
        ...state,
        feedbackItems: state.feedbackItems.map((feedbackRequest) => {
          if (feedbackRequest.id === action.feedbackId) {
            const { activating, ...feedbackTemplateCopy } = feedbackRequest;
            return { ...feedbackTemplateCopy, deleteError: action.error };
          }
          return feedbackRequest;
        }),
      };
    default:
      return state;
  }
}
