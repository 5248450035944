import { feedbackTemplateService } from '_services/feedback.service';
import { feedbackTemplateConstants } from '../_constants';
import { commonActions } from './common.actions';
import { notificationActions } from './notification.actions';

export const feedbackTemplateActions = {
    getAllFeedbackTemplates,
    getFeedbackTemplate,
    createFeedbackTemplate,
    updateFeedbackTemplate,
    removeFeedbackTemplate,
    activateFeedbackTemplate
};

function getAllFeedbackTemplates(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        feedbackTemplateService.getAllFeedbackTemplates(requestObj)
            .then(
                feedbackTemplates => {
                    dispatch(success(feedbackTemplates.payload));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: feedbackTemplateConstants.GETALL_FEEDBACK_TEMPLATES_REQUEST } }
    function success(feedbackTemplates) { return { type: feedbackTemplateConstants.GETALL_FEEDBACK_TEMPLATES_SUCCESS, feedbackTemplates } }
    function failure(error) { return { type: feedbackTemplateConstants.GETALL_FEEDBACK_TEMPLATES_FAILURE, error } }
}

function createFeedbackTemplate(feedbackTemplateRequest, submitCallBackFunction) {
    return dispatch => {
        dispatch(request(feedbackTemplateRequest));
        dispatch(notificationActions.clear());
        feedbackTemplateService.createFeedbackTemplate(feedbackTemplateRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload));
                    submitCallBackFunction();
                    dispatch(notificationActions.success('FeedbackTemplate Creation successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)));
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));

                }
            );
    };

    function request(feedbackTemplate) { return { type: feedbackTemplateConstants.CREATE_FEEDBACK_TEMPLATE_REQUEST, feedbackTemplate } }
    function success(feedbackTemplate) { return { type: feedbackTemplateConstants.CREATE_FEEDBACK_TEMPLATE_SUCCESS, feedbackTemplate } }
    function failure(error) { return { type: feedbackTemplateConstants.CREATE_FEEDBACK_TEMPLATE_FAILURE, error } }
}

function updateFeedbackTemplate(feedbackTemplateRequest, submitCallBackFunction) {
    return dispatch => {
        dispatch(request(feedbackTemplateRequest));
        dispatch(notificationActions.clear());
        feedbackTemplateService.updateFeedbackTemplate(feedbackTemplateRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload, feedbackTemplateRequest.id));
                    submitCallBackFunction();
                    dispatch(notificationActions.success('FeedbackTemplate update successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)))
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));
                }
            );
    };

    function request(feedbackTemplate) { return { type: feedbackTemplateConstants.UPDATE_FEEDBACK_TEMPLATE_REQUEST, feedbackTemplate } }
    function success(feedbackTemplate, feedbackTemplateId) { return { type: feedbackTemplateConstants.UPDATE_FEEDBACK_TEMPLATE_SUCCESS, feedbackTemplate, feedbackTemplateId } }
    function failure(error) { return { type: feedbackTemplateConstants.UPDATE_FEEDBACK_TEMPLATE_FAILURE, error } }
}

function removeFeedbackTemplate(feedbackTemplateId) {
    return dispatch => {
        dispatch(request(feedbackTemplateId));

        feedbackTemplateService.removeFeedbackTemplate(feedbackTemplateId)
            .then(
                taskResponse => dispatch(success(feedbackTemplateId)),
                error => {
                    dispatch(failure(feedbackTemplateId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(feedbackTemplateId) { return { type: feedbackTemplateConstants.REMOVE_FEEDBACK_TEMPLATE_REQUEST, feedbackTemplateId } }
    function success(feedbackTemplateId) { return { type: feedbackTemplateConstants.REMOVE_FEEDBACK_TEMPLATE_SUCCESS, feedbackTemplateId } }
    function failure(feedbackTemplateId, error) { return { type: feedbackTemplateConstants.REMOVE_FEEDBACK_TEMPLATE_FAILURE, feedbackTemplateId, error } }
}

function getFeedbackTemplate(feedbackTemplateId, callbackFunction) {
    return dispatch => {
        dispatch(request(feedbackTemplateId));

        feedbackTemplateService.getFeedbackTemplate(feedbackTemplateId)
            .then(
               feedbackTemplateResponse => {
                    callbackFunction(feedbackTemplateResponse.payload);
                    dispatch(success(feedbackTemplateId))
                },
                error => {
                    dispatch(failure(feedbackTemplateId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(feedbackTemplateId) { return { type: feedbackTemplateConstants.GET_FEEDBACK_TEMPLATE_REQUEST, feedbackTemplateId } }
    function success(feedbackTemplateId) { return { type: feedbackTemplateConstants.GET_FEEDBACK_TEMPLATE_SUCCESS, feedbackTemplateId } }
    function failure(feedbackTemplateId, error) { return { type: feedbackTemplateConstants.GET_FEEDBACK_TEMPLATE_FAILURE, feedbackTemplateId, error } }
}

function activateFeedbackTemplate(feedbackTemplateId, isActive) {
    return dispatch => {
        dispatch(request(feedbackTemplateId));

        feedbackTemplateService.activateFeedbackTemplate(feedbackTemplateId, isActive)
            .then(
                feedbackTemplateResponse => dispatch(success(feedbackTemplateId, isActive)),
                error => {
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(feedbackTemplateId) { return { type: feedbackTemplateConstants.ACTIVATE_FEEDBACK_TEMPLATE_REQUEST, feedbackTemplateId } }
    function success(feedbackTemplateId, isActive) { return { type: feedbackTemplateConstants.ACTIVATE_FEEDBACK_TEMPLATE_SUCCESS, feedbackTemplateId, isActive } }
  
}
