import React, { Component } from "react";
import { connect } from "react-redux";
import { validationUtils } from "_helpers";
import QuestionaireTable from "../fragments/QuestionaireTable";
import { store } from "_helpers";
import { checklistConstants } from "_constants";
import { commonUtils } from "_helpers";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { checklistActions } from "_actions";
import { accessUtils } from "_helpers";
import { appConfigActions } from "_actions";
import ItemChecklistInput from "../fragments/ItemChecklistInput";

class ConfigureChecklistSidebar extends Component {
    constructor() {
        super();
        this.state = {
            formErrors: {},
            hasFormError: false,
            permission: accessUtils.getChecklistAccessControl()
        }
    }
    onCloseItemClick = () => {
        store.dispatch({ type: checklistConstants.CLOSE_CHECKLIST_ANSWER_WINDOW });
    }

    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }
    onResetFormError = () => {
        this.setState({
            hasFormError: false
        })
    }

    submitCallBackFunction = () => {
        this.setState({ checklistRequest: {} })
    }

    handleSubmit = (checklistRequest) => {
        let isValidForm = validationUtils.isValidRequest(this.props.fieldConfig, checklistRequest, this.state.formErrors);
        isValidForm = checklistRequest.questionDataList !== undefined && checklistRequest.questionDataList.length > 0;
        if (isValidForm === true) {
            checklistRequest.questionDataList.forEach(question => {
                if (question.question === "") {
                    isValidForm = false;
                }
            });
        }
        if (isValidForm) {
            if (!checklistRequest.id) {
                store.dispatch(this.props.createChecklist(checklistRequest, this.submitCallBackFunction));
            } else {
                store.dispatch(this.props.updateChecklist(checklistRequest, this.submitCallBackFunction));
            }
        } else {
            this.setState({
                hasFormError: true
            })
        }
    }

    handleRemoveChecklist = (checklistId) => {
        store.dispatch(this.props.removeChecklist(checklistId));
    }
    updateItemChecklistData = (checklistData) => {
        store.dispatch(this.props.updateItemChecklistData(checklistData, this.props.selectedItemInfo.id, this.props.type));
    }
    render() {
        return (
            <div id="configchecklistsidebar" className={`right_sidebar config_checklist_right_sidebar ${this.props.show === true ? 'open' : ''}`}>
                <div className="justify-content-between flex-column d-flex h-100vh">
                    <div className="card-header py-0 border-bottom">
                        <h3 className="card-title">{this.props.showChecklistQuestionsWindow === false ? "Configure Checklist Templates" : "Fill Work Item Checklist"}</h3>
                        <div className="card-options">
                            <button onClick={this.onCloseItemClick} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
                        </div>
                    </div>
                    {this.props.showConfigWindow === true && this.props.showChecklistQuestionsWindow === false &&
                        <ConfigureChecklistSidebarSection
                            permission={this.state.permission}
                            fieldConfig={this.props.fieldConfig}
                            onFormError={this.onFormError}
                            onResetFormError={this.onResetFormError}
                            hasFormError={this.state.hasFormError}
                            getAllChecklists={this.props.getAllChecklists}
                            handleRemoveChecklist={this.handleRemoveChecklist}
                            checklistDataList={this.props.checklistDataList}
                            type={this.props.type}
                            selectedItemInfo={this.props.selectedItemInfo}
                            submitChecklist={this.handleSubmit}
                            allUserTeams={this.props.allUserTeams}
                            typeOptions={this.props.typeOptions}
                        />
                    }
                    {this.props.showChecklistQuestionsWindow === true && this.props.showConfigWindow === false &&
                        <ItemChecklistInput
                            handleUpdateChecklist={this.updateItemChecklistData}
                            type={this.props.type}
                            isSidebar={true}
                            readOnly={false}
                            permission={this.props.permission}
                            checklistData={this.props.selectedItemInfo.checklistData}
                            itemUserTeamId={this.props.selectedItemInfo.userTeamId}
                        />
                    }
                </div >
            </div >
        );
    }
}

class ConfigureChecklistSidebarSection extends Component {
    constructor() {
        super();
        this.state = {
            checklistRequest: {
            },
            questionConfigureMode: true,
            editMode: true,
            filterText: ""
        }
    }
    componentDidMount() {
        store.dispatch(this.props.getAllChecklists({ itemType: this.props.type }))
        store.dispatch(appConfigActions.getAllTeams(false));
        this.setState({
            checklistRequest: {
                userTeamId: commonUtils.getCurrentUserTeamId(),
                type: this.props.type
            },
        })
    }

    addNewQuesiton = () => {
        let questionDataList = this.state.checklistRequest.questionDataList || [];
        const newQuestion = {
            index: questionDataList.length,
            id: Date.now(),
            question: "",
            type: "boolean",
            mandatory: false

        }
        questionDataList.push(newQuestion);
        this.setState({
            checklistRequest: {
                ...this.state.checklistRequest,
                questionDataList: questionDataList

            }
        });
    }

    onFormError = (fieldName, hasError) => {
        this.props.onFormError(fieldName, hasError);
    }
    onResetFormError = () => {
        this.props.onResetFormError();
    }
    onCloseItemClick = () => {
        store.dispatch({ type: checklistConstants.CLOSE_CHECKLIST_CONFIGURE_WINDOW });
    }

    onChange = (fieldName, value) => {
        this.onResetFormError()
        this.setState({
            checklistRequest: {
                ...this.state.checklistRequest,
                [fieldName]: value
            }
        })
    }

    togglequestionConfigureMode = (questionConfigureMode) => {
        this.setState({ questionConfigureMode })
    }

    onRestChecklist = () => {
        this.setState({
            checklistRequest: {
                userTeamId: commonUtils.getCurrentUserTeamId(),
                type: this.props.type
            },
            editMode: true
        })
    }

    onSaveChecklist = () => {
        this.props.submitChecklist(this.state.checklistRequest);
    }

    onEditChecklist = (checklistData) => {
        this.setState({ checklistRequest: checklistData, editMode: true })
        this.togglequestionConfigureMode(true)
    }
    onDuplicateChecklist = (checklistData) => {
        const { id, ...checklistDataCopy } = checklistData;
        this.setState(
            {
                checklistRequest: checklistDataCopy,
                editMode: true
            })
        this.togglequestionConfigureMode(true)
    }

    onSelectChecklist = (checklistData) => {
        this.setState({ checklistRequest: checklistData, editMode: false })
        this.togglequestionConfigureMode(false)
    }

    onFilterTextChange = (filterText) => {
        this.setState({ filterText })
    }

    render() {
        return (<div className="card-body pl-3 pr-3 overflow-auto">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 border-right height-100-sidebar">
                        <div className="card  ">
                            <div className="card-header py-0 pr-3 pl-0">
                                <div className="input-icon w-100p pr-3">
                                    <span className="input-icon-addon">
                                        <i className="fa fa-sort-amount-desc"></i></span>
                                    <input onChange={(event) => { this.onFilterTextChange(event.target.value) }} type="text" className="form-control input-sm h-20px" placeholder="Filter..." value={this.state.filterText} />
                                </div>

                            </div>
                            <div className="card-body">
                                {this.props.checklistDataList &&
                                    <ul className="right_chat  p-0">
                                        {commonUtils.sortArrayOfObjects(this.props.checklistDataList, true).map(checklistData => {
                                            return (this.state.filterText === "" || commonUtils.stringContainsString(checklistData.name, this.state.filterText)) &&
                                                <ChecklistItem
                                                    key={checklistData.id}
                                                    selectedChecklistId={this.state.checklistRequest.id}
                                                    handleRemoveChecklist={this.props.handleRemoveChecklist}
                                                    onEditChecklist={this.onEditChecklist}
                                                    onDuplicateChecklist={this.onDuplicateChecklist}
                                                    onSelectChecklist={this.onSelectChecklist}
                                                    permission={this.props.permission}
                                                    checklistData={checklistData} />
                                        }
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="col-12">
                            <div className=" row">
                                <div className="col-6">
                                    <CustomInput inputtype='text'
                                        label="Name"
                                        name="name"
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.checklistRequest}
                                        onChange={this.onChange}
                                        onFormError={this.onFormError}
                                        placeholder="Checklist Name..."
                                        datatype='alpnumspl' />
                                </div>
                                <div className="col-6">
                                    <CustomInput
                                        label="Description"
                                        name="description"
                                        type='textarea'
                                        height={"37px"}
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.checklistRequest}
                                        onChange={this.onChange}
                                        onFormError={this.onFormError}
                                        placeholder="Description..."
                                        datatype='alpnumspl' />
                                </div>
                            </div>
                            <div className=" row">
                                <div className="col-12">

                                    <CustomInput
                                        type='select'
                                        label="Restricted for User Teams"
                                        name="restrictedByUserTeamIds"
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.checklistRequest}
                                        onChange={this.onChange}
                                        placeholder="User Team"
                                        options={this.props.allUserTeams && commonUtils.convertObjectToSelectObject(this.props.allUserTeams)}
                                        onFormError={this.onFormError}
                                        multi={true}
                                    />
                                </div>
                                {/* <div className="col-6">
                                    <CustomInput
                                        type='select'
                                        label="Default for User Teams"
                                        name="defaultForUserTeamIds"
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.checklistRequest}
                                        onChange={this.onChange}
                                        placeholder="User Team"
                                        options={this.props.userTeams && commonUtils.convertObjectToSelectObject(this.props.userTeams)}
                                        onFormError={this.onFormError}
                                        multi={true}
                                    />
                                </div> */}
                            </div>
                        </div>
                        
                        <div className="col-12 py-1">
                            <div className="card pb-0 mb-0 ">
                                <div className="card-header pt-0 pb-1 px-0 border-bottom">
                                    <span className="text-muted fweight600">
                                        <i className="fa fa-info-circle pr-1"></i>Questions
                                    </span>
                                    {this.state.editMode &&
                                        <div className="card-options">
                                            {this.state.questionConfigureMode &&
                                                <button className="link fsize14 pr-3"
                                                    onClick={this.addNewQuesiton} >
                                                    <i className="fa fa-plus colorblue"></i>
                                                </button>
                                            }

                                            <button className="link fsize14"
                                                onClick={() => this.togglequestionConfigureMode(!this.state.questionConfigureMode)}>
                                                {this.state.questionConfigureMode &&
                                                    <i className="fa fa-list-ul"></i>
                                                }
                                                {!this.state.questionConfigureMode &&
                                                    <i className=" fa fa-navicon"></i>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                                <QuestionaireTable
                                    typeOptions={this.props.typeOptions}
                                    onChange={this.onChange}
                                    dragMode={true}
                                    editMode={this.state.editMode}
                                    questionConfigureMode={this.state.questionConfigureMode}
                                    questionDataList={this.state.checklistRequest && this.state.checklistRequest.questionDataList}
                                />
                            </div>
                        </div>
                        {this.props.hasFormError &&
                            <div className="col-12 py-1">
                                <div className="alert alert-danger alert-dismissible mb-0 py-1">
                                    <button type="button" className="close py-1" onClick={() => this.props.onResetFormError()}></button>
                                    <strong><i className="fa fa-exclamation-triangle colorred"></i></strong> Error : Please complete the mandatory fields before Submitting!
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-12 py-1">
                                {(this.props.permission.createEnabled || (this.state.checklistRequest.id !== undefined && this.props.permission.editEnabled)) &&
                                    <div className="float-right">
                                        {this.state.editMode &&
                                            <button onClick={() => this.onSaveChecklist()} className="btn btn-primary btn-sm mr-3">
                                                Save Checklist
                                            </button>
                                        }
                                        <button onClick={() => this.onRestChecklist()} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                            Reset
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>)
    }
}

class ChecklistItem extends Component {
    constructor() {
        super();
        this.state = {
            confrimDelete: false
        }
    }
    handleRemoveChecklist = (isConfrimDelete) => {
        if (isConfrimDelete) {
            this.props.handleRemoveChecklist(this.props.checklistData.id);
        } else {
            this.setState({
                confrimDelete: true
            })
        }
    }
    render() {
        return (
            <li className={`p-1 ${this.props.selectedChecklistId && this.props.selectedChecklistId == this.props.checklistData.id ? 'colorblue fweight600' : ''}`} key={this.props.checklistData.id}>
                <div className="d-flex justify-content-between align-items-center mt-0 pb-1 px-1">
                    <span onClick={() => this.props.onSelectChecklist(this.props.checklistData)} className="name">{this.props.checklistData.name}</span>
                    <span>
                        {this.props.permission.duplicateEnabled === true &&
                            <button className="link" onClick={() => this.props.onEditChecklist(this.props.checklistData)}>
                                <i className="fa fa-edit pr-2" />
                            </button>
                        }
                        {this.props.permission.editEnabled === true &&
                            <button className="link" onClick={() => this.props.onDuplicateChecklist(this.props.checklistData)}>
                                <i className="fa fa-clone pr-2" />
                            </button>
                        }
                        {this.props.permission.removeEnabled === true && !(this.props.selectedChecklistId && this.props.selectedChecklistId == this.props.checklistData.id) &&

                            <button type="button" className="link" title="Remove" onClick={() => this.handleRemoveChecklist(this.state.confrimDelete)}>
                                {this.state.confrimDelete === true &&
                                    <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
                                }
                                {this.state.confrimDelete === false &&
                                    <i className="dropdown-icon fa fa-times colorred"></i>
                                }
                            </button>
                        }
                    </span>

                </div>
            </li>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.authentication.user,
    allUserTeams: state.appconfig.allTeams,
    allEmployees: state.employees.allEmployees,
    checklistDataList: state.checklist.items,
    fieldConfig: state.checklist.checklistFieldConfig,
    typeOptions:state.checklist.typeOptions
});

const mapDispatchToProps = dispatch => ({
    getAllChecklists: checklistActions.getAllChecklists,
    createChecklist: checklistActions.createChecklist,
    updateChecklist: checklistActions.updateChecklist,
    removeChecklist: checklistActions.removeChecklist,
    activateChecklist: checklistActions.activateChecklist,
    updateItemChecklistData: checklistActions.updateItemChecklistData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureChecklistSidebar);

