import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

import QuestionaireTable from "./QuestionaireTable";
import { store } from "_helpers";
import { checklistConstants } from "_constants";
import { commonUtils } from "_helpers";
import { accessUtils } from "_helpers";
import { checklistActions } from "_actions";
import ItemNotFoundSection from "../ItemsNotFoundSection";
class ItemChecklistInput extends Component {
    constructor() {
        super();
        this.state = {
            formErrors: {},
            hasFormError: false,
            checklistData: {},
            changableOptionEnabled: false,
            changeButtonEnabled: false,
            selectedChecklist: null,
            permission: accessUtils.getChecklistAccessControl()
        }
    }
    componentDidMount() {
        this.setState({
            checklistData: this.props.checklistData || {
                metaData: {
                    complete: false,
                    active: true,
                    lastModifiedBy: commonUtils.getCurrentUserId()
                },
            }
        })
    }
    onShowChecklistConfig = () => {
        store.dispatch({ type: checklistConstants.SHOW_CHECKLIST_CONFIGURE_WINDOW });
    }

    toggleChangable = (changable) => {
        this.setState({
            changableOptionEnabled: changable
        })
        if (changable === true) {
            store.dispatch(this.props.getAllChecklists({ itemType: this.props.type, userTeamId: this.props.itemUserTeamId }))
        } else {
            this.setState({
                checklistData: this.props.checklistData || {
                    metaData: {
                        complete: false,
                        active: true,
                        lastModifiedBy: commonUtils.getCurrentUserId()
                    },
                }
            })
        }
    }

    toggleCloseChangalbe = (changable) => {
        this.setState({
            changableOptionEnabled: changable
        })
    }

    onChangeChecklistSelection = (selectedChecklist) => {
        this.setState({
            selectedChecklist: selectedChecklist,
            checklistData: {
                metaData: {
                    complete: false,
                    active: true,
                    lastModifiedBy: commonUtils.getCurrentUserId()
                },
                questionDataList: selectedChecklist.questionDataList,
            },
            changeButtonEnabled: true
        })
    }

    onQuestionsDataChange = (name, questionDataList) => {
        this.setState({
            hasFormError: false,
            checklistData: {
                ...this.state.checklistData,
                [name]: questionDataList
            }
        })
    }

    onCleaseFormError = () => {
        this.setState({
            hasFormError: false
        });
    }

    handleUpdateChecklist = () => {
        this.props.handleUpdateChecklist(this.state.checklistData);
    }

    handleCompleteChecklist = () => {
        let isValidForm = true;
        this.state.checklistData.questionDataList.forEach(question => {
            if ((question.answer === undefined || question.answer === "") && question.mandatory === true) {
                isValidForm = false;
            }
        });
        if (isValidForm) {
            let checklistData = {
                ...this.state.checklistData,
                metaData: {
                    complete: true,
                    active: true,
                    lastModifiedBy: commonUtils.getCurrentUserId()
                }
            }
            this.setState({ checklistData });

            this.props.handleUpdateChecklist(checklistData);
        } else {
            this.setState({ hasFormError: true });
        }
    }

    render() {
        let updateEnabled = (this.state.checklistData && this.state.checklistData.questionDataList && ((this.props.permission.checklistSaveEnabled && this.state.checklistData.metaData.complete === false) || (this.props.permission.checklistUpdateCompletedEnabled && this.state.checklistData.metaData.complete === true))) && this.props.readOnly === false;
        return (
            <div className="col-12 px-0">
                {!this.props.isSidebar &&
                    <span className="subHeading col-12 section-banner">
                        <button className="link pr-2" ><i className="fa fa-chevron-down"></i></button>
                        Checklist
                        {!this.state.changableOptionEnabled && this.props.permission.checklistChangeEnabled && !this.props.readOnly &&
                            <button className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal" title="Configure Checklist" onClick={() => this.toggleChangable(true)}>Change</button>
                        }
                        {this.state.permission.viewEnabled && !this.props.readOnly &&
                            <span className="card-options">
                                <button className="link-normal pr-2 text-right" title="Configure Checklist" onClick={() => this.onShowChecklistConfig()}><i className="fa fa-cogs colorblue pr-1"></i>Configure</button>
                            </span>
                        }
                    </span>
                }
                {this.props.isSidebar &&
                    <span className="subHeading col-12">
                        <span className="card-options">
                            {!this.state.changableOptionEnabled && this.props.permission.checklistChangeEnabled &&
                                <button className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal" title="Configure Checklist" onClick={() => this.toggleChangable(true)}>
                                    {this.state.checklistData && this.state.checklistData.questionDataList ? "Change Checklist" : "Add Checklist"}
                                </button>
                            }
                            {this.state.permission.viewEnabled &&
                                <button className="link-normal pr-2 text-right" title="Configure Checklist" onClick={() => this.onShowChecklistConfig()}><i className="fa fa-cogs colorblue pr-1"></i>Configure</button>
                            }
                        </span>

                    </span>
                }
                {this.state.changableOptionEnabled &&
                    <div className="mb-1 py-2 px-2 changeChecklistForm">
                        <div className="row">
                            <div className="col-7 py-1">
                                <Select
                                    isSearchable={true}
                                    isClearable={false}
                                    placeholder={"Select Checklist"}
                                    classNamePrefix={'custom-select'}
                                    isMulti={false}
                                    menuPlacement={'auto'}
                                    value={this.state.selectedChecklist}
                                    onChange={(selectedOption) => this.onChangeChecklistSelection(selectedOption)}
                                    options={this.props.checklistDataList && commonUtils.convertObjectToSelectObject(this.props.checklistDataList)}
                                />
                            </div>

                            <div className="col-5 py-1">
                                {this.state.changeButtonEnabled &&
                                    <button onClick={() => this.toggleChangable(false)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                        Reset
                                    </button>
                                }
                                <button onClick={() => this.toggleCloseChangalbe(false)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                    Close
                                </button>
                            </div>

                        </div>
                    </div>
                }
                <div className="col-12 py-1">
                    <QuestionaireTable
                        dragMode={false}
                        editMode={false}
                        configureMode={false}
                        readOnly={!updateEnabled}
                        onChange={this.onQuestionsDataChange}
                        questionDataList={this.state.checklistData && this.state.checklistData.questionDataList}
                    />
                    {this.state.checklistData && this.props.isSidebar &&
                       <ItemNotFoundSection items={this.state.checklistData.questionDataList}  imgurl="no_checklist.png" text={`Checklist Not Added ?`} description="Try Attaching a checklist..." />
                    }
                </div>
                {this.state.hasFormError &&
                    <div className="col-12 py-1">
                        <div className="alert alert-danger alert-dismissible mb-0 py-1">
                            <button type="button" className="close py-1" onClick={() => this.onCleaseFormError()}></button>
                            <strong><i className="fa fa-exclamation-triangle colorred"></i></strong> Error : Please complete the mandatory fields before Submitting!
                        </div>
                    </div>
                }
                <div className="col-12 py-1">

                    {updateEnabled &&
                        <button onClick={() => this.handleUpdateChecklist()} className="btn btn-primary btn-sm mr-3">
                            Update
                        </button>
                    }
                    {this.props.permission.checklistCompleteEnabled && (this.state.checklistData && this.state.checklistData.questionDataList && this.state.checklistData.metaData.complete === false) &&
                        <button onClick={() => this.handleCompleteChecklist()} className="btn btn-primary btn-sm mr-3">
                            Complete
                        </button>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    checklistDataList: state.checklist.items,
});

const mapDispatchToProps = dispatch => ({
    getAllChecklists: checklistActions.getAllChecklists
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemChecklistInput);

