import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { ProjectDetailViewNameConst } from "../ProjectDetailsPage";
import { accessUtils } from "_helpers";

class ProjectDetailsHeader extends Component {
  render() {
    return (
      <div className="section-body listgridheader stickytop">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs  ml-2">
              <li className="nav-item">
                <button
                  className="link nav-link fsize11"
                  data-toggle="tab"
                  onClick={() =>
                    this.props.headerSelect(ProjectDetailViewNameConst.GENERAL)
                  }
                >
                  Details
                </button>
              </li>
              <li className="nav-item">
                <button className="link nav-link fsize11" data-toggle="tab">
                  Dashboard
                </button>
              </li>
              <li className="nav-item">
                <button className="link nav-link fsize11" data-toggle="tab">
                  Related Projects
                </button>
              </li>
              <li className="nav-item px-0">
                {this.props.projectInfo &&
                  this.props.projectInfo.status !== "NEW" &&
                  this.props.projectInfo.status !== "ASSIGNED" && (
                    <NavLink
                      to={
                        "/project/deliverables/" +
                        this.props.projectInfo.itemIdUserTeamPrefix
                      }
                      className={`nav-link`}
                    >
                      Deliverables
                    </NavLink>
                  )}
                {!(
                  this.props.projectInfo &&
                  this.props.projectInfo.status !== "NEW" &&
                  this.props.projectInfo.status !== "ASSIGNED"
                ) && (
                  <span
                    className="link fsize11 colordisabled"
                    data-toggle="tab"
                  >
                    Deliverables
                  </span>
                )}
              </li>
              {accessUtils.getFeedbackRequestAccessControl().viewEnabled ===
                true && (
                <li className="nav-item">
                  <button
                    className="link nav-link fsize11"
                    data-toggle="tab"
                    onClick={() =>
                      this.props.headerSelect(
                        ProjectDetailViewNameConst.FEEDBACK
                      )
                    }
                  >
                    Feedback
                  </button>
                </li>
              )}
            </ul>

            <div className="header-action pt-0 d-none">
              <ul className="nav nav-tabs  pl-2 mx-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="Project-tab"
                    data-toggle="tab"
                    href="#Project-Settings"
                  >
                    Project Settings
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsHeader;
