import React, { Component } from "react";

class MultiOptionDropDownSingleSelectInput extends Component {
  onChange = (value) => {
    this.props.onChange({ ...this.props.questionConfig, answer: value.trim() });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6">
            <div className="input_field">
              <label className="inputLabel">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>
              <select
                className="form-control custom-select select-toggle"
                onChange={(e) => this.onChange(e.target.value)}
                value={this.props.questionConfig.answer || ""}
                disabled={this.props.readonly}
              >
                <option value="">-Select-</option>
                {this.props.questionConfig.questionOptions &&
                  this.props.questionConfig.questionOptions
                    .split(",")
                    .map((value) => (
                      <option key={value.trim()} value={value.trim()}>
                        {value.trim()}
                      </option>
                    ))}
              </select>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default MultiOptionDropDownSingleSelectInput;
