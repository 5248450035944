import { userUtils } from "./user.utils";

export const commonUtils = {
  hasAccess,
  encryptString,
  decryptString,
  getNameInitial,
  convertToSentenceCase,
  removeNameIntial,
  convertConfigListToSelectObject,
  convertObjectToSelectObject,
  convertEmployeeEmailToSelectObject,
  convertPurchaseOrderNameToSelectObject,
  //convertPurchaseOrderObjectToSelectObject,
  convertObjectToSelectGroupObject,
  compareObjects,
  sortArrayOfObjects,
  sortArrayOfObjectsByKey,
  sortArrayOfStrings,
  sortArrayOfObjectsByDateField,
  stringContainsString,
  getCurrentUser,
  getCurrentUserId,
  getCurrentUserRoleId,
  getCurrentUserName,
  getCurrentUserTeamId,
  getCurrentUserTeamIds,
  getCurrentUserTeamName,
  getUserTeams,
  getUserTeamsFromUserItem,
  getSelectedUserTeamIds,
  getSelectedEmployeeIds,
  getSelectedEmployee,
  getEmployeeSkills,
  filterEmployees,
  filterBidRequests,
  filterPurchaseOrders,
  filterProjects,
  filterFeedbacks,
  filterTasks,
  filterWorkItems,
  filterText,
  filterAccounts,
  convertWorkItemsForCalendar,
  convertHolidayLeaveForCalendar,
  filterEmployeesByTeams,
  filterSkillsByEmployee,
  filterEmployeeBySkills,
  getActiveEmployees,
  getClientsOptions,
  getAccountOptions,
  getTeamOptions,
  getUniqueElementsFromArray,
  getCurrentEmployeePrimaryClient,
  getPercentageOfCompletion,
  getAppConfigValue,
  mapKeysToList,
  getSubRoles,
};

function convertToSentenceCase(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
function getNameInitial(name) {
  var split_names = name.trim().split(" ");
  if (split_names.length > 1) {
    let second = split_names[1].charAt(0);

    return (
      split_names[0].charAt(0) +
      "" +
      (second.match(/[a-z]/i)
        ? second
        : split_names[1].length > 1
        ? split_names[1].charAt(1)
        : "")
    ).toUpperCase();
  }
  return split_names[0].charAt(0).toUpperCase();
}
function removeNameIntial(name) {
  var split_names = name.trim().split("-");
  if (split_names.length === 1) {
    return split_names[0];
  }
  return split_names[1];
}

function getAppConfigValue(appConfig, configName) {
  let configObject =
    appConfig && appConfig.find((config) => config.name === configName);
  return configObject && configObject.value;
}

function convertConfigListToSelectObject(inputValues) {
  return (
    inputValues &&
    sortArrayOfStrings(inputValues).map((value) => {
      return { value: value, label: value };
    })
  );
}

function mapKeysToList(inputValueMap) {
  return inputValueMap && Object.keys(inputValueMap).map((key) => key);
}

function convertObjectToSelectObject(objects) {
  return (
    objects &&
    sortArrayOfObjects(objects, true).map((item) => {
      return { ...item, value: item.id, label: item.name };
    })
  );
}

function convertPurchaseOrderNameToSelectObject(objects) {
  return (
    objects &&
    sortArrayOfObjects(objects, true).map((item) => {
      let name = item.name;
      if (
        item.proposalNumber &&
        item.proposalNumber.length > 0 &&
        item.name &&
        item.name.indexOf(item.proposalNumber) < 0
      ) {
        name = item.proposalNumber + " " + item.name;
      }
      return { ...item, value: item.id, label: name };
    })
  );
}
function convertEmployeeEmailToSelectObject(objects) {
  return (
    objects &&
    sortArrayOfObjects(objects, true).map((item) => {
      return { ...item, value: item.email, label: item.name };
    })
  );
}

//function convertPurchaseOrderObjectToSelectObject(objects) {
// return objects && sortArrayOfObjects(objects, true).map(item => { return ({ ...item, value: item.purchaseOrderNumber, label: item.name }) });
//}

function convertObjectToSelectGroupObject(objects) {
  return (
    objects &&
    objects.map((item) => {
      return {
        label: item.name,
        value: item.id,
        options: item.nodes.map((option) => {
          return { ...item, value: option.id, label: option.name };
        }),
      };
    })
  );
}

function sortArrayOfObjects(objects, isAscOrder) {
  //console.log(objects);
  //console.log("Sort order : " + isAscOrder)
  return (
    objects && objects.sort((a, b) => compareObjects(a, b, "name", isAscOrder))
  );
}

function sortArrayOfObjectsByKey(objects, key, isAscOrder) {
  //console.log(objects);
  //console.log("Sort order : " + isAscOrder)
  return (
    objects && objects.sort((a, b) => compareObjects(a, b, key, isAscOrder))
  );
}
function sortArrayOfObjectsByDateField(objects, fieldName, isAscOrder) {
  return objects.sort(function (a, b) {
    let startDate = a[fieldName] ? a[fieldName] : a.estStartTime;
    let startDate2 = b[fieldName] ? b[fieldName] : b.estStartTime;
    if (startDate && startDate2) {
      if (isAscOrder) {
        return new Date(startDate) - new Date(startDate2);
      } else {
        return new Date(startDate2) - new Date(startDate);
      }
    }
    return 0;
  });
}

function compareObjects(object1, object2, key, isAscOrder) {
  try {
    const obj1 = object1[key];
    const obj2 = object2[key];
    const compare = obj1.localeCompare(obj2);
    return compare === 0 ? 0 : isAscOrder ? obj1.localeCompare(obj2) * 1 : -1;
  } catch (error) {}
  return 0;
}

function sortArrayOfStrings(itemsArray) {
  return itemsArray && itemsArray.sort();
}

function stringContainsString(string, subString) {
  return string.toLocaleLowerCase().includes(subString.toLocaleLowerCase());
}

function getUserTeams(userTeams) {
  //console.log("get user team$$$$")
  return userTeams ? userTeams : getUserTeamsFromUserItem();
}

function getSelectedUserTeamIds(userTeams) {
  let selectedUserTeamsIds = [];
  userTeams &&
    userTeams.map(
      (userTeam) => userTeam.isChecked && selectedUserTeamsIds.push(userTeam.id)
    );
  return selectedUserTeamsIds;
}

function getSelectedEmployeeIds(employees) {
  let selectedEmployeeIds = [];
  employees &&
    employees.map(
      (employee) => employee.isChecked && selectedEmployeeIds.push(employee.id)
    );
  return selectedEmployeeIds;
}

function getSelectedEmployee(employees) {
  return employees && employees.find((employee) => employee.isChecked);
}

function getUserTeamsFromUserItem() {
  let userTeams = [];

  let currentUser = getCurrentUser();
  if (currentUser) {
    userTeams.push({
      id: currentUser.defaultUserTeamDetails.id,
      name: currentUser.defaultUserTeamDetails.name,
      isChecked: true,
      default: true,
    });

    currentUser.additionalUserTeamDetails &&
      currentUser.additionalUserTeamDetails.forEach((userTeam) => {
        if (userTeam.id !== currentUser.defaultUserTeamDetails.id) {
          userTeams.push({
            id: userTeam.id,
            name: userTeam.name,
            isChecked: false,
            default: false,
          });
        }
      });
  }
  return userTeams;
}

function getCurrentUserTeamIds() {
  return getUserTeamsFromUserItem().map((userTeam) => userTeam.id);
}

function getCurrentUser() {
  return userUtils.getCurrentUser();
}

function getCurrentEmployeePrimaryClient() {
  return getCurrentUser().primaryClient;
}
function getCurrentUserId() {
  let currentUser = getCurrentUser();
  if (currentUser) {
    return currentUser.id;
  }
}
function getCurrentUserRoleId() {
  let currentUser = getCurrentUser();
  if (currentUser) {
    return currentUser.userRole;
  }
}
function getCurrentUserName() {
  let currentUser = getCurrentUser();
  if (currentUser) {
    return currentUser.name;
  }
}
function getCurrentUserTeamId() {
  let currentUser = getCurrentUser();
  if (currentUser) {
    return currentUser.defaultUserTeamDetails.id;
  }
}
function getCurrentUserTeamName() {
  let currentUser = getCurrentUser();
  if (currentUser) {
    return currentUser.defaultUserTeamDetails.name;
  }
}

function getEmployeeSkills(selectedEmployee) {
  if (selectedEmployee) {
    let additionalSkills = selectedEmployee.additionalSkills || [];
    return [selectedEmployee.primarySkill, ...additionalSkills];
  }
}

function filterEmployees(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  if (filterOptions && filterOptions.SHOW) {
    if (filterOptions.SHOW.selected === "ACTIVE") {
      items = items.filter((employee) => employee.active);
    }
    if (filterOptions.SHOW.selected === "INACTIVE") {
      items = items.filter((employee) => !employee.active);
    }
  }

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter(
      (employee) =>
        commonUtils.stringContainsString(
          employee.name,
          filterOptions.filterText
        ) ||
        commonUtils.stringContainsString(
          employee.email,
          filterOptions.filterText
        ) ||
        (employee.empCode &&
          commonUtils.stringContainsString(
            employee.empCode,
            filterOptions.filterText
          )) ||
        (employee.designation &&
          commonUtils.stringContainsString(
            employee.designation,
            filterOptions.filterText
          )) ||
        (employee.primarySkill &&
          commonUtils.stringContainsString(
            employee.primarySkill,
            filterOptions.filterText
          ))
    );
  }
  items = sortItems(items, filterOptions);
  return items;
}

function getActiveEmployees(allEmployees) {
  return allEmployees.filter(
    (employee) => employee.active && !employee.deleted
  );
}

function filterPurchaseOrders(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  items = filterItems(items, filterOptions);

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((task) =>
      commonUtils.stringContainsString(task.name, filterOptions.filterText)
    );
  }
  items = sortItems(items, filterOptions);
  return items;
}

function sortItems(items, filterOptions) {
  if (filterOptions && filterOptions.SORT) {
    if (filterOptions.SORT.selected === "A-Z") {
      items = commonUtils.sortArrayOfObjects(
        items,
        filterOptions.SORT.directionUp
      );
    } else if (filterOptions.SORT.selected === "ACTUALSTARTDATE") {
      items = commonUtils.sortArrayOfObjectsByDateField(
        items,
        "actualStartTime",
        filterOptions.SORT.directionUp
      );
    } else if (filterOptions.SORT.selected === "ESTIMATEDSTARTDATE") {
      items = commonUtils.sortArrayOfObjectsByDateField(
        items,
        "estStartTime",
        filterOptions.SORT.directionUp
      );
    } else if (filterOptions.SORT.selected === "ESTIMATEDENDDATE") {
      items = commonUtils.sortArrayOfObjectsByDateField(
        items,
        "estCompletionTime",
        filterOptions.SORT.directionUp
      );
    } else if (filterOptions.SORT.selected === "CREATEDDATE") {
      items = commonUtils.sortArrayOfObjectsByDateField(
        items,
        "createdDate",
        filterOptions.SORT.directionUp
      );
    }
  }
  return items;
}

function filterBidRequests(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  items = filterItems(items, filterOptions);

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((bidRequest) =>
      commonUtils.stringContainsString(
        bidRequest.studyName,
        filterOptions.filterText
      )
    );
  }
  items = sortItems(items, filterOptions);
  return items;
}

function filterProjects(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  items = filterItems(items, filterOptions);
  items = excludetemsByStatus(items, "NEW");

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((task) =>
      commonUtils.stringContainsString(task.name, filterOptions.filterText)
    );
  }
  if (filterOptions && filterOptions.SORT) {
    items = commonUtils.sortArrayOfObjects(
      items,
      filterOptions.SORT.selected === "A-Z"
    );
  }
  return items;
}

function filterFeedbacks(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  if (filterOptions && filterOptions.SHOW) {
    if (filterOptions.SHOW.selected === "ACTIVE") {
      items = items.filter((item) => item.active);
    }
    if (filterOptions.SHOW.selected === "INACTIVE") {
      items = items.filter((item) => !item.active);
    }
  }

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((item) =>
      commonUtils.stringContainsString(item.name, filterOptions.filterText)
    );
  }
  if (filterOptions && filterOptions.SORT) {
    if (filterOptions.SORT.selected === "A-Z") {
      items = commonUtils.sortArrayOfObjects(
        items,
        filterOptions.SORT.directionUp
      );
    }
    if (filterOptions.SORT.selected === "CREATEDDATE") {
      items = commonUtils.sortArrayOfObjectsByDateField(
        items,
        "createdDate",
        filterOptions.SORT.directionUp
      );
    }
  }
  return items;
}

function excludetemsByStatus(items, status) {
  const currentUserId = commonUtils.getCurrentUserId();
  return items.filter(
    (item) =>
      item.status !== status ||
      (item.createdBy && item.createdBy === currentUserId)
  );
}

function filterTasks(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  items = filterItems(items, filterOptions);

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((project) =>
      commonUtils.stringContainsString(project.name, filterOptions.filterText)
    );
  }
  items = sortItems(items, filterOptions);
  return items;
}

function filterAccounts(items, filterOptions) {
  if (!items) {
    return items;
  }
  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((account) =>
      commonUtils.stringContainsString(account.name, filterOptions.filterText)
    );
  }
  return items;
}

function filterWorkItems(items, filterOptions) {
  //console.log(filterOptions)
  if (!items) {
    return items;
  }

  items = filterItems(items, filterOptions);

  if (
    filterOptions &&
    filterOptions.filterText &&
    filterOptions.filterText &&
    filterOptions.filterText.length > 2
  ) {
    items = items.filter((project) =>
      commonUtils.stringContainsString(project.name, filterOptions.filterText)
    );
  }
  items = sortItems(items, filterOptions);
  return items;
}

function filterText(items, filterText) {
  return (
    (items &&
      items.filter((item) =>
        commonUtils.stringContainsString(item.name, filterText)
      )) ||
    []
  );
}

function filterItems(items, filterOptions) {
  if (filterOptions && filterOptions.SHOW) {
    if (filterOptions.SHOW.selected === "NEW") {
      items = items.filter((item) => item.status === "NEW");
    }
    if (filterOptions.SHOW.selected === "ONGOING") {
      items = items.filter((item) => item.status !== "COMPLETED");
    }
    if (filterOptions.SHOW.selected === "COMPLETED") {
      items = items.filter(
        (item) => item.status === "COMPLETED" || item.status === "CLOSED"
      );
    }
    if (filterOptions.SHOW.selected === "INPROGRESS") {
      items = items.filter((item) => item.status === "INPROGRESS");
    }
    if (filterOptions.SHOW.selected === "ASSIGNED") {
      items = items.filter((item) => item.status === "ASSIGNED");
    }
    if (filterOptions.SHOW.selected === "WON") {
      items = items.filter((item) => item.status === "WON");
    }
    if (filterOptions.SHOW.selected === "LOST") {
      items = items.filter((item) => item.status === "LOST");
    }
    if (filterOptions.SHOW.selected === "CANCELLED") {
      items = items.filter((item) => item.status === "CANCELLED");
    }
    if (filterOptions.SHOW.selected === "REJECTED") {
      items = items.filter((item) => item.status === "REJECTED");
    }
    if (filterOptions.SHOW.selected === "CLOSED") {
      items = items.filter((item) => item.status === "CLOSED");
    }
    if (filterOptions.SHOW.selected === "MYASSIGNED") {
      items = items.filter(
        (item) =>
          item.taskOwner === getCurrentUserId() ||
          item.owner === getCurrentUserId() ||
          item.createdBy === getCurrentUserId() ||
          item.employeeId === getCurrentUserId()
      );
    }
    if (filterOptions.SHOW.selected === "MYPROJECTS") {
      items = items.filter(
        (item) =>
          item.taskOwner === getCurrentUserId() ||
          item.owner === getCurrentUserId() ||
          item.createdBy === getCurrentUserId() ||
          item.employeeId === getCurrentUserId()
      );
    }
    if (filterOptions.SHOW.selected === "ARCHIVED") {
      items = items.filter((item) => item.archived);
    }
    if (filterOptions.SHOW.selected === "ACTIVE") {
      items = items.filter((item) => !item.archived);
    }

    if (filterOptions.SHOW.selected === "DELETED") {
      items = items.filter((item) => item.deleted);
    }

    if (filterOptions.SHOW.selected === "FAVORITE") {
      items = items.filter((item) => item.favorite);
    }
  }
  return items;
}

function convertWorkItemsForCalendar(taskActivites, viewMode) {
  if (!taskActivites) {
    return [];
  }

  let newWorkItems = taskActivites.slice();
  if (viewMode === "PROJECT") {
    newWorkItems = newWorkItems.filter(
      (ele, ind) =>
        ind ===
        newWorkItems.findIndex((elem) => elem.projectId === ele.projectId)
    );
  } else if (viewMode === "DELIVERABLES") {
    newWorkItems = newWorkItems.filter(
      (ele, ind) =>
        ind === newWorkItems.findIndex((elem) => elem.taskId === ele.taskId)
    );
  }

  return (
    newWorkItems &&
    newWorkItems.map((value) => {
      let title = null;
      let start = null;
      let end = null;
      if (viewMode === "WORKITEMS") {
        start = new Date(
          value.actualEndTime ? value.actualStartTime : value.estimatedStartTime
        );
        let estDate = new Date(value.estimatedStartTime);
        title = value.name;
        end = new Date(
          value.actualEndTime
            ? value.actualEndTime
            : estDate.setTime(
                estDate.getTime() + value.durationInMinutes * 60 * 60 * 1000
              )
        );
      }
      if (viewMode === "PROJECT") {
        start = new Date(value.projectEstStartTime);
        end = new Date(value.projectEstCompletionTime);
        title = value.projectName;
      } else if (viewMode === "DELIVERABLES") {
        start = new Date(value.taskEstStartTime);
        end = new Date(value.taskEstCompletionTime);
        title = value.taskName;
      }
      let allDay = false;
      return {
        title: title,
        start: start,
        end: end,
        allDay: allDay,
        status: value.status,
      };
    })
  );
}

function convertHolidayLeaveForCalendar(holidayLeaves, viewMode) {
  if (!holidayLeaves) {
    return [];
  }

  return (
    holidayLeaves &&
    holidayLeaves.map((value) => {
      let start = new Date(value.holidayDate);
      let title = value.name;
      let end = new Date(value.holidayDate);
      let allDay = true;
      return {
        title: title,
        start: start,
        end: end,
        allDay: allDay,
        status: value.status,
      };
    })
  );
}

function filterEmployeesByTeams(allEmployees, teamIds) {
  //console.log("Filtering employees by userTeamId : " + teamIds)
  if (allEmployees && teamIds && teamIds.length > 0) {
    return commonUtils.sortArrayOfObjects(
      allEmployees.filter((employee) => teamIds.includes(employee.userTeamId)),
      true
    );
  }
  return allEmployees;
}

function filterSkillsByEmployee(allEmployees, skills) {
  //console.log("Filtering employees by sills : " + skills)
  let newSkills = [];
  if (allEmployees && skills && skills.length > 0) {
    allEmployees.map((employee) => {
      if (
        skills.includes(employee.primarySkill) &&
        !newSkills.includes(employee.primarySkill)
      ) {
        newSkills.push(employee.primarySkill);
      }
      if (employee.additionalSkills) {
        employee.additionalSkills.map((addtionalSkill) => {
          if (
            skills.includes(addtionalSkill) &&
            !newSkills.includes(addtionalSkill)
          ) {
            newSkills.push(addtionalSkill);
          }
        });
      }
      return employee;
    });
  }
  //console.log("Filtered employees by sills : " + newSkills)
  return newSkills;
}

function filterEmployeeBySkills(employees, skills) {
  if (employees && skills && skills.length > 0) {
    return employees.filter((employee) =>
      skills.includes(
        employee.primarySkill ||
          (employee.additionalSkills &&
            employee.additionalSkills.some((additionalSkill) =>
              skills.some((skill) => additionalSkill === skill)
            ))
      )
    );
  }
  return employees;
}

function getAccountOptions(accounts) {
  return (
    accounts &&
    sortArrayOfObjects(
      accounts.map((account) => {
        return { value: account.name, label: account.name, ...account };
      })
    )
  );
}

function getTeamOptions(accounts, selectedAccountName) {
  //console.log("getTeamOptions : " + selectedAccountName)
  let selectedAccount = getItemByNameAccount(accounts, selectedAccountName);
  return (
    selectedAccount &&
    selectedAccount.teams &&
    sortArrayOfObjects(
      selectedAccount.teams.map((team) => {
        return { value: team.name, label: team.name, ...team };
      })
    )
  );
}

function getItemByNameAccount(items, selectedName) {
  return (
    items && selectedName && items.find((item) => item.name === selectedName)
  );
}

function getClientsOptions(accounts, selectedAccountName, selectedTeamName) {
  let selectedAccount = getItemByNameAccount(accounts, selectedAccountName);
  let selectedTeam =
    selectedAccount &&
    getItemByNameAccount(selectedAccount.teams, selectedTeamName);
  return (
    selectedTeam &&
    selectedTeam.clients &&
    sortArrayOfObjects(
      selectedTeam.clients.map((client) => {
        return { value: client.name, label: client.name, ...client };
      })
    )
  );
}

function getUniqueElementsFromArray(array) {
  return array && array.filter((v, i, a) => a.indexOf(v) === i);
}

function getPercentageOfCompletion(totalCount, completedCount) {
  if (totalCount && totalCount >= 0 && completedCount && completedCount >= 0) {
    return Math.round((completedCount / totalCount) * 100);
  }
  return 0;
}

function encryptString(input, keyString) {
  let key =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz=+-&*^%$#@()";
  var c = "";
  var c = "";
  while (key.length < input.length) {
    key += key;
  }
  for (var i = 0; i < input.length; i++) {
    var value1 = input[i].charCodeAt(0);
    var value2 = key[i].charCodeAt(0);
    var xorValue = value1 ^ value2;
    var xorValueAsHexString = xorValue.toString("16");
    if (xorValueAsHexString.length < 2) {
      xorValueAsHexString = "0" + xorValueAsHexString;
    }

    c += xorValueAsHexString;
  }
  return c;
}

function decryptString(input, keyString) {
  let key =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz=+-&*^%$#@()";
  var c = "";
  while (key.length < input.length / 2) {
    key += key;
  }

  for (var i = 0; i < input.length; i = i + 2) {
    var hexValueString = input.substr(i, 2);
    var value1 = parseInt(hexValueString, 16);
    var value2 = key[i / 2].charCodeAt(0);
    var xorValue = value1 ^ value2;
    c += String.fromCharCode(xorValue);
  }
  return c;
}

function hasAccess(item, allowForEveryOne) {
  const currentItemUserTeamId = item.userTeamId;
  const restrictedByUserTeamIds = item.restrictedByUserTeamIds;
  const includedUserTeamIds = item.includedUserTeamIds;

  const currentUserTeamIds = getCurrentUserTeamIds();
  return (
    (currentUserTeamIds &&
      currentUserTeamIds.includes(currentItemUserTeamId)) ||
    (currentUserTeamIds &&
      restrictedByUserTeamIds &&
      restrictedByUserTeamIds.some((id) => currentUserTeamIds.includes(id))) ||
    (currentUserTeamIds &&
      includedUserTeamIds &&
      includedUserTeamIds.some((id) => currentUserTeamIds.includes(id))) ||
    allowForEveryOne
  );
}

function getSubRoles(roles, currentRole) {
  let filterdRoles = [];
  iterateNextedList(roles, currentRole, false, filterdRoles);
  return filterdRoles;
}
function iterateNextedList(roles, currentRole, isMatched, filterdRoles) {
  return roles.forEach((role) => {
    if (currentRole === role.id || isMatched) {
      filterdRoles.push({ name: role.name, id: role.id });
      if (role.nodes) {
        return iterateNextedList(role.nodes, currentRole, true, filterdRoles);
      }
      return;
    }
    return iterateNextedList(role.nodes, currentRole, false, filterdRoles);
  });
}
