import React, { Component } from "react";

class DecisionOptionSelectInput extends Component {
  onChange = (value) => {
    this.props.onChange({ ...this.props.questionConfig, answer: value });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6">
            <div className="inputField">
              <label className="labelTxt">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>
              <div className="row">
                <div className="radioField ml-2">
                  <input
                    type="radio"
                    className="radio"
                    name="recommends"
                    disabled={this.props.readonly}
                    onChange={(event) => this.onChange(true)}
                    checked={this.props.questionConfig.answer === true}
                  />
                  <label>Yes</label>
                </div>
                <div className="radioField p-0 ml-2">
                  <input
                    type="radio"
                    className="radio"
                    name="recommends"
                    disabled={this.props.readonly}
                    onChange={(event) => this.onChange(false)}
                    checked={this.props.questionConfig.answer === false}
                  />
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default DecisionOptionSelectInput;
