import React, { Component } from "react";

class SingleDecisionOptionSingleSelectInput extends Component {
  onChange = (value) => {
    this.props.onChange({ ...this.props.questionConfig, answer: value });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6">
            <div className="inputField">
              <div className="radioField m-0">
                <input
                  type="checkbox"
                  name="conditions"
                  onChange={(event) => this.onChange(event.target.checked)}
                  disabled={this.props.readonly}
                  checked={
                    this.props.questionConfig.answer &&
                    this.props.questionConfig.answer === true
                  }
                />
                <label>
                  {this.props.questionConfig.question}
                  {this.props.questionConfig.mandatory &&
                    this.props.questionConfig.mandatory === true}
                  <span className="colorred">*</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default SingleDecisionOptionSingleSelectInput;
