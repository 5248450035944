import React, { Component } from "react";

class TextInput extends Component {
  onChange = (value) => {
    this.props.onChange({ ...this.props.questionConfig, answer: value });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6">
            <div className="input_field">
              <label className="inputLabel">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>
              <input
                type={this.props.type || "text"}
                name="question"
                id="question"
                placeholder=""
                value={this.props.questionConfig.answer || ""}
                disabled={this.props.readonly}
                onChange={(event) => this.onChange(event.target.value)}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
export default TextInput;
