import React, { Component } from "react";

class MultiOptionMultiSelectInput extends Component {
  onChange = (isChecked, value) => {
    value = value.trim();
    var answer = this.props.questionConfig.answer || null;
    var answerList = answer ? answer.split(",") : [];
    if (isChecked === true) {
      answerList.push(value);
    } else {
      const index = answerList.indexOf(value);
      if (index > -1) {
        answerList.splice(index, 1);
      }
    }

    if (answerList.length > 0) {
      answer = answerList.join(",");
    } else {
      answer = null;
    }
    this.props.onChange({ ...this.props.questionConfig, answer: answer });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6">
            <div className="inputField">
              <label className="inputLabel">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>

              {this.props.questionConfig.questionOptions &&
                this.props.questionConfig.questionOptions
                  .split(",")
                  .map((value) => (
                    <div key={value} className="radioField">
                      <input
                        type="checkbox"
                        name="join"
                        onChange={(e) => this.onChange(e.target.checked, value)}
                        disabled={this.props.readonly}
                        checked={
                          this.props.questionConfig.answer &&
                          this.props.questionConfig.answer
                            .split(",")
                            .indexOf(value.trim()) > -1
                        }
                      />
                      <label>{value}</label>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </>
    );
  }
}
export default MultiOptionMultiSelectInput;
