import React, { Component } from "react";
import { connect } from "react-redux";
import { validationUtils } from "_helpers";
import QuestionaireTable from "../fragments/QuestionaireTable";
import { store } from "_helpers";
import { feedbackTemplateConstants } from "_constants";
import { commonUtils } from "_helpers";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { feedbackTemplateActions } from "_actions";
import { accessUtils } from "_helpers";
import { appConfigActions } from "_actions";
import ItemNotFoundSection from "../ItemsNotFoundSection";

class ConfigureFeedbackTemplateSidebar extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false,
      permission: accessUtils.getFeedbackTemplateAccessControl(),
    };
  }
  onCloseItemClick = () => {
    store.dispatch({
      type: feedbackTemplateConstants.CLOSE_FEEDBACK_TEMPLATE_CONFIGURE_WINDOW,
    });
  };

  onFormError = (fieldName, hasError) => {
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError,
      },
    }));
  };
  onResetFormError = () => {
    this.setState({
      hasFormError: false,
    });
  };

  handleSubmit = (feedbackTemplateRequest, submitCallBackFunction) => {
    let isValidForm = validationUtils.isValidRequest(
      this.props.fieldConfig,
      feedbackTemplateRequest,
      this.state.formErrors
    );
    isValidForm =
      feedbackTemplateRequest.questionDataList !== undefined &&
      feedbackTemplateRequest.questionDataList.length > 0;
    if (isValidForm === true) {
      feedbackTemplateRequest.questionDataList.forEach((question) => {
        if (question.question === "") {
          isValidForm = false;
        }
      });
    }
    if (isValidForm) {
      if (!feedbackTemplateRequest.id) {
        store.dispatch(
          this.props.createFeedbackTemplate(
            feedbackTemplateRequest,
            submitCallBackFunction
          )
        );
      } else {
        store.dispatch(
          this.props.updateFeedbackTemplate(
            feedbackTemplateRequest,
            submitCallBackFunction
          )
        );
      }
    } else {
      this.setState({
        hasFormError: true,
      });
    }
  };

  handleRemoveFeedbackTemplate = (feedbackTemplateId) => {
    store.dispatch(this.props.removeFeedbackTemplate(feedbackTemplateId));
  };
  updateItemFeedbackTemplateData = (feedbackTemplateData) => {
    store.dispatch(
      this.props.updateItemFeedbackTemplateData(
        feedbackTemplateData,
        this.props.selectedItemInfo.id,
        this.props.type
      )
    );
  };
  render() {
    return (
      <div
        id="configfeedbackTemplatesidebar"
        className={`right_sidebar config_feedbackTemplate_right_sidebar ${
          this.props.show === true ? "open" : ""
        }`}
      >
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">{"Configure Feedback Templates"}</h3>
            <div className="card-options">
              <button
                onClick={this.onCloseItemClick}
                className="link p-2 create-project-close-link float-right"
              >
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          {this.props.show === true && (
            <ConfigureFeedbackTemplateSidebarSection
              permission={this.state.permission}
              fieldConfig={this.props.fieldConfig}
              onFormError={this.onFormError}
              onResetFormError={this.onResetFormError}
              hasFormError={this.state.hasFormError}
              getAllFeedbackTemplates={this.props.getAllFeedbackTemplates}
              handleRemoveFeedbackTemplate={this.handleRemoveFeedbackTemplate}
              feedbackTemplateDataList={this.props.feedbackTemplateDataList}
              type={this.props.type}
              selectedItemInfo={this.props.selectedItemInfo}
              submitFeedbackTemplate={this.handleSubmit}
              allUserTeams={this.props.allUserTeams}
              typeOptions={this.props.typeOptions}
              feedbackTypeOptions={this.props.feedbackTypeOptions}
              feedbackSubTypeOptions={this.props.feedbackSubTypeOptions}
            />
          )}
        </div>
      </div>
    );
  }
}

class ConfigureFeedbackTemplateSidebarSection extends Component {
  constructor() {
    super();
    this.state = {
      feedbackTemplateRequest: {},
      questionConfigureMode: true,
      editMode: true,
      filterText: "",
    };
  }
  componentDidMount() {
    store.dispatch(
      this.props.getAllFeedbackTemplates({ itemType: this.props.type })
    );
    store.dispatch(appConfigActions.getAllTeams(false));
    this.setState({
      feedbackTemplateRequest: {
        userTeamId: commonUtils.getCurrentUserTeamId(),
        type: this.props.type,
      },
    });
  }

  addNewQuesiton = () => {
    let questionDataList =
      this.state.feedbackTemplateRequest.questionDataList || [];
    const newQuestion = {
      index: questionDataList.length,
      id: Date.now(),
      question: "",
      type: "boolean",
      mandatory: false,
    };
    questionDataList.push(newQuestion);
    this.setState({
      feedbackTemplateRequest: {
        ...this.state.feedbackTemplateRequest,
        questionDataList: questionDataList,
      },
    });
  };

  onFormError = (fieldName, hasError) => {
    this.props.onFormError(fieldName, hasError);
  };
  onResetFormError = () => {
    this.props.onResetFormError();
  };
  onCloseItemClick = () => {
    store.dispatch({
      type: feedbackTemplateConstants.CLOSE_CHECKLIST_CONFIGURE_WINDOW,
    });
  };

  onChange = (fieldName, value) => {
    this.onResetFormError();
    this.setState({
      feedbackTemplateRequest: {
        ...this.state.feedbackTemplateRequest,
        [fieldName]: value,
      },
    });
  };

  togglequestionConfigureMode = (questionConfigureMode) => {
    this.setState({ questionConfigureMode });
  };

  onRestFeedbackTemplate = () => {
    this.setState({
      feedbackTemplateRequest: {
        userTeamId: commonUtils.getCurrentUserTeamId(),
        type: this.props.type,
      },
      editMode: true,
    });
  };

  submitCallBackFunction = () => {
    this.setState({ feedbackTemplateRequest: { type: this.props.type } });
  };

  onSaveFeedbackTemplate = () => {
    this.props.submitFeedbackTemplate(
      this.state.feedbackTemplateRequest,
      this.submitCallBackFunction
    );
  };

  onEditFeedbackTemplate = (feedbackTemplateData) => {
    this.setState({
      feedbackTemplateRequest: feedbackTemplateData,
      editMode: true,
    });
    this.togglequestionConfigureMode(true);
  };
  onDuplicateFeedbackTemplate = (feedbackTemplateData) => {
    const { id, ...feedbackTemplateDataCopy } = feedbackTemplateData;
    this.setState({
      feedbackTemplateRequest: feedbackTemplateDataCopy,
      editMode: true,
    });
    this.togglequestionConfigureMode(true);
  };

  onSelectFeedbackTemplate = (feedbackTemplateData) => {
    this.setState({
      feedbackTemplateRequest: feedbackTemplateData,
      editMode: false,
    });
    this.togglequestionConfigureMode(false);
  };

  onFilterTextChange = (filterText) => {
    this.setState({ filterText });
  };

  getFeedbackTypeOptions = () => {
    if (this.props.type && this.props.feedbackTypeOptions) {
      return this.props.feedbackTypeOptions.filter(
        (type) => type.value === this.props.type
      );
    }
    return this.props.feedbackTypeOptions;
  };

  getFeedbackSubTypeOptions = () => {
    return this.props.feedbackSubTypeOptions;
  };

  render() {
    return (
      <div className="card-body pl-3 pr-3 overflow-auto">
        <div className="col-12">
          <div className="row">
            <div className="col-4 border-right border-left height-100-sidebar">
              <div className="card  ">
                <div className="card-header py-0 pr-3 pl-0 pb-2 border-bottom">
                  <div className="input-icon w-100p pr-3">
                    <span className="input-icon-addon">
                      <i className="fa fa-sort-amount-desc"></i>
                    </span>
                    <input
                      onChange={(event) => {
                        this.onFilterTextChange(event.target.value);
                      }}
                      type="text"
                      className="form-control input-sm h-20px"
                      placeholder="Filter..."
                      value={this.state.filterText}
                    />
                  </div>
                </div>
                <div className="card-body bgcolorwhite">
                  {this.props.feedbackTemplateDataList && (
                    <ul className="right_chat  p-0">
                      {commonUtils
                        .sortArrayOfObjects(
                          this.props.feedbackTemplateDataList,
                          true
                        )
                        .map((feedbackTemplateData) => {
                          return (
                            feedbackTemplateData &&
                            (this.state.filterText === "" ||
                              commonUtils.stringContainsString(
                                feedbackTemplateData.name,
                                this.state.filterText
                              )) && (
                              <FeedbackTemplateItem
                                key={feedbackTemplateData.id}
                                selectedFeedbackTemplateId={
                                  this.state.feedbackTemplateRequest.id
                                }
                                handleRemoveFeedbackTemplate={
                                  this.props.handleRemoveFeedbackTemplate
                                }
                                onEditFeedbackTemplate={
                                  this.onEditFeedbackTemplate
                                }
                                onDuplicateFeedbackTemplate={
                                  this.onDuplicateFeedbackTemplate
                                }
                                onSelectFeedbackTemplate={
                                  this.onSelectFeedbackTemplate
                                }
                                permission={this.props.permission}
                                feedbackTemplateData={feedbackTemplateData}
                              />
                            )
                          );
                        })}
                    </ul>
                  )}
                  <ItemNotFoundSection
                    items={this.props.feedbackTemplateDataList}
                    imgurl="no_feedback_template.png"
                    text={`Feedback Templates Not Added ?`}
                    description="Try Adding a Feedback Template..."
                  />
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="col-12">
                <div className=" row">
                  <div className="col-6">
                    <CustomInput
                      readOnly={this.props.readOnly}
                      type="select"
                      label="Type"
                      name="type"
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.feedbackTemplateRequest}
                      onChange={this.onChange}
                      placeholder="Type"
                      options={this.getFeedbackTypeOptions()}
                      onFormError={this.onFormError}
                    />
                  </div>
                  <div className="col-6">
                    <CustomInput
                      readOnly={this.props.readOnly}
                      type="select"
                      label="Sub Type"
                      name="subType"
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.feedbackTemplateRequest}
                      onChange={this.onChange}
                      placeholder="Sub Type"
                      options={this.getFeedbackSubTypeOptions()}
                      onFormError={this.onFormError}
                    />
                  </div>
                  <div className="col-6">
                    <CustomInput
                      inputtype="text"
                      label="Name"
                      name="name"
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.feedbackTemplateRequest}
                      onChange={this.onChange}
                      onFormError={this.onFormError}
                      placeholder="Name..."
                      datatype="alpnumspl"
                    />
                  </div>
                  <div className="col-6">
                    <CustomInput
                      label="Description"
                      name="description"
                      type="textarea"
                      height={"37px"}
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.feedbackTemplateRequest}
                      onChange={this.onChange}
                      onFormError={this.onFormError}
                      placeholder="Description..."
                      datatype="alpnumspl"
                    />
                  </div>
                </div>
                <div className=" row">
                  <div className="col-12">
                    <CustomInput
                      type="select"
                      label="Restricted for User Teams"
                      name="restrictedByUserTeamIds"
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.feedbackTemplateRequest}
                      onChange={this.onChange}
                      placeholder="User Team"
                      options={
                        this.props.allUserTeams &&
                        commonUtils.convertObjectToSelectObject(
                          this.props.allUserTeams
                        )
                      }
                      onFormError={this.onFormError}
                      multi={true}
                    />
                  </div>
                  {/* <div className="col-6">
                                    <CustomInput
                                        type='select'
                                        label="Default for User Teams"
                                        name="defaultForUserTeamIds"
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.feedbackTemplateRequest}
                                        onChange={this.onChange}
                                        placeholder="User Team"
                                        options={this.props.userTeams && commonUtils.convertObjectToSelectObject(this.props.userTeams)}
                                        onFormError={this.onFormError}
                                        multi={true}
                                    />
                                </div> */}
                </div>
              </div>

              <div className="col-12 py-1">
                <div className="card pb-0 mb-0 ">
                  <div className="card-header pt-0 pb-1 px-0 border-bottom">
                    <span className="text-muted fweight600">
                      <i className="fa fa-info-circle pr-1"></i>Questions
                    </span>
                    {this.state.editMode && (
                      <div className="card-options">
                        {this.state.questionConfigureMode && (
                          <button
                            className="link fsize14 pr-3"
                            onClick={this.addNewQuesiton}
                          >
                            <i className="fa fa-plus colorblue"></i>
                          </button>
                        )}

                        <button
                          className="link fsize14"
                          onClick={() =>
                            this.togglequestionConfigureMode(
                              !this.state.questionConfigureMode
                            )
                          }
                        >
                          {this.state.questionConfigureMode && (
                            <i className="fa fa-list-ul"></i>
                          )}
                          {!this.state.questionConfigureMode && (
                            <i className=" fa fa-navicon"></i>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <QuestionaireTable
                    onChange={this.onChange}
                    dragMode={true}
                    editMode={this.state.editMode}
                    questionConfigureMode={this.state.questionConfigureMode}
                    questionDataList={
                      this.state.feedbackTemplateRequest &&
                      this.state.feedbackTemplateRequest.questionDataList
                    }
                    typeOptions={this.props.typeOptions}
                  />
                </div>
              </div>
              {this.props.hasFormError && (
                <div className="col-12 py-1">
                  <div className="alert alert-danger alert-dismissible mb-0 py-1">
                    <button
                      type="button"
                      className="close py-1"
                      onClick={() => this.props.onResetFormError()}
                    ></button>
                    <strong>
                      <i className="fa fa-exclamation-triangle colorred"></i>
                    </strong>{" "}
                    Error : Please complete the mandatory fields before
                    Submitting!
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12 py-1">
                  {(this.props.permission.createEnabled ||
                    (this.state.feedbackTemplateRequest.id !== undefined &&
                      this.props.permission.editEnabled)) && (
                    <div className="float-right">
                      {this.state.editMode && (
                        <button
                          onClick={() => this.onSaveFeedbackTemplate()}
                          className="btn btn-primary btn-sm mr-3"
                        >
                          Save FeedbackTemplate
                        </button>
                      )}
                      <button
                        onClick={() => this.onRestFeedbackTemplate()}
                        className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal"
                      >
                        Reset
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class FeedbackTemplateItem extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false,
    };
  }
  handleRemoveFeedbackTemplate = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveFeedbackTemplate(
        this.props.feedbackTemplateData.id
      );
    } else {
      this.setState({
        confrimDelete: true,
      });
    }
  };
  render() {
    return (
      <li
        className={`p-1 ${
          this.props.selectedFeedbackTemplateId &&
          this.props.selectedFeedbackTemplateId ==
            this.props.feedbackTemplateData.id
            ? "colorblue fweight600"
            : ""
        }`}
        key={this.props.feedbackTemplateData.id}
      >
        <div className="d-flex justify-content-between align-items-center mt-0 pb-1 px-1">
          <span
            onClick={() =>
              this.props.onSelectFeedbackTemplate(
                this.props.feedbackTemplateData
              )
            }
            className="name"
          >
            {this.props.feedbackTemplateData.name}
          </span>
          <span>
            <a
              className=""
              target="_new"
              href={`/document/feedback/template/${this.props.feedbackTemplateData.id}`}
            >
              <i className="fa fa-eye pr-2" />
            </a>
            {this.props.permission.editEnabled === true && (
              <button
                className="link"
                onClick={() =>
                  this.props.onEditFeedbackTemplate(
                    this.props.feedbackTemplateData
                  )
                }
              >
                <i className="fa fa-edit pr-2" />
              </button>
            )}
            {this.props.permission.duplicateEnabled === true && (
              <button
                className="link"
                onClick={() =>
                  this.props.onDuplicateFeedbackTemplate(
                    this.props.feedbackTemplateData
                  )
                }
              >
                <i className="fa fa-clone pr-2" />
              </button>
            )}
            {this.props.permission.removeEnabled === true &&
              !(
                this.props.selectedFeedbackTemplateId &&
                this.props.selectedFeedbackTemplateId ==
                  this.props.feedbackTemplateData.id
              ) && (
                <button
                  type="button"
                  className="link"
                  title="Remove"
                  onClick={() =>
                    this.handleRemoveFeedbackTemplate(this.state.confrimDelete)
                  }
                >
                  {this.state.confrimDelete === true && (
                    <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
                  )}
                  {this.state.confrimDelete === false && (
                    <i className="dropdown-icon fa fa-times colorred"></i>
                  )}
                </button>
              )}
          </span>
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.authentication.user,
  allUserTeams: state.appconfig.allTeams,
  allEmployees: state.employees.allEmployees,
  feedbackTemplateDataList: state.feedbackTemplate.templateItems,
  fieldConfig: state.feedbackTemplate.feedbackTemplateFieldConfig,
  typeOptions: state.feedbackTemplate.typeOptions,
  feedbackTypeOptions: state.feedbackTemplate.feedbackTypeOptions,
  feedbackSubTypeOptions: state.feedbackTemplate.feedbackSubTypeOptions,
});

const mapDispatchToProps = (dispatch) => ({
  getAllFeedbackTemplates: feedbackTemplateActions.getAllFeedbackTemplates,
  createFeedbackTemplate: feedbackTemplateActions.createFeedbackTemplate,
  updateFeedbackTemplate: feedbackTemplateActions.updateFeedbackTemplate,
  removeFeedbackTemplate: feedbackTemplateActions.removeFeedbackTemplate,
  activateFeedbackTemplate: feedbackTemplateActions.activateFeedbackTemplate,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureFeedbackTemplateSidebar);
