import { feedbackRequestConstants } from "_constants";
import { commonActions } from "./common.actions";
import { notificationActions } from "./notification.actions";
import { feedbackRequestService } from "_services";

export const feedbackRequestActions = {
  getAllFeedbackRequests,
  getFeedbackRequest,
  getFeedback,
  createFeedbackRequest,
  updateFeedbackRequest,

  updateFeedbackRequestStatus,

  removeFeedbackRequest,
  remindFeedbackRequest,

  removeFeedback,
  activateFeedback,
  updateFeedback,
};

function getAllFeedbackRequests(requestObj) {
  //console.log(requestObj);
  return (dispatch) => {
    dispatch(request());
    feedbackRequestService.getAllFeedbackRequests(requestObj).then(
      (feedbackRequests) => {
        dispatch(success(feedbackRequests.payload));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: feedbackRequestConstants.GETALL_FEEDBACK_REQUEST_REQUEST };
  }
  function success(feedbackRequests) {
    return {
      type: feedbackRequestConstants.GETALL_FEEDBACK_REQUEST_SUCCESS,
      feedbackRequests,
    };
  }
  function failure(error) {
    return {
      type: feedbackRequestConstants.GETALL_FEEDBACK_REQUEST_FAILURE,
      error,
    };
  }
}

function createFeedbackRequest(feedbackRequestRequest, submitCallBackFunction) {
  return (dispatch) => {
    dispatch(request(feedbackRequestRequest));
    dispatch(notificationActions.clear());
    feedbackRequestService.createFeedbackRequest(feedbackRequestRequest).then(
      (taskResponse) => {
        dispatch(success(taskResponse.payload));
        submitCallBackFunction();
        dispatch(
          notificationActions.success("Feedback Request Creation successful")
        );
      },
      (error) => {
        dispatch(failure(commonActions.getErrorMessage(error)));
        dispatch(
          notificationActions.error(commonActions.getErrorMessage(error))
        );
      }
    );
  };

  function request(feedbackRequest) {
    return {
      type: feedbackRequestConstants.CREATE_FEEDBACK_REQUEST_REQUEST,
      feedbackRequest,
    };
  }
  function success(feedbackRequest) {
    return {
      type: feedbackRequestConstants.CREATE_FEEDBACK_REQUEST_SUCCESS,
      feedbackRequest,
    };
  }
  function failure(error) {
    return {
      type: feedbackRequestConstants.CREATE_FEEDBACK_REQUEST_FAILURE,
      error,
    };
  }
}

function updateFeedbackRequest(feedbackRequestRequest, submitCallBackFunction) {
  return (dispatch) => {
    feedbackRequestService.updateFeedbackRequest(feedbackRequestRequest).then(
      (feedbackRequestResponse) => {
        if (
          feedbackRequestResponse.hasOwnProperty("error") &&
          feedbackRequestResponse.error !== null
        ) {
          submitCallBackFunction(feedbackRequestResponse.error);
        } else {
          submitCallBackFunction(feedbackRequestResponse.payload);
        }
      },
      (error) => {}
    );
  };
}

function updateFeedback(feedbackRequest, submitCallBackFunction) {
  return (dispatch) => {
    dispatch(request(feedbackRequest));
    dispatch(notificationActions.clear());
    feedbackRequestService.updateFeedback(feedbackRequest).then(
      (taskResponse) => {
        dispatch(success(taskResponse.payload, feedbackRequest.id));
        submitCallBackFunction();
        dispatch(
          notificationActions.success("Feedback Request update successful")
        );
      },
      (error) => {
        dispatch(failure(commonActions.getErrorMessage(error)));
        dispatch(
          notificationActions.error(commonActions.getErrorMessage(error))
        );
      }
    );
  };

  function request(feedbackRequest) {
    return {
      type: feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_REQUEST,
      feedbackRequest,
    };
  }
  function success(feedbackRequest, feedbackId) {
    return {
      type: feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_SUCCESS,
      feedbackRequest,
      feedbackId,
    };
  }
  function failure(error) {
    return {
      type: feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_FAILURE,
      error,
    };
  }
}

function removeFeedbackRequest(feedbackId, feedbackRequestId) {
  return (dispatch) => {
    dispatch(request(feedbackRequestId));

    feedbackRequestService.removeFeedbackRequest(feedbackRequestId).then(
      (taskResponse) => dispatch(success(feedbackId, feedbackRequestId)),
      (error) => {
        dispatch(failure(feedbackRequestId, error.toString()));
        dispatch(notificationActions.error(error.toString()));
      }
    );
  };

  function request(feedbackRequestId) {
    return {
      type: feedbackRequestConstants.REMOVE_FEEDBACK_REQUEST_REQUEST,
      feedbackRequestId,
    };
  }
  function success(feedbackId, feedbackRequestId) {
    return {
      type: feedbackRequestConstants.REMOVE_FEEDBACK_REQUEST_SUCCESS,
      feedbackId,
      feedbackRequestId,
    };
  }
  function failure(feedbackRequestId, error) {
    return {
      type: feedbackRequestConstants.REMOVE_FEEDBACK_REQUEST_FAILURE,
      feedbackRequestId,
      error,
    };
  }
}

function getFeedbackRequest(feedbackRequestId, callbackFunction) {
  return (dispatch) => {
    dispatch(request(feedbackRequestId));

    feedbackRequestService.getFeedbackRequest(feedbackRequestId).then(
      (feedbackRequestResponse) => {
        if (
          feedbackRequestResponse.hasOwnProperty("error") &&
          feedbackRequestResponse.error !== null
        ) {
          callbackFunction(feedbackRequestResponse.error);
        } else {
          callbackFunction(feedbackRequestResponse.payload);
          dispatch(success(feedbackRequestId));
        }
      },
      (error) => {
        callbackFunction({ error });
        dispatch(failure(feedbackRequestId, error.toString()));
        dispatch(notificationActions.error(error.toString()));
      }
    );
  };

  function request(feedbackRequestId) {
    return {
      type: feedbackRequestConstants.GET_FEEDBACK_REQUEST_REQUEST,
      feedbackRequestId,
    };
  }
  function success(feedbackId, feedbackRequestId) {
    return {
      type: feedbackRequestConstants.GET_FEEDBACK_REQUEST_SUCCESS,
      feedbackId,
      feedbackRequestId,
    };
  }
  function failure(feedbackRequestId, error) {
    return {
      type: feedbackRequestConstants.GET_FEEDBACK_REQUEST_FAILURE,
      feedbackRequestId,
      error,
    };
  }
}

function getFeedback(feedbacktId, callbackFunction) {
  return (dispatch) => {
    dispatch(request(feedbacktId));

    feedbackRequestService.getFeedback(feedbacktId).then(
      (feedbackResponse) => {
        callbackFunction(feedbackResponse.payload);
        dispatch(success(feedbacktId));
      },
      (error) => {
        dispatch(failure(feedbacktId, error.toString()));
        dispatch(notificationActions.error(error.toString()));
      }
    );
  };

  function request(feedbackRequestId) {
    return {
      type: feedbackRequestConstants.GET_FEEDBACK_REQUEST,
      feedbackRequestId,
    };
  }
  function success(feedbackId, feedbackRequestId) {
    return {
      type: feedbackRequestConstants.GET_FEEDBACK_SUCCESS,
      feedbackId,
      feedbackRequestId,
    };
  }
  function failure(feedbackRequestId, error) {
    return {
      type: feedbackRequestConstants.GET_FEEDBACK_FAILURE,
      feedbackRequestId,
      error,
    };
  }
}

function remindFeedbackRequest(feedbackRequestId) {
  return (dispatch) => {
    dispatch(request(feedbackRequestId));

    feedbackRequestService.remindFeedbackRequest(feedbackRequestId).then(
      (feedbackRetriggerResponse) => {
        dispatch(success(feedbackRequestId));
        dispatch(notificationActions.success("Feedback Request Retrigged"));
      },
      (error) => {
        dispatch(failure(feedbackRequestId, error.toString()));
        dispatch(notificationActions.error(error.toString()));
      }
    );
  };

  function request(feedbackRequestId) {
    return {
      type: feedbackRequestConstants.REMIND_FEEDBACK_REQUEST_REQUEST,
      feedbackRequestId,
    };
  }
  function success(feedbackId, feedbackRequestId) {
    return {
      type: feedbackRequestConstants.REMIND_FEEDBACK_REQUEST_SUCCESS,
      feedbackId,
      feedbackRequestId,
    };
  }
  function failure(feedbackRequestId, error) {
    return {
      type: feedbackRequestConstants.REMIND_FEEDBACK_REQUEST_FAILURE,
      feedbackRequestId,
      error,
    };
  }
}

function updateFeedbackRequestStatus(
  feedbackRequestId,
  status,
  employeeId,
  expiryDate
) {
  return (dispatch) => {
    dispatch(request(feedbackRequestId));

    feedbackRequestService
      .updateFeedbackRequestStatus(
        feedbackRequestId,
        status,
        employeeId,
        expiryDate
      )
      .then(
        (feedbackRequestResponse) => {
          dispatch(
            notificationActions.success("Feedback Request status updated")
          );
          dispatch(success(feedbackRequestId, feedbackRequestResponse.payload));
        },
        (error) => {
          dispatch(failure(feedbackRequestId, error.toString()));
          dispatch(notificationActions.error(error.toString()));
        }
      );
  };

  function request(feedbackRequestId) {
    return {
      type: feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_STATUS_REQUEST,
      feedbackRequestId,
    };
  }
  function success(feedbackRequestId, feedbackRequestResponse) {
    return {
      type: feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_STATUS_SUCCESS,
      feedbackRequestId,
      feedbackRequestResponse,
    };
  }
  function failure(feedbackRequestId, error) {
    return {
      type: feedbackRequestConstants.UPDATE_FEEDBACK_REQUEST_STATUS_FAILURE,
      feedbackRequestId,
      error,
    };
  }
}

function removeFeedback(feedbackId) {
  return (dispatch) => {
    dispatch(request(feedbackId));

    feedbackRequestService.removeFeedback(feedbackId).then(
      (taskResponse) => dispatch(success(feedbackId)),
      (error) => {
        dispatch(failure(feedbackId, error.toString()));
        dispatch(notificationActions.error(error.toString()));
      }
    );
  };

  function request(feedbackId) {
    return {
      type: feedbackRequestConstants.REMOVE_FEEDBACK_REQUEST,
      feedbackId,
    };
  }
  function success(feedbackId) {
    return {
      type: feedbackRequestConstants.REMOVE_FEEDBACK_SUCCESS,
      feedbackId,
    };
  }
  function failure(feedbackId, error) {
    return {
      type: feedbackRequestConstants.REMOVE_FEEDBACK_FAILURE,
      feedbackId,
      error,
    };
  }
}

function activateFeedback(feedbackId, isActive) {
  return (dispatch) => {
    dispatch(request(feedbackId));

    feedbackRequestService.activateFeedback(feedbackId, isActive).then(
      (feedbackRequestResponse) => dispatch(success(feedbackId, isActive)),
      (error) => {
        dispatch(notificationActions.error(error.toString()));
      }
    );
  };

  function request(feedbackId) {
    return {
      type: feedbackRequestConstants.ACTIVATE_FEEDBACK_REQUEST,
      feedbackId,
    };
  }
  function success(feedbackId, isActive) {
    return {
      type: feedbackRequestConstants.ACTIVATE_FEEDBACK_SUCCESS,
      feedbackId,
      isActive,
    };
  }
}
