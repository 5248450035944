import config from "config";

let BASE_API_PATH = "/api/v1/";
let USER_API_PATH = BASE_API_PATH + "users";
let EMPLOYEE_API_PATH = BASE_API_PATH + "employees";
let BID_REQUEST_API_PATH = BASE_API_PATH + "bidrequest";
let PURCHASE_ORDER_API_PATH = BASE_API_PATH + "purchaseorder";
let PROJECT_API_PATH = BASE_API_PATH + "projects";
let TASK_API_PATH = BASE_API_PATH + "task";
let DOCS_API_PATH = "/docs";

let TASKBOARD_API_PATH = BASE_API_PATH + "taskboard";
let CHECKLIST_API_PATH = BASE_API_PATH + "checklist";
let FEEDBACK_API_PATH = BASE_API_PATH + "feedback";
let APPCONFIG_API_PATH = BASE_API_PATH + "app/config";
let NOTES_API_PATH = BASE_API_PATH + "notes";
let SCHEDULE_API_PATH = BASE_API_PATH + "schedule";
let HOLIDAYS_API_PATH = APPCONFIG_API_PATH + "/holiday";
let ACCOUNTS_API_PATH = BASE_API_PATH + "accounts";
let INVOICE_API_PATH = ACCOUNTS_API_PATH + "/invoice";
let INVOICE_DOC_API_PATH = DOCS_API_PATH + "/invoice";
let DASHBOARD_API_PATH = BASE_API_PATH + "dashboard";
let REPORT_BASE_PATH = "/report";

let TASK_ACTIVITY_API_PATH = TASK_API_PATH + "/activity";
let ITEM_ADDITIONAL_API_PATH = BASE_API_PATH + "additional/item";

const urlConfig = {
  USER_AUTHENTICATE: USER_API_PATH + "/authenticate",
  USER_LOGOUT: USER_API_PATH + "/logout",
  INITATE_ACCOUNT_ACTIVATION: USER_API_PATH + "/activation/request",
  VALIDATE_ACCOUNT_ACTIVATION: USER_API_PATH + "/validate/activation",
  CHANGE_PASSWORD: USER_API_PATH + "/change/password",
  UPDATE_PASSWORD: USER_API_PATH + "/update/password",

  USER_AUTH_REFRESH: EMPLOYEE_API_PATH + "/authrefresh",
  EMPLOYEE_GET_ALL: EMPLOYEE_API_PATH + "/all",
  EMPLOYEE_TEAMS_ALL: EMPLOYEE_API_PATH,
  EMPLOYEE_GET_INFO: EMPLOYEE_API_PATH,
  EMPLOYEE_CREATE: EMPLOYEE_API_PATH,
  EMPLOYEE_UPDATE: EMPLOYEE_API_PATH,
  EMPLOYEE_USER_ROLE_UPDATE: EMPLOYEE_API_PATH + "/roles",
  EMPLOYEE_DELETE: EMPLOYEE_API_PATH,
  EMPLOYEE_REMOVE: EMPLOYEE_API_PATH,
  EMPLOYEE_ACTIVATE: EMPLOYEE_API_PATH,

  GET_TEAM_ATTENDANCE: EMPLOYEE_API_PATH + "/attendance",
  MARK_ATTENDANCE: EMPLOYEE_API_PATH + "/attendance",
  DELETE_ATTENDANCE: EMPLOYEE_API_PATH + "/attendance",
  PURCHASE_ORDER_PATH: PURCHASE_ORDER_API_PATH,
  BID_REQUEST_PATH: BID_REQUEST_API_PATH,
  PROJECT_GET_ALL: PROJECT_API_PATH,
  PROJECT_GET_INFO: PROJECT_API_PATH,
  PROJECT_CREATE: PROJECT_API_PATH,
  PROJECT_UPDATE: PROJECT_API_PATH,
  PROJECT_DELETE: PROJECT_API_PATH,
  PROJECT_REMOVE: PROJECT_API_PATH,
  PROJECT_ARCHIVE: PROJECT_API_PATH,
  PROJECT_ADD_TO_FAVORITES: PROJECT_API_PATH + "/favorite",
  PROJECT_UPDATE_ESTIMATE: PROJECT_API_PATH + "/estimate",
  PROJECT_GET_TIMESHEET: PROJECT_API_PATH + "/timesheet",
  PROJECT_GET_PURCHASEORDER: PROJECT_API_PATH + "/purchaseorders",
  PROJECT_ACCOUNT_ALL_BY_USER_TEAM: ACCOUNTS_API_PATH + "/list",
  PROJECT_ACCOUNT_ALL: ACCOUNTS_API_PATH + "/list/all",
  PROJECT_ACCOUNT_SUB_BY_EXCLUDE_USER_TEAM: ACCOUNTS_API_PATH + "/sub",
  PROJECT_ACCOUNT_DETACH_FROM_TEAM: ACCOUNTS_API_PATH + "/detach",
  PROJECT_ACCOUNTS_API_PATH: ACCOUNTS_API_PATH,

  TASK_GET_ALL: TASK_API_PATH,
  TASK_RECURRABLE_GET_ALL: TASK_API_PATH + "/recurring",
  TASK_GET_INFO: TASK_API_PATH + "/info",
  TASK_CREATE: TASK_API_PATH,
  TASK_UPDATE: TASK_API_PATH,
  TASK_DELETE: TASK_API_PATH,
  TASK_REMOVE: TASK_API_PATH,
  TASK_ACTIVATE: TASK_API_PATH,
  TASK_TIMESHEET: TASK_API_PATH + "/timesheet",

  TASKBOARD: TASKBOARD_API_PATH,
  TASK_WORK_ITEM_PATH: TASK_ACTIVITY_API_PATH,
  TASK_WORK_ITEMS_GET_ALL: TASK_ACTIVITY_API_PATH + "/list",
  TASK_WORK_ITEMS_GET_INFO: TASK_ACTIVITY_API_PATH,
  TASK_WORK_ITEMS_CREATE: TASK_ACTIVITY_API_PATH,
  TASK_WORK_ITEMS_UPDATE: TASK_ACTIVITY_API_PATH,
  TASK_WORK_ITEMS_UPDATE_STATUS: TASK_ACTIVITY_API_PATH + "/status",
  TASK_WORK_ITEMS_DELETE: TASK_ACTIVITY_API_PATH,
  TASK_WORK_ITEMS_REMOVE: TASK_ACTIVITY_API_PATH,

  TASK_WORK_ITEMS_PROJECT_GET_ALL: TASK_ACTIVITY_API_PATH + "/project/list",
  TASK_WORK_ITEMS_BOARD_GET_ALL: TASK_ACTIVITY_API_PATH + "/board/list",
  TASK_WORK_ITEMS_PENDING_GET_ALL: TASK_ACTIVITY_API_PATH + "/list/pending",
  TASK_WORK_ITEMS_COMPLETED_GET_ALL: TASK_ACTIVITY_API_PATH + "/list/completed",
  TASK_WORK_ITEMS_CALEDAR_GET_ALL: TASK_ACTIVITY_API_PATH + "/calendar/list",
  WORK_ITEMS_GET_ALL: TASK_ACTIVITY_API_PATH + "/all/list",

  APPCONFIG_INITIALIZE: APPCONFIG_API_PATH + "/initialize",
  APPCONFIG_CREATE: APPCONFIG_API_PATH,
  APPCONFIG_UPDATE: APPCONFIG_API_PATH,
  APPCONFIG_GET: APPCONFIG_API_PATH,
  APPCONFIG_PATH: APPCONFIG_API_PATH,
  APPCONFIG_UPDATE_PROPERTY: APPCONFIG_API_PATH,
  APPCONFIG_GET_MODULE: APPCONFIG_API_PATH + "/module",
  HOLIDAY_PATH: HOLIDAYS_API_PATH,
  APPCONFIG_GET_ALL_TEAMS: APPCONFIG_API_PATH + "/allteams",
  APPCONFIG_GET_ALL_ROLES: APPCONFIG_API_PATH + "/alluserroles",

  NOTES_CREATE: NOTES_API_PATH,
  NOTES_UPDATE: NOTES_API_PATH,
  NOTES_DELETE: NOTES_API_PATH,
  NOTES_LIST: NOTES_API_PATH,

  SCHEDULE_PATH: SCHEDULE_API_PATH,
  SCHEDULE_CREATE: SCHEDULE_API_PATH,
  SCHEDULE_UPDATE: SCHEDULE_API_PATH,
  SCHEDULE_REMOVE: SCHEDULE_API_PATH,
  SCHEDULE_LIST: SCHEDULE_API_PATH,

  ACCOUNTS_CREATE: ACCOUNTS_API_PATH,
  ACCOUNTS_UPDATE: ACCOUNTS_API_PATH,
  ACCOUNTS_DELETE: ACCOUNTS_API_PATH,
  ACCOUNTS_LIST: ACCOUNTS_API_PATH,

  INVOICE_PATH: INVOICE_API_PATH,
  DOCS_INVOICE_PATH: INVOICE_DOC_API_PATH,

  ITEM_ADDITIONAL_PATH: ITEM_ADDITIONAL_API_PATH,

  REPORT_VIEW: REPORT_BASE_PATH + "/view",
  REPORT_DOWNLOAD:
    config.BACK_END_API_ENDPOINT + REPORT_BASE_PATH + "/download",

  DASHBOARD_API_PATH: DASHBOARD_API_PATH,
  DASHBOARD_PROJECT_STAT_BY_CAT: DASHBOARD_API_PATH + "/project/statistics",
  DASHBOARD_TASK_STAT_BY_CAT: DASHBOARD_API_PATH + "/task/statistics",
  DASHBOARD_WORKITEM_STAT_BY_CAT: DASHBOARD_API_PATH + "/workitem/statistics",
  CHECKLIST: CHECKLIST_API_PATH,
  FEEDBACK: FEEDBACK_API_PATH,
  FEEDBACK_TEMPLATE: FEEDBACK_API_PATH + "/template",

  witelistUrls: ["/document/feedback/request/"],
  witelistApis: ["/api/v1/feedback/request"],
};

export default urlConfig;
