import React, { Component } from "react";
import Select from "react-select";

class QuestionaireTable extends Component {
  onQuestionChange = (index, question) => {
    this.props.questionDataList[index] = question;
    this.props.onChange("questionDataList", this.props.questionDataList);
  };

  onRemoveQuestion = (questionId) => {
    let questionDataList =
      this.props.questionDataList.filter(
        (question) => question.id !== questionId
      ) || [];
    questionDataList = questionDataList.map((question, index) => {
      question.index = index;
      return question;
    });
    this.props.onChange("questionDataList", questionDataList);
  };
  onMoveUp = (index) => {
    let questionDataList = this.props.questionDataList;
    questionDataList[index].index = index - 1;
    questionDataList[index - 1].index = index;
    this.props.onChange("questionDataList", questionDataList);
  };
  onMoveDown = (index) => {
    let questionDataList = this.props.questionDataList;
    questionDataList[index + 1].index = index;
    questionDataList[index].index = index + 1;
    this.props.onChange("questionDataList", questionDataList);
  };

  render() {
    return (
      <div className="col-12 px-0">
        {this.props.questionDataList &&
          this.props.questionDataList
            .sort((a, b) => a.index - b.index)
            .map((question, index) => (
              <Question
                typeOptions={this.props.typeOptions}
                index={index}
                size={this.props.questionDataList.length}
                readOnly={this.props.readOnly}
                dragMode={this.props.dragMode}
                editMode={this.props.editMode}
                questionConfigureMode={this.props.questionConfigureMode}
                key={question.id}
                question={question}
                onQuestionChange={this.onQuestionChange}
                onRemoveQuestion={this.onRemoveQuestion}
                onMoveUp={this.onMoveUp}
                onMoveDown={this.onMoveDown}
              />
            ))}
      </div>
    );
  }
}

class Question extends Component {
  constructor() {
    super();
    this.state = {
      draggable: false,
    };
  }
  toggeleDragable = (draggable) => {
    this.setState({
      draggable,
    });
  };
  onQuestionChange = (name, value) => {
    var isMulti = false;
    var value = { ...this.props.question, [name]: value };
    if (name === "type") {
      isMulti =
        this.props.typeOptions.find(
          (type) =>
            type.value === this.props.question.type && type.multi === false
        ) || false;
      if (isMulti === false) {
        value = { ...value, questionOptions: null };
      }
    }
    this.props.onQuestionChange(this.props.index, value);
  };

  render() {
    return (
      <div className="d-flex justify-content-between  mt-0 pt-2 px-1 border-bottom pb-1">
        <div className="bid-question-div d-flex align-items-left">
          {this.props.dragMode && this.props.editMode && (
            <span className="mr-2 w-8">
              {this.props.index !== 0 && (
                <button
                  className="link d-block"
                  onClick={() => this.props.onMoveUp(this.props.question.index)}
                >
                  <i className="fa fa-caret-up colorblue"></i>
                </button>
              )}
              {this.props.index !== this.props.size - 1 && (
                <button
                  className="link"
                  onClick={() =>
                    this.props.onMoveDown(this.props.question.index)
                  }
                >
                  <i className="fa fa-caret-down colorblue"></i>
                </button>
              )}
            </span>
          )}

          {!this.props.questionConfigureMode && (
            <>
              <span className="bid-question fa mt-1 "></span>
              <span className="mx-w-500">
                {this.props.question.question}
                {this.props.question.mandatory && (
                  <span className="form-required">*</span>
                )}
              </span>
            </>
          )}
          {this.props.questionConfigureMode && (
            <div>
              <input
                type="text"
                className="form-control input-sm height25 w-350"
                value={this.props.question.question || ""}
                onChange={(event) =>
                  this.onQuestionChange("question", event.target.value)
                }
              />
            </div>
          )}
        </div>
        {this.props.questionConfigureMode && (
          <div>
            <div className="d-flex">
              <span className="w-200">
                <Select
                  isSearchable={true}
                  isClearable={false}
                  placeholder={"Select Type"}
                  classNamePrefix={"custom-select"}
                  isMulti={false}
                  menuPlacement={"auto"}
                  value={this.props.typeOptions.find(
                    (type) => type.value === this.props.question.type
                  )}
                  onChange={(selectedOption) => {
                    this.onQuestionChange("type", selectedOption.value);
                  }}
                  options={this.props.typeOptions}
                />
              </span>
              <div className="selectgroup ml-3">
                <label className="selectgroup-item">
                  <input
                    type="checkbox"
                    className="selectgroup-input"
                    title="Is Mandatory?"
                    value={this.props.question.mandatory || false}
                    checked={this.props.question.mandatory || false}
                    onChange={(event) =>
                      this.onQuestionChange("mandatory", event.target.checked)
                    }
                  />
                  <span className="selectgroup-button mr-1 py-0 px-1 fsize11">
                    Required
                  </span>
                </label>
                <label className="selectgroup-item">
                  <input
                    type="checkbox"
                    className="selectgroup-input"
                    title="Is Comments Enabled?"
                    value={this.props.question.hasComments || false}
                    checked={this.props.question.hasComments || false}
                    onChange={(event) =>
                      this.onQuestionChange("hasComments", event.target.checked)
                    }
                  />
                  <span className="selectgroup-button mr-1 py-0 px-1 fsize11">
                    Comments
                  </span>
                </label>
              </div>

              <span className="ml-3">
                <button
                  className="link"
                  onClick={() =>
                    this.props.onRemoveQuestion(this.props.question.id)
                  }
                >
                  <i className="fa fa-times colorred"></i>
                </button>
              </span>
            </div>
            {this.props.question &&
              this.props.question.type &&
              this.props.typeOptions.find(
                (type) =>
                  type.value === this.props.question.type && type.multi === true
              ) && (
                <div>
                  <span className="fsize11">Options (Comma seperated)</span>
                  <input
                    type="text"
                    className="form-control input-sm height25 w-350"
                    value={this.props.question.questionOptions || ""}
                    onChange={(event) =>
                      this.onQuestionChange(
                        "questionOptions",
                        event.target.value
                      )
                    }
                  />
                </div>
              )}
          </div>
        )}

        {!this.props.readOnly && !this.props.questionConfigureMode && (
          <>
            <div>
              {this.props.question.type === "string" && (
                <input
                  type="text"
                  className="form-control input-sm height25"
                  value={this.props.question.answer || ""}
                  placeholder="Answer"
                  onChange={(event) =>
                    this.onQuestionChange("answer", event.target.value)
                  }
                />
              )}
              {this.props.question.type === "number" && (
                <input
                  type="number"
                  className="form-control input-sm height25"
                  value={this.props.question.answer || ""}
                  placeholder="Answer"
                  onChange={(event) =>
                    this.onQuestionChange("answer", event.target.value)
                  }
                />
              )}
              {this.props.question.type === "boolean" && (
                <div className="custom-controls-stacked mt-2">
                  <label className="custom-control custom-radio custom-control-inline pb-0 mb-0">
                    <input
                      type="radio"
                      className={`custom-control-input`}
                      name={this.props.name}
                      value={true}
                      checked={
                        this.props.question.answer !== undefined &&
                        this.props.question.answer === true
                          ? true
                          : false
                      }
                      onChange={(event) =>
                        this.onQuestionChange("answer", true)
                      }
                    />
                    <div className="custom-control-label">Yes</div>
                  </label>
                  <label className="custom-control custom-radio custom-control-inline pb-0 mb-0">
                    <input
                      type="radio"
                      className={`custom-control-input`}
                      name={this.props.name}
                      value={false}
                      checked={
                        this.props.question.answer !== undefined &&
                        this.props.question.answer === false
                          ? true
                          : false
                      }
                      onChange={(event) =>
                        this.onQuestionChange("answer", false)
                      }
                    />
                    <div className="custom-control-label">No</div>
                  </label>
                </div>
              )}
              {this.props.question.hasComments && (
                <div>
                  <input
                    type="text"
                    className="form-control input-sm height25 mt-1"
                    value={this.props.question.comments || ""}
                    placeholder="Comments"
                    onChange={(event) =>
                      this.onQuestionChange("comments", event.target.value)
                    }
                  />
                </div>
              )}
            </div>
          </>
        )}

        {this.props.readOnly && !this.props.questionConfigureMode && (
          <span>
            {this.props.question.type === "boolean"
              ? this.props.question.answer === "false"
                ? "No"
                : "Yes"
              : this.props.question.answer || "NA"}
            {this.props.question.comments && (
              <span className="fsize11 ml-1">
                ({this.props.question.comments || ""})
              </span>
            )}
          </span>
        )}
      </div>
    );
  }
}

export default QuestionaireTable;
