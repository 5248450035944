import React, { Component } from "react";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import ProjectEstimateInput from "./fragments/ProjectEstimateInput";
import RecurringSelectionWindow from "./modal/RecurringSelectionWindow";
import ItemCommentsSection from "./fragments/ItemCommentsSection";
import ItemPropertyInfo from "./fragments/ItemPropertyInfo";
import SelectInput from "views/common/fragments/formfields/SelectInput";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { projectActions } from "_actions";
import { store } from "_helpers";
import ConfigureChecklistSidebar from "./modal/ConfigureChecklistSidebar";
import ItemChecklistInput from "./fragments/ItemChecklistInput";
import { checklistActions } from "_actions";
import ProjectServiceLineView from "./fragments/ProjectServiceLineView";
import ItemStatistics from "./fragments/ItemStatistics";

class ProjectDetailsGeneral extends Component {
    handleUpdateStatus = (projectId, status) => {
        store.dispatch(this.props.updateProjectStatus(projectId, status));
    }

    handleUpdateChecklist = (checklistData) => {
        store.dispatch(this.props.updateItemChecklistData(checklistData, this.props.item.id, "PROJECT"));
    }

    render() {
        return (
            <>
                {this.props.item &&
                    <div className="col-lg-12 col-md-12 px-0">
                        <div className="row">
                        <div className="col-lg-9 col-md-9 px-0">
                            <div className="card h-100 mb-0 pl-2">
                                <div className="card-header pt-1 pl-0  pb-2 border-bottom">
                                    <span>
                                        <label className="d-block fsize14 colorlightgreen">{this.props.item.ownerUserTeamDetails.name} / {this.props.item.itemIdUserTeamPrefix}</label>
                                        <span className="card-title text-normal-case fsize20 ">
                                            <i className="fa fa-wpforms fa-lg mr-1 colorlightgreen"></i>
                                            <span className="">[{this.props.item.code}] </span>- {this.props.item.name}
                                        </span>
                                    </span>
                                    <div className="card-options">
                                        <SelectInput
                                            options={[
                                                { value: "NEW", label: "New", level: 0 },
                                                { value: "ASSIGNED", label: "Assigned", level: 1 },
                                                { value: "INPROGRESS", label: "In Progress", level: 2 },
                                                { value: "COMPLETED", label: "Completed", level: 3 },
                                                { value: "CLOSED", label: "Closed", level: 4 }]}
                                            value={this.props.item.status}
                                            allowLevelDown={this.props.permission.allowStatusLevelDown}
                                            changeableStatus={this.props.permission.changeableStatus}
                                            isReadOnly={(!this.props.permission.allowStatusChange) || this.props.readOnly}
                                            itemId={this.props.item.id}
                                            onChange={this.handleUpdateStatus}
                                            className={classNames("tag",
                                                { "tag-info": this.props.item.status === 'NEW' },
                                                { "tag-primary": this.props.item.status === 'ASSIGNED' },
                                                { "tag-warning": this.props.item.status === 'INPROGRESS' },
                                                { "tag-success": this.props.item.status === 'COMPLETED' },
                                                { "tag-dark": this.props.item.status === 'CLOSED' }
                                            )} />
                                        {!this.props.readOnly &&
                                            <div className="item-action dropdown ml-2 show">
                                                <button className="link" data-toggle="dropdown" aria-expanded="true"><i className="fe fe-more-vertical"></i></button>
                                                <div className="dropdown-menu dropdown-menu-right dropdownrightmenu" x-placement="top-end">
                                                    <button className="dropdown-item link"><i className="dropdown-icon fa fa-cloud-download"></i> Download</button>
                                                    <div className="dropdown-divider"></div>
                                                    <button className="dropdown-item link"><i className="dropdown-icon fa fa-edit"></i> Edit</button>
                                                    <button className="dropdown-item link"><i className="dropdown-icon fa fa-paste"></i> Archive</button>
                                                    <button className="dropdown-item link"><i className="dropdown-icon fa fa-trash"></i> Delete</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="card-body pt-3 pb-1">
                                    <div className="row">
                                        <div className="col-8 py-0 justify-content-between flex-column d-flex">
                                            <div className="top">
                                                <span className="subHeading">Description</span>
                                                <p>{this.props.item.description || 'No description availabel'} </p>

                                                <div className="row">



                                                    <ItemPropertyInfo
                                                        label="Estimated Hours"
                                                        value={this.props.item.estHours || 'NA'}
                                                    />

                                                    <ItemPropertyInfo
                                                        label="Estimated Budget"
                                                        value={this.props.item.estCost || 'NA'}
                                                    />

                                                    <ItemPropertyInfo
                                                        label="Estimated Hourly Rate"
                                                        value={this.props.item.estHourlyRate || 'NA'}
                                                    />

                                                    <ItemPropertyInfo
                                                        label="Project Category"
                                                        value={this.props.item.category || 'NA'}
                                                    />
                                                    <div className="form-group  col-4">
                                                        <label className="form-label text-muted">Proposal No</label>
                                                        <i className="fe fe-file-minus pr-1" />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.proposalNumber || 'NA'}</span>
                                                    </div>
                                                    <div className="form-group  col-4">
                                                        <label className="form-label text-muted">PO Number</label>
                                                        <i className="fe fe-target pr-1" />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.purchaseOrderNumber || 'NA'}</span>
                                                    </div>

                                                    <div className="form-group  col-4">
                                                        <label className="form-label text-muted">Order Book Ref No</label>
                                                        <i className="fe fe-bookmark " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.orderBookRef || 'NA'}</span>
                                                    </div>

                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Project Type</label>
                                                        <i className="fe fe-book-open pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.projectType}</span>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Location</label>
                                                        <i className="fe fe-layers pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.location}</span>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Account</label>
                                                        <i className="fe fe-layers pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.accountName}</span>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Team</label>
                                                        <i className="fe fe-layers pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.teamName}</span>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Clients</label>
                                                        <i className="fe fe-layers pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.clientNames && this.props.item.clientNames.join(',')}</span>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Client Partner</label>
                                                        <i className="fa fa-handshake-o pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.clientPartner}</span>
                                                    </div>
                                                    <ItemPropertyInfo
                                                        label="Capex/Opex"
                                                        value={this.props.item.capexOpex || 'NA'}
                                                    />

                                                    <ItemPropertyInfo
                                                        label="Product Name"
                                                        value={this.props.item.productName || 'NA'}
                                                    />

                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Has Order Changed?</label>
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.isOrderChanged === true ? 'Yes' : 'No'}</span>
                                                    </div>

                                                    {this.props.item.isOrderChanged === true &&
                                                        <>
                                                            <ItemPropertyInfo
                                                                label="Revised Additional Hours"
                                                                value={this.props.item.revisedAdditionalHrs || 'NA'}
                                                            />
                                                            <ItemPropertyInfo
                                                                label="Revised Additional Cost"
                                                                value={this.props.item.revisedAdditionalCost || 'NA'}
                                                            />
                                                        </>
                                                    }


                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Complexity</label>
                                                        <i className="fe fe-layers pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.complexity || 'NA'}</span>
                                                    </div>

                                                    <div className="form-group col-4">
                                                        <label className="form-label text-muted">Priority</label>
                                                        <i className="fe fe-layers pr-1 " />
                                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.priority}</span>
                                                    </div>


                                                </div>
                                            </div>
                                            {this.props.permission.viewServiceLineEstimate &&
                                                <ProjectServiceLineView
                                                    item={this.props.item}
                                                    permission={this.props.permission}
                                                />
                                            }
                                        </div>
                                        <div className="col-4 pl-3">
                                            <div className="form-group mb-1">
                                                <label className="text-muted fsize13 w150">Project Lead:</label>
                                                <span className="fsize13 fweight600 menu-text-black">{(this.props.item.ownerDetails && this.props.item.ownerDetails.name) || 'NA'}</span>
                                            </div>

                                            <div className="form-group mb-1 ">
                                                <label className="text-muted fsize13 w150">Start Date:</label>
                                                <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateFormat(this.props.item.estStartTime)}</span>
                                            </div>

                                            <div className="form-group  mb-1">
                                                <label className="text-muted fsize13 w150">Due to:</label>
                                                <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateFormat(this.props.item.estCompletionTime)}</span>
                                            </div>

                                            <div className="form-group  mb-1">
                                                <label className="text-muted fsize13 w150">Created Date:</label>
                                                <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateTimeFormat(this.props.item.createdDate)}</span>
                                            </div>

                                            {this.props.item.lastModifiedDate &&
                                                <div className="form-group  mb-1">
                                                    <label className="text-muted fsize13 w150">Updated Date:</label>
                                                    <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateTimeFormat(this.props.item.lastModifiedDate)}</span>
                                                </div>
                                            }
                                            {this.props.item.createdByDetails &&
                                                <div className="form-group  mb-1">
                                                    <label className="text-muted fsize13 w150">Created By:</label>
                                                    <span className="fsize13 fweight600 menu-text-black">{this.props.item.createdByDetails.name}</span>
                                                </div>
                                            }
                                            {this.props.item.updatedByDetails &&
                                                <div className="form-group  mb-1">
                                                    <label className="text-muted fsize13 w150">Updated By:</label>
                                                    <span className="fsize13 fweight600 menu-text-black">{this.props.item.updatedByDetails.name}</span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 sidemenu border-left px-0">
                            <ItemStatistics
                                label="Deliverable Statistics"
                                items={this.props.taskStatisticsByCategory}
                                loadData={this.props.getTaskStatisticsByCategory}
                                itemId={this.props.itemId}
                            />
                        </div>
                        </div>
                        <div>
                            {!this.props.readOnly &&
                                <>
                                    <div className="col-12 px-0">
                                        <RecurringSelectionWindow
                                            userTeamId={this.props.item.ownerUserTeamDetails.id}
                                            itemId={this.props.item.id}
                                            type={"PROJECT"}
                                            scheduleType={"ITEM_RECURRING"}
                                            readOnly={this.props.readOnly}
                                        />
                                    </div>
                                    {(this.props.item.restrictedByUserTeamDetails || this.props.item.restrictedByEmployeeSkills || this.props.item.restrictedByEmployeeDetails) &&
                                        <div className="col-12 px-0">
                                            <span className="subHeading col-12 section-banner">
                                                <button className="link pr-2" ><i className="fa fa-chevron-down"></i></button>
                                                Access Control
                                            </span>
                                            <div className="col-12 row">
                                                {this.props.item.restrictedByUserTeamDetails && this.props.item.restrictedByUserTeamDetails.length > 0 &&
                                                    <div className="form-group col-3">
                                                        <label className="form-label text-muted">Restricted By Teams</label>
                                                        {this.props.item.restrictedByUserTeamDetails.map(userTeamDetails =>
                                                            <p className="fsize13 fweight600 menu-text-black mb-1" key={userTeamDetails.id}>
                                                                {userTeamDetails.name}
                                                            </p>
                                                        )}
                                                    </div>
                                                }
                                                {this.props.item.restrictedByEmployeeSkills && this.props.item.restrictedByEmployeeSkills.length > 0 &&
                                                    <div className="form-group col-3">
                                                        <label className="form-label text-muted">Restricted By Skills</label>
                                                        {this.props.item.restrictedByEmployeeSkills.map(skill =>
                                                            <p className="fsize13 fweight600 menu-text-black mb-1" key={skill}>
                                                                {skill}
                                                            </p>
                                                        )}
                                                    </div>
                                                }
                                                {this.props.item.restrictedByEmployeeDetails && this.props.item.restrictedByEmployeeDetails.length > 0 &&
                                                    <div className="form-group col-3">
                                                        <label className="form-label text-muted">Restricted By Employees</label>
                                                        {this.props.item.restrictedByEmployeeDetails.map(employeeDetails =>
                                                            <p className="fsize13 fweight600 menu-text-black mb-1" key={employeeDetails.id}>
                                                                {employeeDetails.name}
                                                            </p>
                                                        )}

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-12 px-0">
                                        <ProjectEstimateInput
                                            projectEstimateRequest={this.props.projectEstimateRequest}
                                            onChange={this.props.onEstimateChange}
                                            handleUpdateEstimate={this.props.handleUpdateEstimate}
                                            projectInfo={this.props.item}
                                            name={"teamEstimate"}
                                            readOnly={this.props.readOnly}
                                            projectItem={this.props.item}
                                            userTeams={(this.props.item.restrictedByUserTeamDetails && this.props.item.restrictedByUserTeamDetails.length > 0) ? this.props.item.restrictedByUserTeamDetails : commonUtils.getUserTeamsFromUserItem()}
                                        />
                                    </div>
                                </>
                            }
                            {this.props.permission.checklistEnabled &&
                                <ItemChecklistInput
                                    handleUpdateChecklist={this.handleUpdateChecklist}
                                    type={"PROJECT"}
                                    readOnly={this.props.readOnly}
                                    permission={this.props.permission}
                                    checklistData={this.props.item.checklistData}
                                    itemUserTeamId={this.props.item.userTeamId}
                                />
                            }
                        </div>
                        <ItemCommentsSection
                            itemId={this.props.item.id}
                            subType={"PROJECT"}
                            isReadOnly={this.props.readOnly}
                        />
                        <ConfigureChecklistSidebar
                            type={"PROJECT"}
                            show={this.props.showChecklistModel}
                            showConfigWindow={this.props.showChecklistModel}
                            showChecklistQuestionsWindow={false}
                        />
                    </div >

                }
            </>
        );
    }
}
const mapStateToProps = state => ({
    showChecklistModel: state.checklist.isShowConfigureChecklistSidebar
});
const mapDispatchToProps = dispatch => ({
    updateProjectStatus: projectActions.updateProjectStatus,
    updateItemChecklistData: checklistActions.updateItemChecklistData
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsGeneral);
