import React, { Component } from "react";

class MultiOptionSingleSelectInput extends Component {
  onChange = (isChecked, value) => {
    value = value.trim();
    if (isChecked === false) {
      value = null;
    }
    this.props.onChange({ ...this.props.questionConfig, answer: value });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-6">
            <div className="inputField">
              <label className="inputLabel">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>

              {this.props.questionConfig.questionOptions &&
                this.props.questionConfig.questionOptions
                  .split(",")
                  .map((value) => (
                    <div key={value} className="radioField">
                      <input
                        type="radio"
                        name="join"
                        className="radio"
                        onChange={(e) => this.onChange(e.target.checked, value)}
                        disabled={this.props.readonly}
                        checked={
                          this.props.questionConfig.answer &&
                          this.props.questionConfig.answer === value.trim()
                        }
                      />
                      <label>{value.trim()}</label>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </>
    );
  }
}
export default MultiOptionSingleSelectInput;
