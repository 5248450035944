import React, { Component } from "react";

class OverAllRating extends Component {
  constructor() {
    super();
    this.state = {
      selectableStars: 0,
      colorList: [
        "",
        "colorred",
        "colororange",
        "colorblue",
        "colorlightgreen1",
        "colorgreen",
      ],
    };
  }

  getSelectedStars = () => {
    return (this.props.questionConfig && this.props.questionConfig.answer) || 0;
  };

  onMouseOver = (stars) => {
    if (this.props.readonly === false) {
      this.setState({
        selectableStars: stars,
      });
    }
  };

  onMouseOut = () => {
    if (this.props.readonly === false) {
      this.setState({
        selectableStars: 0,
      });
    }
  };

  onClick = (stars) => {
    if (this.props.readonly === false) {
      this.props.onChange({ ...this.props.questionConfig, answer: stars });
    }
  };

  render() {
    var n = 5;
    const numbers = Array.from({ length: n }, (_, i) => i + 1);
    const styles = {
      colorred: {
        color: "red",
      },
    };
    return (
      <>
        {this.props.questionConfig && (
          <div className="row">
            <div className="col-md-12 starRatingSec">
              <h4 className="mainHeading">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </h4>
              <div className="starRating">
                {numbers.map((number) => (
                  <i
                    key={number}
                    className={
                      `fa fa-solid fa-star ` +
                      ((number <= this.state.selectableStars
                        ? this.state.colorList[this.state.selectableStars]
                        : "") ||
                        (this.state.selectableStars === 0 &&
                        this.getSelectedStars() !== 0 &&
                        number <= this.getSelectedStars()
                          ? this.state.colorList[this.getSelectedStars()]
                          : ""))
                    }
                    onMouseOver={() => this.onMouseOver(number)}
                    onMouseLeave={() => this.onMouseOut()}
                    onClick={() => this.onClick(number)}
                  ></i>
                ))}
              </div>
              <span>Click to rate</span>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default OverAllRating;
