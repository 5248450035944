import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { employeeActions } from "_actions";
import { validationUtils } from "_helpers";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import { store } from "_helpers";
import workItemUtils from "_helpers/workitem.utils";
class ProjectEstimateInput extends Component {
    constructor() {
        super();
        this.state = {
            formErrors: {},
            showAddTeamOption: false,
            selectedTeamOption: null,
            editable: false,
            permission: {
                //readOnlyMyTeam
                //ReadonlyOnlyOtherTeams
                //showOnlyMyTeamEstimate,
                //showAlLTeamEstimate
                //Show my additional user teams estimate
                //editOnlyMyTeamEstimate,
                //editAllTeamsEstimate,
                //edit my additional TeamsEstimate,
                //Remove my team estimate
                //remove other team estimate
                //remove my additional teams esitmate
            }
        }
    }

    componentDidMount() {
        this.setState({
            projectEstimateRequest: this.props.projectEstimateRequest ? this.props.projectEstimateRequest : {
                // [commonUtils.getCurrentUserTeamId()]: { "teamName": commonUtils.getCurrentTeamName(), "estimates": {} }
            }
        })
    }

    onAddTeamsMonthlyEstimate = (teamId, teamEstimate) => {
        this.setState((prevState) =>
        ({
            projectEstimateRequest: {
                ...prevState.projectEstimateRequest,
                [teamId]: {
                    ...teamEstimate
                }
            }
        }), () => {
            //console.log(this.state.projectEstimateRequest);
            this.props.onChange(this.props.name, this.state.projectEstimateRequest);
        });

    }

    handleUpdateEstimate = () => {
        this.props.handleUpdateEstimate();
        this.toggleEditOption(false);
    }
    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }

    toggleAddTeamOption = (isShow) => {
        this.setState({
            showAddTeamOption: isShow
        })
    }
    toggleEditOption = (isEdit) => {
        this.setState({
            editable: isEdit
        })
    }
    onChangeTeamSelection = (selectedTeamOption) => {
        this.setState({
            selectedTeamOption: selectedTeamOption
        })
    }
    addTeamForEstimate = () => {
        if (this.state.selectedTeamOption) {
            this.onAddTeamsMonthlyEstimate(this.state.selectedTeamOption.value, { "teamName": this.state.selectedTeamOption.label, "estimates": {} });
        }
        this.setState({
            selectedTeamOption: null
        })
    }

    removeTeamFromEstimate = (teamIdToBeDeleted) => {
        //console.log(teamIdToBeDeleted)
        let projectEstimateRequestNew = { ...this.state.projectEstimateRequest };
        //console.log(projectEstimateRequestNew[teamIdToBeDeleted])
        delete projectEstimateRequestNew[teamIdToBeDeleted];
        //console.log(projectEstimateRequestNew)
        this.setState((prevState) =>
        ({
            projectEstimateRequest: projectEstimateRequestNew
        }), () => {
            //console.log(this.state.projectEstimateRequestNew);
            this.props.onChange(this.props.name, this.state.projectEstimateRequest);
        });

    }
    render() {
        return (
            <div className="card pb-0 mb-0">
                <span className="subHeading col-12 section-banner">
                    <button className="link pr-2" ><i className="fa fa-chevron-down"></i></button>
                    Project Estimates
                    {!this.state.editable && !this.props.readOnly &&
                        < button onClick={() => this.toggleEditOption(!this.state.editable)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                            Edit
                        </button>
                    }
                </span>

                {
                    this.props.projectEstimateRequest && Object.keys(this.props.projectEstimateRequest).map(teamId =>

                        <ProjectEstimateTeam
                            key={teamId}
                            editable={this.state.editable}
                            projectInfo={this.props.projectInfo}
                            teamProjectEstimate={this.props.projectEstimateRequest[teamId]}
                            teamId={teamId}
                            onAddTeamEstimate={this.onAddTeamsMonthlyEstimate}
                            removeTeamFromEstimate={this.removeTeamFromEstimate}
                            employeeAppConfig={this.props.employeeAppConfig}
                            estimateTypeOptions={this.props.estimateTypeOptions}
                            projectItem={this.props.projectItem}
                        />

                    )
                }
                <hr />
                {this.state.editable &&
                    <div className="col-12">
                        {!this.state.showAddTeamOption && this.props.userTeams && (!this.props.projectEstimateRequest || (this.props.userTeams.filter(userTeam => !this.props.projectEstimateRequest[userTeam.id]).length > 0)) &&

                            <span>
                                <button onClick={() => this.toggleAddTeamOption(!this.state.showAddTeamOption)} className="create-link link pt-0 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                    <i className="fe fe-plus-circle mr-1"></i>Add a Team for Estimation
                                </button>
                            </span>


                        }
                        {this.state.showAddTeamOption &&
                            <div className="row py-2 addEstimateForm">

                                <div className=" col-3">
                                    <div className="form-group">
                                        <label className="form-label">Select Team</label>

                                        <Select
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder={"Select Team"}
                                            classNamePrefix={'custom-select'}
                                            isMulti={false}
                                            menuPlacement={'auto'}
                                            value={this.state.selectedTeamOption}
                                            onChange={(selectedOption) => this.onChangeTeamSelection(selectedOption)}
                                            options={commonUtils.convertObjectToSelectObject((this.props.projectEstimateRequest && this.props.userTeams.filter(userTeam => !this.props.projectEstimateRequest[userTeam.id])) || this.props.userTeams)}
                                        />
                                    </div>
                                </div>
                                <div className=" col-12">
                                    <button onClick={() => this.addTeamForEstimate()} className="plainbutton text-upper border fweight600 fsize13">
                                        Add
                                    </button>
                                    <button onClick={() => this.toggleAddTeamOption(!this.state.showAddTeamOption)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }

                {this.state.editable &&

                    <div className=" col-12">
                        <hr />
                        <button onClick={() => this.handleUpdateEstimate()} className="btn btn-primary btn-sm mr-3">
                            Update Estimates
                        </button>
                        <button onClick={() => this.toggleEditOption(false)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                            Cancel
                        </button>
                    </div>
                }
            </div >
        );
    }
}

class ProjectEstimateTeam extends Component {
    constructor() {
        super();
        this.state = {
            showAddMonthlyEstimateOption: false,
            selectedMonth: null,
            selectedEstimateTypeOption: null,
            selectedEstimateNameOption: null,
            selectedEstimatedHours: null,
            selectedPrimaryPracticeEstimateNameOption: null
        }
    }
    componentDidMount() {

        this.setState({
            teamProjectEstimate: this.props.teamProjectEstimate
        })
    }
    toggleShowAddMonthlyEstimateOption = (isShow) => {

        if (isShow === true && !this.props.employeeAppConfig) {
            store.dispatch(employeeActions.getModuleConfig(this.props.teamId));
        }

        this.setState({
            showAddMonthlyEstimateOption: isShow
        })
    }
    onAddMonthlyEstimateEstimate = (teamId, month, estimateType, estimateName, estimateHours) => {

        this.setState((prevState) =>
        ({
            teamProjectEstimate: {
                ...prevState.teamProjectEstimate,
                "estimates": {
                    ...prevState.teamProjectEstimate.estimates || {},
                    [month]: {
                        ...prevState.teamProjectEstimate.estimates[month] || {},
                        [estimateType]: {
                            ...(prevState.teamProjectEstimate.estimates[month] && prevState.teamProjectEstimate.estimates[month][estimateType]) || {},
                            [estimateName]: estimateHours
                        }
                    }
                },
                "estimateTypes": commonUtils.getUniqueElementsFromArray([...prevState.teamProjectEstimate.estimateTypes || [], estimateType])
            }
        }), () => {
            this.props.onAddTeamEstimate(teamId, this.state.teamProjectEstimate);
        });
    }

    onChangeMonthSelection = (selectedMonthOption) => {
        this.setState({
            selectedMonth: selectedMonthOption
        })
    }

    onChangsEstimateNameSelection = (estimateNameOption) => {
        this.setState({
            selectedEstimateNameOption: estimateNameOption
        })
    }

    onChangsPrimaryPracticeSelection = (estimateNameOption) => {
        this.setState({
            selectedPrimaryPracticeEstimateNameOption: estimateNameOption
        })
    }

    onChangeEstimatedHoursSelection = (label, value) => {
        if (validationUtils.validateHourDecimal(value)) {
            this.setState((prevState) =>
            ({
                selectedEstimatedHours: {
                    ...prevState.selectedEstimatedHours,
                    [label]: value
                }
            }))
        }
    }
    onChangeEstimateTypeSelection = (estimateTypeOption) => {
        this.setState({
            selectedEstimateTypeOption: estimateTypeOption,
            selectedEstimateNameOption: null
        })
    }



    addMonthlyToTeamEstimate = () => {
        var estimationName = (this.state.selectedEstimateNameOption && this.state.selectedEstimateNameOption.value) || "Hours";
        var estimationType = (this.state.selectedEstimateTypeOption && this.state.selectedEstimateTypeOption.value) || this.props.estimateTypeOptions[0].value;
        if (estimationType === 'PRACTICE') {
            var primaryPracticeName = (this.state.selectedPrimaryPracticeEstimateNameOption && this.state.selectedPrimaryPracticeEstimateNameOption.value) || "";
            estimationName = primaryPracticeName + '#' + estimationName;
        }
        if (this.state.selectedMonth && this.state.selectedMonth.label && this.state.selectedEstimatedHours && Object.values(this.state.selectedEstimatedHours).every(values => values !== "")) {
            this.onAddMonthlyEstimateEstimate(this.props.teamId, this.state.selectedMonth.value, estimationType, estimationName, this.state.selectedEstimatedHours)
            //this.toggleShowAddMonthlyEstimateOption(false)
        }
        this.setState({
            //selectedMonth: null,
            //selectedEstimateNameOption: null,
            //selectedEstimateTypeOption : null,
            //selectedEstimatedHours: null
        })
    }

    getMonthRangeOption = () => {
        let estStartTime = this.props.projectInfo.estStartTime;
        let estCompletionTime = this.props.projectInfo.estCompletionTime;

        let monthYearArray = dateUtils.getMonthYearArray(estStartTime, estCompletionTime);
        return monthYearArray.map(monthYear => { return ({ value: monthYear, label: monthYear }) })

    }
    getRestrictedEmployees = () => {
        let employees = this.props.projectInfo.restrictedByEmployeeDetails || this.props.allEmployees;
        let restrictedUserTeams = this.props.projectInfo.restrictedByUserTeamIds || (this.props.userTeams && this.props.userTeams.map(userTeam => userTeam.id));
        if (employees && restrictedUserTeams && restrictedUserTeams.length > 0) {
            employees = commonUtils.filterEmployeesByTeams(employees, restrictedUserTeams)
        }
        return employees;
    }

    getRestrictedBySkillsOptions = () => {
        let configObject = this.props.employeeAppConfig.find(config => config.name === "skills");
        let restrictedBySkills = this.props.projectInfo.restrictedByEmployeeSkills || (configObject && configObject.value);
        let employees = this.getRestrictedEmployees();

        if (employees && employees.length > 0 && restrictedBySkills) {
            restrictedBySkills = commonUtils.filterSkillsByEmployee(employees, restrictedBySkills)
        }

        return commonUtils.convertConfigListToSelectObject(restrictedBySkills);
    }

    getEmployeeDesignationsOptions = () => {
        let configObject = this.props.employeeAppConfig.find(config => config.name === "designation");
        return configObject && commonUtils.convertConfigListToSelectObject(configObject.value);
    }


    removeMonthFromEstimate = (monthToBeDeleted) => {
        //console.log(monthToBeDeleted)
        let teamProjectEstimateNew = { ...this.state.teamProjectEstimate };
        delete teamProjectEstimateNew.estimates[monthToBeDeleted];
        //console.log(teamProjectEstimateNew)
        this.setState((prevState) =>
        ({
            teamProjectEstimate: teamProjectEstimateNew
        }), () => {
            //console.log("this.state.teamProjectEstimate")
            //console.log(this.state.teamProjectEstimate)
            this.props.onAddTeamEstimate(this.props.teamId, this.state.teamProjectEstimate);
        });

    }

    getEstimateTotal = (estimateType, estimateHourType) => {
        let total = 0;
        this.props.teamProjectEstimate.estimates && this.getMonthRangeOption().map((monthYearValue, index) => {
            let monthYear = monthYearValue.value;
            this.props.teamProjectEstimate.estimates[monthYear] && this.props.teamProjectEstimate.estimates[monthYear][estimateType] && Object.keys(this.props.teamProjectEstimate.estimates[monthYear][estimateType]).map(estimateName => {
                total += parseFloat(this.props.teamProjectEstimate.estimates[monthYear][estimateType][estimateName][estimateHourType] || 0);
            })
        })
        return total.toFixed(2);
    }

    render() {
        let estimateTypes = this.props.teamProjectEstimate.estimateTypes || []

        return (
            <div className="border-bottom pb-2">
                <table className="mb-0 table table-nowrap table-borderless position-relative">
                    <tbody>
                        <tr className="timesheet-inputs border-top">
                            <td colSpan={2}>
                                <i className="fa fa-bookmark colorblue"></i> <span className="fsize12 fweight600"> <i>{this.props.teamProjectEstimate.teamName}</i></span>
                                {this.props.editable &&
                                    <button onClick={() => this.props.removeTeamFromEstimate(this.props.teamId)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal"><i className="fa fa-close colorred"></i></button>
                                }
                            </td>
                        </tr>
                        <tr className="timesheet-inputs border-bottom">
                            <td className="py-0 fsize13 fweight600" width="10%">Month</td>
                            {estimateTypes.map(estimateType =>
                                <td className="py-0 fsize11 fweight600" width="22.5%" key={estimateType}>{estimateType}</td>
                            )}
                            <td className="actions-section">
                            </td>
                        </tr>
                        {this.props.teamProjectEstimate.estimates && this.getMonthRangeOption().map((monthYearValue, index) => {
                            let monthYear = monthYearValue.value;
                            return (
                                <>
                                    {this.props.teamProjectEstimate.estimates[monthYear] &&

                                        <tr className="timesheet-inputs border-bottom" key={monthYear}>
                                            <td className="py-0 text-muted align-middle">{monthYear}</td>

                                            {estimateTypes.map(estimateType =>

                                                <td key={monthYear + estimateType} >
                                                    {this.props.projectItem.automationEnabled && index === 0 &&
                                                        <div className="col-12 row ml-0 pl-0">
                                                            <div className="col-6 ml-0 pl-0">

                                                            </div>
                                                            <div className="col-2  small m-0 p-0 fweight600 ">
                                                                With Tool
                                                            </div>
                                                            <div className="col-2  small m-0 p-0 fweight600">
                                                                W/o Tool
                                                            </div>
                                                            {this.props.projectItem.revisedEstEnabled && index === 0 &&
                                                                <div className="col-2  small m-0 p-0 fweight600">
                                                                    Revised
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {this.props.teamProjectEstimate.estimates[monthYear] && this.props.teamProjectEstimate.estimates[monthYear][estimateType] && Object.keys(this.props.teamProjectEstimate.estimates[monthYear][estimateType]).map(estimateName =>

                                                        <div className="col-12 row ml-0 pl-0" key={monthYear + estimateName + estimateType}>
                                                            <div className="col-6 ml-0 pl-0">
                                                                {estimateName}
                                                            </div>
                                                            <div className="col-2 m-0 p-0 text-center">
                                                                {this.props.teamProjectEstimate.estimates[monthYear][estimateType][estimateName].estimate}
                                                            </div>
                                                            {this.props.projectItem.automationEnabled &&
                                                                <div className="col-2 m-0 p-0 text-center">
                                                                    {this.props.teamProjectEstimate.estimates[monthYear][estimateType][estimateName].estimateAutomation}
                                                                </div>
                                                            }
                                                            {this.props.projectItem.revisedEstEnabled &&
                                                                <div className="col-2 m-0 p-0 text-center">
                                                                    {this.props.teamProjectEstimate.estimates[monthYear][estimateType][estimateName].estimateRevised}
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                </td>
                                            )
                                            }

                                            <td className="actions-section">
                                                {this.props.editable &&
                                                    <button onClick={() => this.removeMonthFromEstimate(monthYear)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal"><i className="fa fa-close colorred"></i></button>
                                                }
                                            </td>
                                        </tr>
                                    }
                                </>
                            )
                        })
                        }
                        <tr className="timesheet-inputs border-bottom bgcolorlightblue_1">
                            <td className="py-0 text-muted align-middle fsize13 fweight600" width="10%">Total</td>
                            {estimateTypes.map(estimateType =>
                                <td className="py-0 align-middle fsize13 fweight600" key={estimateType}>
                                    <div className="col-12 row ml-0 pl-0" >
                                        <div className="col-6 ml-0 pl-0">

                                        </div>
                                        <div className="col-2 m-0 p-0 text-center">
                                            {this.getEstimateTotal(estimateType, "estimate")}
                                        </div>
                                        {this.props.projectItem.automationEnabled &&
                                            <div className="col-2 m-0 p-0 text-center">
                                                {this.getEstimateTotal(estimateType, "estimateAutomation")}
                                            </div>
                                        }
                                        {this.props.projectItem.revisedEstEnabled &&
                                            <div className="col-2 m-0 p-0 text-center">
                                                {this.getEstimateTotal(estimateType, "estimateRevised")}
                                            </div>
                                        }
                                    </div>
                                </td>
                            )}
                            <td className="actions-section">
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.props.editable &&
                    <div className=" col-12">
                        {!this.state.showAddMonthlyEstimateOption &&

                            <span>
                                <button onClick={() => this.toggleShowAddMonthlyEstimateOption(!this.state.showAddMonthlyEstimateOption)} className="create-link link pt-0 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                    <i className="fe fe-plus-circle mr-1"></i>Add Estimation for {this.props.teamProjectEstimate.teamName}
                                </button>
                            </span>


                        }
                        {this.state.showAddMonthlyEstimateOption &&
                            <div className="row py-2 addEstimateForm">

                                <div className=" col-2">
                                    <div className="form-group">
                                        <label className="form-label">Select Month</label>
                                        <Select
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder={"Select Month"}
                                            classNamePrefix={'custom-select'}
                                            isMulti={false}
                                            menuPlacement={'auto'}
                                            value={this.state.selectedMonth}
                                            onChange={(selectedOption) => this.onChangeMonthSelection(selectedOption)}

                                            options={this.getMonthRangeOption()}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label className="form-label">Estimate Type</label>
                                        <Select
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder={"Estimate Type"}
                                            classNamePrefix={'custom-select'}
                                            isMulti={false}
                                            menuPlacement={'auto'}
                                            value={this.state.selectedEstimateTypeOption || this.props.estimateTypeOptions[0]}
                                            onChange={(selectedOption) => this.onChangeEstimateTypeSelection(selectedOption)}
                                            options={this.props.estimateTypeOptions}
                                        />
                                    </div>
                                </div>


                                {this.props.employeeAppConfig && this.state.selectedEstimateTypeOption && this.state.selectedEstimateTypeOption.value === 'SKILL' &&
                                    <div className=" col-3">
                                        <div className="form-group">
                                            <label className="form-label">Select Skill</label>
                                            <Select
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder={"Select Skill"}
                                                classNamePrefix={'custom-select'}
                                                isMulti={false}
                                                menuPlacement={'auto'}
                                                value={this.state.selectedEstimateNameOption}
                                                onChange={(selectedOption) => this.onChangsEstimateNameSelection(selectedOption)}
                                                options={this.getRestrictedBySkillsOptions()}
                                            />
                                        </div>
                                    </div>
                                }

                                {this.props.employeeAppConfig && this.state.selectedEstimateTypeOption && this.state.selectedEstimateTypeOption.value === 'PRACTICE' &&

                                    <div className=" col-3">
                                        <div className="form-group">
                                            <label className="form-label">Select Primary Practice</label>
                                            <Select
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder={"Select Primary Practice"}
                                                classNamePrefix={'custom-select'}
                                                isMulti={false}
                                                menuPlacement={'auto'}
                                                value={this.state.selectedPrimaryPracticeEstimateNameOption}
                                                onChange={(selectedOption) => this.onChangsPrimaryPracticeSelection(selectedOption)}
                                                options={workItemUtils.getEmployeePrimaryPractice(this.props.employeeAppConfig)}
                                            />
                                        </div>
                                        {this.state.selectedPrimaryPracticeEstimateNameOption && this.state.selectedPrimaryPracticeEstimateNameOption.value &&
                                            <div className="form-group">
                                                <label className="form-label">Select Secondary Practice</label>
                                                <Select
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder={"Select Secondary Practice"}
                                                    classNamePrefix={'custom-select'}
                                                    isMulti={false}
                                                    menuPlacement={'auto'}
                                                    value={this.state.selectedEstimateNameOption}
                                                    onChange={(selectedOption) => this.onChangsEstimateNameSelection(selectedOption)}
                                                    options={workItemUtils.getEmployeeSecondaryPractice(this.props.employeeAppConfig, this.state.selectedPrimaryPracticeEstimateNameOption.value)}
                                                />
                                            </div>
                                        }
                                    </div>


                                }


                                {this.props.employeeAppConfig && this.state.selectedEstimateTypeOption && this.state.selectedEstimateTypeOption.value === 'DESIGNATION' &&
                                    <div className=" col-3">
                                        <div className="form-group">
                                            <label className="form-label">Select Designation</label>
                                            <Select
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder={"Select Designation"}
                                                classNamePrefix={'custom-select'}
                                                isMulti={false}
                                                menuPlacement={'auto'}
                                                value={this.state.selectedEstimateNameOption}
                                                onChange={(selectedOption) => this.onChangsEstimateNameSelection(selectedOption)}
                                                options={this.getEmployeeDesignationsOptions()}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className=" col-2">
                                    <div className="form-group">
                                        <label className="form-label"> {this.props.projectItem.automationEnabled ? "Est Hrs With tool" : "Estimate"}</label>
                                        <input type="text" onChange={(e) => { this.onChangeEstimatedHoursSelection("estimate", e.target.value) }} className="form-control" value={(this.state.selectedEstimatedHours && this.state.selectedEstimatedHours.estimate) || ''} />
                                    </div>
                                </div>
                                {this.props.projectItem.automationEnabled &&
                                    <div className=" col-2">
                                        <div className="form-group">
                                            <label className="form-label">Est Hrs Without tool</label>
                                            <input type="text" onChange={(e) => { this.onChangeEstimatedHoursSelection("estimateAutomation", e.target.value) }} className="form-control" value={(this.state.selectedEstimatedHours && this.state.selectedEstimatedHours.estimateAutomation) || ''} />
                                        </div>
                                    </div>
                                }
                                {this.props.projectItem.revisedEstEnabled &&
                                    <div className=" col-2">
                                        <div className="form-group">
                                            <label className="form-label">Revised Estimate</label>
                                            <input type="text" onChange={(e) => { this.onChangeEstimatedHoursSelection("estimateRevised", e.target.value) }} className="form-control" value={(this.state.selectedEstimatedHours && this.state.selectedEstimatedHours.estimateRevised) || ''} />
                                        </div>
                                    </div>
                                }
                                <div className=" col-6">
                                    <button onClick={() => this.addMonthlyToTeamEstimate()} className="plainbutton text-upper border fweight600 fsize13">
                                        Add
                                    </button>
                                    <button onClick={() => this.toggleShowAddMonthlyEstimateOption(false)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div >);
    }
}


const mapStateToProps = state => ({
    employeeAppConfig: state.employees.createConfig,
    estimateTypeOptions: state.projects.estimateTypeOptions
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectEstimateInput);

