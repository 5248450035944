import React, { Component } from "react";
import ProjectDetailsHeader from "./components/ProjectDetailsHeader";
import ProjectDetailsGeneral from "./components/ProjectDetailsGeneral";
import ProjectDetailsCalendar from "./components/ProjectDetailsCalendar";
import ProjectDetailsActivity from "./components/ProjectDetailsActivity";
import ProjectDetailsMembers from "./components/ProjectDetailsMembers";
import ProjectDetailsNotification from "./components/ProjectDetailsNotification";
import ProjectDetailsFiles from "./components/ProjectDetailsFiles";
import { connect } from "react-redux";
import { projectActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";

import { dashboardActions } from "_actions";
import { accessUtils } from "_helpers";
import FeedbackPageSection from "./components/FeedbackPageSection";


export const ProjectDetailViewNameConst = Object.freeze({
  GENERAL: Symbol("general"),
  ACTIVITY: Symbol("activity"),
  CALENDAR: Symbol("calendar"),
  MEMBERS: Symbol("members"),
  NOTIFICATION: Symbol("notification"),
  FILES: Symbol("files"),
  FEEDBACK: Symbol("feedback")
});



class ProjectDetailsPage extends Component {
  constructor() {
    super()
    this.state = {
      projectDetailViewName: ProjectDetailViewNameConst.GENERAL,
      permission: accessUtils.getProjectAccessControl()
    };

  }
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name + " : " + this.props.match.params.projectId)
    if (!this.props.projectInfo || this.props.projectInfo.id !== this.props.match.params.projectId) {
      let projectId = commonUtils.removeNameIntial(this.props.match.params.projectId);
      store.dispatch(this.props.getProjectInfo(projectId))

      this.setState({
        projectId: projectId
      })
    }

    let urlViewName = this.getViewByName(new URLSearchParams(this.props.location.search).get("view"));

    this.changeProjectDetailsView(urlViewName);

    this.setState({
      projectEstimateRequest: null
    });
  }

  getViewByName = (viewName) => {
    if (viewName) {
      switch (viewName) {
        case "general":
          return ProjectDetailViewNameConst.GENERAL;
        case "activity":
          return ProjectDetailViewNameConst.ACTIVITY;
        case "calendar":
          return ProjectDetailViewNameConst.CALENDAR;
        case "members":
          return ProjectDetailViewNameConst.MEMBERS;
        case "notification":
          return ProjectDetailViewNameConst.NOTIFICATION;
        case "files":
          return ProjectDetailViewNameConst.FILES;
        case "feedback":
          return ProjectDetailViewNameConst.FEEDBACK;
      }
    }
    return ProjectDetailViewNameConst.GENERAL;
  }

  changeProjectDetailsView = (viewName) => {

    //this.props.history.push(this.props.location.pathname + "?view=" + viewName.description)

    this.setState({
      projectDetailViewName: viewName,
    })
  };

  onEstimateChange = (name, projectEstimateRequest) => {
    this.setState({
      projectEstimateRequest: {
        [name]: {
          ...projectEstimateRequest,
        }
      }
    })
  }

  handleUpdateEstimate = () => {
    //console.log("handleUpdateEstimate PROJECT PAGE")
    //console.log(this.state.projectEstimateRequest)
    store.dispatch(this.props.updateProjectEstimate(this.state.projectEstimateRequest, this.state.projectId));
  }

  render() {
    return (
      <div className="projectDetails">
        {this.props.projectInfo && commonUtils.hasAccess(this.props.projectInfo, this.state.permission.allowAccessToOtherUserTeamProjects) &&
          <ProjectDetailsHeader
            projectInfo={this.props.projectInfo}
            headerSelect={this.changeProjectDetailsView}
          />
        }
        <div className="section-body mt-0">
          <div className="container-fluid px-0">
            {this.props.projectInfo &&
              <div className="row mx-0 projectDetailsSection pt-1 border-top">
                <div className="col-lg-12 px-1">
                  {this.state.projectDetailViewName === ProjectDetailViewNameConst.GENERAL ?
                    <>

                      <ProjectDetailsGeneral
                        isLoading={this.props.isLoading}
                        item={this.props.projectInfo}
                        projectEstimateRequest={(this.state.projectEstimateRequest && this.state.projectEstimateRequest.teamEstimate) || (this.props.projectInfo.projectEstimate && this.props.projectInfo.projectEstimate.teamEstimate)}
                        onEstimateChange={this.onEstimateChange}
                        handleUpdateEstimate={this.handleUpdateEstimate}
                        readOnly={(!commonUtils.hasAccess(this.props.projectInfo, this.state.permission.allowAccessToOtherUserTeamProjects)) || this.props.projectInfo.deleted}
                        permission={this.state.permission}
                        taskStatisticsByCategory={this.props.taskStatisticsByCategory}
                        getTaskStatisticsByCategory={this.props.getTaskStatisticsByCategory}
                        itemId={this.props.projectInfo.id}
                      />
                    </>

                    : this.state.projectDetailViewName === ProjectDetailViewNameConst.FEEDBACK ? <FeedbackPageSection projectItem={this.props.projectInfo} feedbackType="PROJECT"/> :
                      this.state.projectDetailViewName === ProjectDetailViewNameConst.ACTIVITY ? <ProjectDetailsActivity /> :
                        this.state.projectDetailViewName === ProjectDetailViewNameConst.FILES ? <ProjectDetailsFiles /> :
                          this.state.projectDetailViewName === ProjectDetailViewNameConst.NOTIFICATION ? <ProjectDetailsNotification /> :
                            this.state.projectDetailViewName === ProjectDetailViewNameConst.MEMBERS ? <ProjectDetailsMembers /> :
                              this.state.projectDetailViewName === ProjectDetailViewNameConst.CALENDAR ? <ProjectDetailsCalendar /> : <ProjectDetailsGeneral />
                  }
                </div>

              </div>
            }

          </div>
        </div>
        {!this.props.projectInfo && !this.props.isLoading &&
          <div className="text-center pt-3">
            <img src="/assets/images/custom/noprojects-min.PNG" />
            <span className="d-block fsize15"> No Project details found ?</span>
            <span className="d-block fsize12 text-muted">You may not have enough privilleage to view the project details
            </span>
            <span className="d-block fsize12 text-muted">
              or Project does not exist</span>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication,
  projectInfo: state.projects.selectedProjectInfo,
  isLoading: state.projects.getInfoLoading,
  taskStatisticsByCategory: state.dashboard.taskStatisticsByCategory
});
const mapDispatchToProps = dispatch => ({
  getProjectInfo: projectActions.getProjectInfo,
  updateProjectEstimate: projectActions.updateProjectEstimate,
  getTaskStatisticsByCategory: dashboardActions.getTaskStatisticsByCategory,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsPage);
